angular.module('app').controller('homeCtrl', function ($rootScope, $scope, $state) {

    /**
     * Make install requests the main home route
     */
    if($state.current.name === 'app.home'){
        $state.go('app.home.installRequest');
    }

    $scope.search = {}
    $scope.numberPages = {}

});