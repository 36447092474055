/**
 * Created by Kais CHAIBI on 29/11/2017.
 */
angular.module("app.services").factory('AbstractApiSrv',function ($http,$rootScope, $window,Notification,CONFIG) {

    function AbstractApiSrv(opt){
        if(!opt) throw new Error("INVALID Service params")
        if(opt && opt.name){
            this.srvName=opt.name
        }else{
            throw new Error("invalid service name")
        }

        if(opt && opt.path){
            this.path=opt.path
        }else{
            throw new Error("invalid service path ")
        }

        // this.API_URI=CONFIG.API_URI;
    }
    AbstractApiSrv.prototype.setApiUri=function(apiUri){
        this.API_URI=apiUri;
    }
    AbstractApiSrv.prototype.getById=function(id,success,error){
        $http.get( this.API_URI+'/'+this.path+'/'+id).then(function(response) {
            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(response);
            }



        },function(data) {
            error(data)

        });
    }
    AbstractApiSrv.prototype.query=function(searchQuery,limit,page,success,error) {
        var l = 10;
        var p = 1;
        if (page) p = page;
        if (limit) l = limit;
        $http.post(this.API_URI+'/'+this.path+'/query?limit='+l+'&page='+p,searchQuery).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(response);
            }



        },function(data) {
            error(data)

        });
    };

    AbstractApiSrv.prototype.agg= function(agg,limit,page,success,error){
        var l=10;
        var p= 1;
        if (page) p=page;
        if (limit) l=limit;


        $http.post(this.API_URI+'/'+this.path+'/agg?limit='+l+'&page='+p,agg).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(response);
            }



        },function(data) {
            error(data)

        });
    };

    AbstractApiSrv.prototype.delete=function(id,success,error){
        $http.delete(this.API_URI+'/'+this.path+'/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(response);
            }



        },function(data) {

            error(data)

        });
    };

    AbstractApiSrv.prototype.updateById=function(id,updata,success,error){
        $http.put(this.API_URI+'/'+this.path+'/'+id,updata).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else if (!response.data.success && response.data.error){
                success(response.data)
            }
            else{

                error(response);
            }



        },function(data) {
            error(data)

        });
    };

    AbstractApiSrv.prototype .add=function(addData,success,error){
        $http.post(this.API_URI+'/'+this.path+'/',addData).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else if (!response.data.success && response.data.error){
                success(response.data)
            }
            else{

                error(response);
            }



        },function(data) {
            error(data)

        });
    };

    AbstractApiSrv.prototype .getAll=function(addData,success,error){
        $http.get(this.API_URI+'/'+this.path+'/').then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else if (!response.data.success && response.data.error){
                success(response.data)
            }
            else{

                error(response);
            }



        },function(data) {
            error(data)

        });
    };

    return AbstractApiSrv;
})