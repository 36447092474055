angular.module('app.dialogs').controller('confirmDeleteRoleDlgCtrl', function ($scope, $rootScope, ngDialog, I3RoleSrv, Notification) {

    $scope.confirm = function () {
        I3RoleSrv.delete($scope.role._id, function (response) {
            if(response.success){
                //$scope.roles.splice($scope.roles.indexOf($scope.role), 1);
                $scope.loadRoles();
                Notification.success({
                    title: "Role deleted",
                    message : "Role was deleted successfully!"
                });
                $scope.role = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : "Une erreur est survenue lors de la suppression !"
                });
                $scope.role = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: "Erreur",
                message : "Une erreur est survenue lors de la suppression  !"
            });

            $scope.role = {};
        });

        $scope.role = {};
        $scope.closeThisDialog();
    }

});