angular.module('app').controller('adminPlatformCtrl', function ($scope, $rootScope, $translate, $filter, Notification, ngDialog, I3PlateformeSrv, plateformHelperSrv, i3HelperSrv) {

    $scope.mode = "VIEW";
    $scope.platform = {};

    /**
     * Loading platform type
     */

    i3HelperSrv.getEnums(function(res){
        $scope.platform_type = res.PLATFORM_TYPE.LIST
    },function(err){

    })


    /**
     * Loading platforms
     */
    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.platforms = response.result;
    }, function (err) {
        console.log(err);
    });

    /**
     * Editing platform action
     * @param platform
     */
    $scope.edit = function (platform) {
        $scope.platform = platform;
        $scope.mode = "EDIT";
    };

    /**
     * Creating a new platform action
     */
    $scope.create = function () {
        $scope.platform = {};
        $scope.mode = "CREATE";
    };

    /**
     * Get platform link from API endpoi
     * @param url
     * @returns {string}
     */
    $scope.getLink  = function (url) {
        return "http://" + url.split("/")[2]
    };

    /**
     * Test authentication params
     */
    $scope.test = function () {
        plateformHelperSrv.testLogin($scope.platform, function (res) {
            if(res.success){
                Notification.success({
                    title: $translate.instant("AUTH_SUCCESS"),
                    message : $translate.instant("AUTH_SUCCESS_MSG")
                });
            }else{
                Notification.error({
                    title:  $translate.instant("AUTH_ERROR"),
                    message : $translate.instant("AUTH_ERROR_MSG")
                });
            }
        }, function (err) {
            Notification.error({
                title:  $translate.instant("AUTH_ERROR"),
                message : $translate.instant("AUTH_ERROR_MSG")
            });
        });
    };

    $scope.save = function () {
        if($scope.mode === "EDIT"){
            // Update platform
            I3PlateformeSrv.updateById($scope.platform._id, $scope.platform, function (response) {
                if(response.success){
                    Notification.success({
                        title: $translate.instant("PLATFORM_UPDATED"),
                        message : "Platform " + $scope.platform.name + " was updated successfully!"
                    });

                    $scope.mode = "VIEW";
                    $scope.platform = {};
                }else{
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.platform.name +"!"
                    });

                    $scope.mode = "VIEW";
                    $scope.platform = {};
                }
            }, function (err) {
                console.log(err);

                Notification.error({
                    title: $translate.instant('ERROR'),
                    message : $translate.instant("ERROR_OCCURED_UDPDATE") +" "+ $scope.platform.name +"!"
                });

                $scope.mode = "VIEW";
                $scope.platform = {};

            })

        }else{
            // Creating a new platform
            I3PlateformeSrv.add($scope.platform, function (response) {

                if(response.success){
                    $scope.platforms.push(response.result);

                    Notification.success({
                        title: $translate.instant("PLATFORM_CREATED"),
                        message : "Platform " + $scope.platform.name + " was created successfully!"
                    });

                    $scope.mode = "VIEW";
                    $scope.platform = {};
                }else{
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.platform.name +"!"
                    });
                    $scope.mode = "VIEW";
                    $scope.platform = {};
                }

            }, function (err) {

                console.log(err);

                Notification.error({
                    title: $translate.instant('ERROR'),
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.platform.name +"!"
                });
                $scope.mode = "VIEW";
                $scope.platform = {};
            })

        }
    };

    /**
     * Removing platform
     * @param platform
     */
    $scope.remove = function (platform) {

        $scope.platform = platform;

        ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/platform.delete.dlg.tpl.html",
            controller : "confirmDeletePlatformDlgCtrl",
            scope: $scope
        })

    };

    /**
     * Cancel creating/editing platform
     */
    $scope.cancel = function () {
        $scope.mode = "VIEW";
        $scope.platform = {};
    }

});