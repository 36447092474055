angular.module('app.dialogs').controller('settingFieldsDlgCtrl', function ($scope, $rootScope, ngDialog, I3RoleSrv, I3SettingSrv, Notification, $translate, i3HelperSrv) {

    $scope.res = [];
    var keys = '';

    i3HelperSrv. getFieldsByCollectionName($scope.setting._id.toUpperCase(), function(res){
        if(res){
            $scope.fields = Object.keys(res);
            if($scope.field_list_notif.length>0){
                var res = $scope.field_list_notif.split(" ");
                angular.forEach($scope.fields, function (e) {
                    var index = res.indexOf(e)
                    if(index>=0){
                        $scope.fields[e] = true
                    }else {
                        $scope.fields[e] = false
                    }
                })
            }else if($scope.field_list_notif === true){
                $scope.all = true
            }else if($scope.field_list_notif === false){
                $scope.nothing = true
            }
        }else{
            console.log('error')
        }
    },function(err){
        console.log(err)
    });

    $scope.getSelectedFields = function () {
        keys = '';
        for(var key in $scope.fields){
            if(!isFinite(key)){
                if(keys === ''){
                    keys+= key;
                }else{
                    keys+= " "+key;
                }
            }
        }
        console.log('$scope.fields',$scope.fields)
    };

    $scope.confirm = function () {
        if($scope.all){
            $scope.setting[$scope.notificationFor].update = true
        }else if(keys && keys !== ''){
            $scope.setting[$scope.notificationFor].update = keys
        }else{
            $scope.setting[$scope.notificationFor].update = false
        }
        $scope.closeThisDialog($scope.setting);
    }

});