angular.module('app').controller("clientInstallationDlgCtrl", function ($scope, I3ProductSrv ) {

    $scope.options = [];
    $scope.values = [];
    $scope.installation = {};
    $scope.installations = [];
    $scope.installation.product_options = [];


    I3ProductSrv.agg({},1000,0,function(res){
        $scope.products = res.result;
        angular.forEach($scope.products, function (prod) {
            angular.forEach(prod.options, function (op) {
                $scope.options.push([op,prod.name])
            })
        })
    },function(err){

    })

    $scope.chooseOptions = function (values) {
        $scope.installation.product_options = [];
        for(var i = 0; i< values.length ; i++){
            if(values[i]){
                $scope.installation.product_options.push($scope.options[i][0])
            }
        }
        var filterProduct = $scope.products.filter(function (value) {
            var match = true;
            if(value.options.length !== $scope.installation.product_options.length){
                return false;
            }
            for(var i=0;i<value.options.length; i++){
                if($scope.installation.product_options.indexOf(value.options[i]) < 0){
                    match = false;
                }
            }
            return match;
        });

        if(filterProduct &&filterProduct.length){
            $scope.installation._product = filterProduct[0]
        }else{
            //TODO set default product
            $scope.installation._product = null
        }
    };


    $scope.add=function(){
        if($scope.installation){
            $scope.closeThisDialog($scope.installation)
        }
    }

});