angular.module('app').controller('interventionAddCtrl', function ($rootScope, $scope, $translate,$state,$stateParams, I3UserSrv,I3ClaimSrv, I3InterventionSrv, Notification, I3DocumentSrv, i3HelperSrv) {

    if($stateParams.id && $stateParams.id.length>0){
            $scope.mode="EDIT";
            I3InterventionSrv.query({query:{ _id: $stateParams.id },options:{populate:  ["_assigned_to", "_claim", "_created_by", "docs"]}},1,0,function(res) {


                if (res && res.success &&  res.result.length>0){
                    $scope.intervention =  res.result[0];
                    $scope.getFiles($scope.intervention.docs);
                    delete $scope.intervention._created_by;

                    if($scope.intervention.comments || $scope.intervention.comments.length<=0)
                        $scope.intervention.comments=[{owner:$rootScope.SESSION.user._id}];
                } else
                    Notification.error("FIND_BY_ID_ERROR");
            },function(err){
                Notification.error("FIND_BY_ID_ERROR")
            })
        }else{
            $scope.mode = "CREATE";
            $scope.users = [];
            $scope.intervention = {comments:[{owner:$rootScope.SESSION.user._id}]};
        }

        I3UserSrv.query({}, 10000, 1, function (res) {
            if(res.success){
                $scope.users = res.result;
            }
        }, function (err) {
            console.log(err);
        });



        I3ClaimSrv.query({}, 10000, 1, function (res) {
            if(res.success){
                $scope.claims = res.result;
            }
        }, function (err) {
            console.log(err);
        });


        $scope.save = function (intervention) {
            var interv = angular.copy(intervention);
            interv._claim = intervention._claim._id;

            if(intervention._assigned_to){
                if(intervention._assigned_to.length > 0 && intervention._assigned_to[0]._id){
                    interv._assigned_to = [];
                    angular.forEach(intervention._assigned_to, function (user) {
                        interv._assigned_to.push(user._id);
                    });
                }else{
                    delete interv._assigned_to;
                }
            }

            if(interv.comments && interv.comments.length > 0){

            }
            if(interv.comments[0].msg && interv.comments[0].msg.length>0){
            }else {
                delete   interv.comments;
            }

            if($scope.mode === "CREATE"){
                I3InterventionSrv.add(interv, function (res) {
                    if(res && res.success){
                        Notification.success({message : $translate.instant("INTERVENTION_ADDED")});
                        $state.go('app.home.intervention');
                    }else{
                        Notification.error({message : $translate.instant("INTERVENTION_FAILED")});
                    }
                }, function (err) {
                    console.log(err);
                });
            }else{
                I3InterventionSrv.updateById(interv._id, interv, function (res) {
                    if(res.success){
                        Notification.success({message : $translate.instant('INTERVENTION_UPDATED')});
                        $state.go('app.home.intervention');
                    }else{
                        Notification.warning({message : $translate.instant('INTERVENTION_UPDATE_FAILED')});
                    }
                }, function (err) {
                    Notification.error({message : $translate.instant('INTERVENTION_UPDATE_FAILED')});
                    console.log(err);
                })
            }
        };

        /**
         * Getting files
         */
        $scope.getFiles=function(files) {
            I3DocumentSrv.query({query: {_id: {'$in': files}}}, 10000, 1, function (res) {
                if (res.success) {
                    $scope.intervention.docs = res.result;
                }
            }, function (err) {
                console.log(err);
            });
        };
        if($scope.mode === "CREATE"){
            $scope.intervention.docs = []
        }

        $scope.uploadFiles = function(files, errFiles) {
            $scope.files = files;
            $scope.errFiles = errFiles;
            i3HelperSrv.uploadFiles(files, function (res) {
                angular.forEach(res.result , function (file) {
                    $scope.intervention.docs.push(file)
                });
                file.result = res.data;
            }, function (response) {
                if (response.status)
                    $scope.errorMsg = response.status + ': ' + response.data;
            }, function (evt) {

            });
        }

        $scope.downloadFile=function(file){
            i3HelperSrv.downloadFile(file._id, file.original_name,function success(res){

            },function error(err){

            },function progress(evt){
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
                console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
            })
        };


    });