angular.module('app').controller("confirmDeleteClaimDlgCtrl", function ($scope, $state, I3ClaimSrv, Notification) {

    $scope.confirm = function () {
        I3ClaimSrv.delete($scope.claim._id, function (response) {
            if(response.success){
                $scope.loadClaims()
                //$scope.users.splice($scope.users.indexOf($scope.user), 1);
                Notification.success({
                    title: "Claim deleted",
                    message : "Claim was deleted successfully!"
                });
                $scope.claim = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : "Une erreur est survenue lors de la suppression !"
                });
                $scope.claim = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: "Erreur",
                message : "Une erreur est survenue lors de la suppression  !"
            });

            $scope.claim = {};
        });

        $scope.claim = {};
        $scope.closeThisDialog();
    }
});