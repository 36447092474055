/**
 * Created by Kais CHAIBI on 30/01/2018.
 */


angular.module("app.services").factory('NGIFleetClientSrv', function ($http, $rootScope, $window) {

    function NGIFleetClientSrv() {


    }

    NGIFleetClientSrv.prototype.setApiUri = function (apiUri) {
        console.log("apiUri",apiUri)
        this.API_URI = apiUri;
    }
    NGIFleetClientSrv.prototype.getClient = function (name,size, page, success, error) {
        if(!this.API_URI) {
            console.log( this.API_URI )
            return ;
        }
        var organisation = {

            "id": -1,
            "organisationType": "",
            "name": name || "",
            "properties": [
                {"name": "Phone_number", "type": "s", "value": null},
                {"name": "fax", "type": "s", "value": null},
                {"name": "email", "type": "s", "value": null},
                {"name": "Country", "type": "s", "value": null},
                {"name": "Address", "type": "s", "value": null},
                {"name": "ContactLastName", "type": "s", "value": null},
                {"name": "ContactFirstName", "type": "s", "value": null},
                {"name": "ContactPhone", "type": "s", "value": null},
                {"name": "ContactEmail", "type": "s", "value": null},
                {"name": "Reference", "type": "s", "value": null}

            ]
        }


        $http.post(this.API_URI, {
            "jsonrpc": "2.0",
            "method": "getOrganisationByPage",
            "params": [$window.sessionStorage.plateformToken, organisation, page, size, ["fleet","Department"]],
            "id": 1
        }).then(function (response) {

            if (response && response.data && response.data.result && response.data.result.result ) {


                var organizations  = response.data.result.result.map(function(val ){
                    var org= {
                        _id:val.id,
                        name: val.name,
                        contact: {}
                    }
                    org.name=val.name;
                    val.properties.forEach(function(prop){
                        if(prop.name == "Phone_number"){
                            org.contact.phone=prop.value;
                        }
                        else if(prop.name == "fax"){
                            org.contact.fax=prop.value;
                        }
                        else if(prop.name == "email"){
                            org.contact.mail=prop.value;
                        }
                        else if(prop.name == "ContactLastName"){
                            if(org.contact.name){
                                org.contact.name+=prop.value;
                            }else{
                                org.contact.name=prop.value;
                            }

                        }
                        else if(prop.name == "ContactFirstName"){
                           console.log(prop.name, prop.value,org.contact.name )
                            if(org.contact.name){
                                org.contact.name=org.contact.name+" "+prop.value;
                            }else{
                                org.contact.name=prop.value;
                            }

                        }
                    })
                    return org;
                })
                success({success:true, result:organizations});
            }
            else {

                error(response);
            }


        }, function (data) {
            error(data)

        });
    }

return new NGIFleetClientSrv();
})