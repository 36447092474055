angular.module('app').controller('adminUserAddCtrl', function ($scope,$rootScope, $state, $translate, I3UserSrv, I3RoleSrv,ngDialog, Notification) {

    $scope.user = {};

    /**
     * Getting roles
     */
    I3RoleSrv.query({}, 10000, 1, function (response) {
        if(response.success){
            $scope.roles = response.result;
        }
    });


    /**
     * Save user
     */
    $scope.save = function () {
        I3UserSrv.add($scope.user, function (response) {
            if(response.success){
                Notification.success({
                    title : "User created",
                    message : "User " + $scope.user.login + " was created successfully!"
                });
                $state.go('app.admin.users');
                $scope.user  = {};
            }else{
                Notification.success({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_CREATE") +" "+ $scope.user.login +"!"
                });
                $scope.user  = {};
            }
        }, function (err) {
            var msg = "";
            if( err.error.errorString){
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message : msg
            });
        })
    };


    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.user = {};
        $state.go('app.admin.users');
    }

});