angular.module('app').controller('loginCtrl', function ($rootScope,$scope, $window, $state,$cookieStore,$translate, ngDialog ,i3HelperSrv,Notification) {

    $scope.user={};
    $scope.rememberMe=  $cookieStore.get('rememberme');
    $scope.user.login=  $cookieStore.get('login');
    $scope.user.password=  $cookieStore.get('password');

    $scope.socials = [
        {
            icon : "facebook",
            url : "https://www.facebook.com/NGI.GEO.SOLUTIONS"
        },
        {
            icon : "twitter",
            url : "https://twitter.com/ngi_maghreb"
        },
        {
            icon : "google-plus",
            url : "https://plus.google.com/+NGIMaghreb"
        },
        {
            icon : "linkedin",
            url : "https://www.linkedin.com/company/1371452/"
        }
    ];

    if ($cookieStore.get('lang')==='fr'){
        $scope.language= "fr";
    }
    else if ($cookieStore.get('lang')==='en'){
        $scope.language= "en";
    }
    else{
        $scope.language= "fr";
    }

    $scope.changeLanguage = function(langKey){
        if(langKey==="en"){
            $scope.language= "en";
            $cookieStore.put('lang','en');
            $translate.use($scope.language);
        }
        else if (langKey==="fr"){
            $scope.language= "fr";
            $cookieStore.put('lang','fr');
            $translate.use($scope.language);
        }
    };
    $scope.signIn=function() {
        var signedIn = angular.isDefined($rootScope.SESSION);

        if(signedIn){
            i3HelperSrv.logout( function(){
                $rootScope.appData={};
                $rootScope.maplayers={};
                console.debug("logout success");
            });
        }

        if ($scope.rememberMe){

            $cookieStore.put('login',$scope.user.login);
            $cookieStore.put('password',$scope.user.password);
            $cookieStore.put('lang',$scope.language);
        }else{
            $cookieStore.remove('rememberme');
            $cookieStore.remove('login');
            $cookieStore.remove('password');
            $cookieStore.remove('lang');
        }
        i3HelperSrv.login( $scope.user,function(res){
             if(res && res.success){
                 $state.go("app.dashboard");
             }else{
                 $scope.message=$translate.instant("LOGIN_OR_PASSWORD_INVALID");
                 Notification.error({title:$translate.instant("AUTHENTICATION_ERROR"), message:$translate.instant("LOGIN_OR_PASSWORD_INVALID")})
             }

        },function(err){
            if(err.error){
                if(err.error.errorCode){
                    $scope.message=$translate.instant(err.error.errorCode);
                }else{
                    $scope.message=err.error
                }
            }

            Notification.error({title: $translate.instant("LOGIN_ERROR"), message: $scope.message,replaceMessage: true});

        })
    };
    $scope.loginClient=function() {
        $scope.user.login = 'superadmin';
        $scope.user.password = 'superadmin';
        var signedIn = angular.isDefined($rootScope.SESSION);

        if(signedIn){
            i3HelperSrv.logout( function(){
                $rootScope.appData={};
                $rootScope.maplayers={};
                console.debug("logout success");
            });
        }
        i3HelperSrv.login( $scope.user,function(res){
             if(res && res.success){
                 $state.go("client");
             }else{
                 $scope.message=$translate.instant("LOGIN_OR_PASSWORD_INVALID");
                 Notification.error({title:$translate.instant("AUTHENTICATION_ERROR"), message:$translate.instant("LOGIN_OR_PASSWORD_INVALID")})
             }

        },function(err){
            if(err.error){
                if(err.error.errorCode){
                    $scope.message=$translate.instant(err.error.errorCode);
                }else{
                    $scope.message=err.error
                }
            }

            Notification.error({title: $translate.instant("LOGIN_ERROR"), message: $scope.message,replaceMessage: true});

        })
    };


    $scope.remember=function(){
        if ($scope.rememberMe){
            $cookieStore.put('rememberme',true);
            $cookieStore.put('login',$scope.user.login);
            $cookieStore.put('password',$scope.user.password);
        }else{
            $cookieStore.remove('rememberme');
            $cookieStore.remove('login');
            $cookieStore.remove('password');
        }
    };

    /*Open dialog window for reset password*/
    $scope.recoverPassword = function () {
        ngDialog.open({
            template: 'app/commons/dialogs/forget/forget.dlg.tpl.html',
            controller: 'forgetDlgCtrl',
            scope: $scope
        })
    };
    $rootScope.$on("sessionExpired", function(){
        Notification.error({message:"Session expirée",replaceMessage: true});
        $state.go('login');


    })
});