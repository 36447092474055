angular.module('app').controller('adminClaimsCtrl', function ($scope,$rootScope, $state, $translate, I3ClaimSrv, I3RoleSrv,ngDialog, iaxeedAssetSrv, Notification, I3PlateformeSrv, constantSrv) {

    $scope.claim = {};
    //$scope.itemsByPage = 50;
    //$scope.search = {};

    $scope.status = constantSrv.getClaimsStatus();

    I3PlateformeSrv.query({}, 10000, 1, function (res) {
        if(res.success){
            $scope.platforms = res.result;
        }
    }, function (err) {
        console.log(err);
    });

    /**
     * Getting users
     */
    $scope.loadClaims  = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options =  {
            populate: [
                {
                    path: '_plateforme',
                    select: 'name'
                }
            ]
        };

        var query = {};

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            //start = pagination.start / number + 1 || 1;
            $scope.numberPages.claimItemByPages = number
            if($scope.numberPages.claimNumberPages){
                start = $scope.numberPages.claimNumberPages
            }else{
                start = pagination.start / number + 1 || 1;
            }
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }
        if(!$scope.search.object) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.object) {
                    $scope.search.object = tableState.search.predicateObject.object
                    query["object"] = {"$regex": tableState.search.predicateObject.object, "$options": "i"};
                }
            }else{
                query.object = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.object = $scope.search.object
            }
            query.object = {'$regex' :$scope.search.object, '$options' : 'i'}
        }
        if(!$scope.search.claim_client_name) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.client_name) {
                    $scope.search.claim_client_name = tableState.search.predicateObject.client_name
                    query.client_name = {'$regex': tableState.search.predicateObject.client_name, '$options': 'i'}
                }
            }else{
                query.client_name = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.client_name = $scope.search.claim_client_name
            }
            query.client_name = {'$regex' :$scope.search.claim_client_name, '$options' : 'i'}
        }

        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.object = tableState.search.predicateObject.object
        }
        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.claim_client_name = tableState.search.predicateObject.client_name
        }


        if($scope.search){
            if ($scope.search && $scope.search._plateforme) {
                query._plateforme= $scope.search._plateforme._id;
            }
            if ($scope.search.status) {
                query["status"] = {'$regex': $scope.search.status, $options: '-i'}
            }
        }

        I3ClaimSrv.query({query: query, options: options } , number, start, function (response) {
            if(response.success){
                $scope.claims = response.result;
                $scope.claimCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                tableState.pagination. start=start*number-1>=0? start*number-1: 0;
                $scope.tableState = tableState;
            }
        });
    };


    /**
     * Removing user
     * @param user
     */
    $scope.remove = function (user) {
        $scope.claim = user;
        
        ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/claim.delete.dlg.tpl.html",
            controller : "confirmDeleteClaimDlgCtrl",
            scope: $scope
        })

    };

    $scope.updateClaimStatus=function(claim,$data){
        I3ClaimSrv.updateById(claim._id,{status:$data}, function(res){
            if(res.success){
                Notification.success($translate.instant("CLAIM_UPDATED"));
            }
        },function(err){
        })
    }
    $scope.onPageChange = function (val) {
        $scope.numberPages.claimNumberPages = val
        $scope.numberPages.claimItemByPages = $scope.itemsByPage
    }

    if(!$scope.numberPages.claimItemByPages || $scope.numberPages.claimItemByPages === undefined){
        $scope.itemsByPage = 50
    }else{
        $scope.itemsByPage = $scope.numberPages.claimItemByPages
    }

});