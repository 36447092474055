/**
 * Created by Kais CHAIBI on 30/01/2018.
 */


angular.module("app.services").factory('NGIFleetAssetSrv', function ($http, $rootScope, $window) {

    function NGIFleetClientSrv() {


    }

    NGIFleetClientSrv.prototype.setApiUri = function (apiUri) {
        console.log("apiUri",apiUri)
        this.API_URI = apiUri;
    }
    NGIFleetClientSrv.prototype.getAssetByClient = function (clientId,assetname,size, page, success, error) {
       console.log(assetname)
        if(!this.API_URI) {
            console.log( this.API_URI )
            return ;
        }
        var org  = {
            "id":clientId,
            "organisationType": "",
            "name": "",
            "properties": [

            ]
        }

        var asset={
            "id": -1,
            "assetType": "",
            "properties": [
                {"name": "plateNumber", "type": "s", "value": assetname },
                {"name": "brand", "type": "s", "value": null},
                {"name": "model", "type": "s", "value": null},
                {"name": "comment", "type": "s", "value": null},
                {"name": "safety instructions", "type": "s", "value": null},
                {"name": "theoretical consumption", "type": "s", "value": null},
                {"name": "fuel", "type": "s", "value": null}

            ]
        }
        var toEntity = {

            "organisation": org,
            "entityType": "organisation"


        };


        var fromEntity = {

            "asset": asset,
            "entityType": "asset"
        };

        var relation_client_asset = {
            "id": -1,
            "serviceName": "Weenee_Fleet",
            "partyRoleTypeNameFrom": "",
            "partyRoleTypeNameTo": "",
            "fromEntity": fromEntity,
            "toEntity": toEntity,
            "fromDate": null,
            "thruDate": null,
            "relationTypeName": "owner",
            "properties": []
        };


        $http.post(this.API_URI, {
            "jsonrpc": "2.0",
            "method": "getRelationByPage",
            "params": [$window.sessionStorage.plateformToken, relation_client_asset, page, size],
            "id": 1
        }).then(function (response) {

            console.log(response.data.result.result)
            if (response && response.data && response.data.result && response.data.result.result ) {

                var assets=[];

                response.data.result.result.forEach(function(val){
                        if(val.fromEntity && val.fromEntity.asset){
                            var asset={_id:val.fromEntity.asset.id};
                            val.fromEntity.asset.properties.forEach(function(prop){
                                if(prop.name=="plateNumber"){
                                    asset.name=prop.value;
                                }
                            })
                            assets.push(asset);
                        }



                })




                success({success:true, result:assets});
            }
            else {

                error(response);
            }


        }, function (data) {
            error(data)

        });
    }

return new NGIFleetClientSrv();
})