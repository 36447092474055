/**
 * Project: ngitracking
 * Created by ngiteam 06/02/2017.
 */
var config_dev={
    "APP_NAME": "NGI_I3",
    "APP_VERSION": "v0.0.2",
    "API_URI": "http://localhost:5000/api/v1.0",
    "OPEN_PROJECT_API": "https://dev.ngi.tn/api/v3/",
    "LBS_WMS":"https://tcfleet.ngi.tn/bgis/wms?format=png",
    "EVENT_URI":"http://localhost:4999", // socket io
    "BND_URI":"https://tcfleet.ngi.tn/bgis/bnd",
    "OPEN_PROJECT_URI":"https://dev.ngi.tn/",
    "PUBLIC_LINK_URL":"http://localhost:8081/i3client"

};

var config_preprod={
    "APP_NAME": "NGI_I3",
    "APP_VERSION": "v0.0.2",
    "API_URI": "http://10.168.1.100:5000/api/v1.0",
    "OPEN_PROJECT_URI":"https://dev.ngi.tn/",
    "BND_URI":"https://tcfleet.ngi.tn/bgis/bnd",
    "LBS_WMS":"https://tcfleet.ngi.tn/bgis/wms?format=png",
    "PUBLIC_LINK_URL":"http://localhost:8081/i3client"
};

var config_prod_secure={
    "APP_NAME": "NGI_I3",
    "APP_VERSION": "v0.0.2",
    "API_URI": "https://i3.ngi.tn/api/v1.0",
    "OPEN_PROJECT_URI":"https://dev.ngi.tn/",
    "BND_URI":"https://tcfleet.ngi.tn/bgis/bnd",
    "LBS_WMS":"https://tcfleet.ngi.tn/bgis/wms?format=png",
    "PUBLIC_LINK_URL":"http://localhost:8081/i3client"
};

angular.module("app").constant("CONFIG",config_config_prod_secure);

angular.module("app").config([
    '$translateProvider',"$provide", "ngDialogProvider", "NotificationProvider","I3_SERVICES","blockUIConfig",
    function($translateProvider,$provide,ngDialogProvider, NotificationProvider,I3_SERVICES,blockUIConfig) {
        $translateProvider.useSanitizeValueStrategy('escapeParameters');
        $translateProvider.useStaticFilesLoader({
            files: [{
                prefix: 'i18n/',
                suffix: '.json'
            }, {
                prefix: 'i18n/server/',
                suffix: '.json'
            }]
        });
        moment.locale('fr');
        $translateProvider.preferredLanguage('fr');
        $translateProvider.forceAsyncReload(true);

        NotificationProvider.setOptions({
            delay: 10000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'right',
            positionY: 'bottom'
        });

        ngDialogProvider.setDefaults({
            className: 'ngdialog-theme-default'
        });
        blockUIConfig.message="Chargement...";
        blockUIConfig.delay = 100;



    }
]);
