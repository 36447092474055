angular.module('app').controller("confirmClientDlgCtrl", function ($scope, I3PlateformeSrv, plateformHelperSrv, countrySrv, iaxeedCompanySrv, I3InstallationGroupSrv, installation, Notification, $state ) {

    $scope.client = {};
    $scope.client.contact = {};
    $scope.countries = countrySrv.getCountryList();


    if(installation){
        if(installation.client_name){
            $scope.client.name = installation.client_name;
        }
        if(installation.client_data){
            if(installation.client_data.contact && installation.client_data.contact.phone){
                $scope.client.contact.phone = installation.client_data.contact.phone;
            }
            if(installation.client_data.contact && installation.client_data.contact.mail){
                $scope.client.contact.mail = installation.client_data.contact.mail;
            }
            if(installation.client_data.contact && installation.client_data.contact.fax){
                $scope.client.contact.fax = installation.client_data.contact.fax;
            }
            if(installation.client_data && installation.client_data._ctry){
                for(var i = 0; i<$scope.countries.length; i++){
                    if(installation.client_data._ctry === $scope.countries[i].code){
                        $scope.client._ctry = $scope.countries[i]
                    }
                }
            }
        }
    }


    /**
     * select and authenticate plateforme
     * @param platform
     */
    $scope.selectPlateform=function(platform){
        plateformHelperSrv.setPlateFormAndLogin(platform,function(res){
        },function(err){
            console.log(err);
        })
    };


    /**
     * Loading platforms
     */
    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.platforms = response.result;
    }, function (err) {
        console.log(err);
    });


    $scope.add = function (client) {
        client._ctry = client._ctry._id;
        iaxeedCompanySrv.add(client,function(res){
            if(res && res.success){
                I3InstallationGroupSrv.updateById(installation._id, {client_id:res.result._id}, function (response) {
                    if (response.success) {
                        Notification.success('Client ajouté avec succès');
                        $state.reload();
                        $scope.closeThisDialog(res.result)
                    } else {
                        Notification.error('ERROR')
                    }
                }, function (err) {

                })
            }else{
                Notification.error('Erreur');
            }
        },function(err){
        })
    }

});