angular.module('app').controller('installationRequestAddCtrl', function ($rootScope, $scope,I3InstallationGroupSrv,I3PlateformeSrv,plateformHelperSrv,iaxeedCompanySrv,I3ProductSrv,ngDialog,Notification,$state ,$stateParams,messageBox, i3HelperSrv, I3DocumentSrv,NGIFleetHelperSrv,NGIFleetClientSrv) {
    $scope.installationsList = []
    var now = moment().clone();
    $scope.dayRes = now;

    $scope.pickerDay = {
        format : "DD/MM/YYYY",
        position: "top left",
        init: null,
        autoTrans : false,
        enableTime : false,
        enableDate : true,
        enableMinutes : false,
        defaultView : "DATE",
        maxDate: moment("2020/05/22", "YYYY/MM/DD"),
        minDate: now,
        getDate : function (date) {
            $scope.dayRes = date;
            $scope.installationRequest.preferred_dt = date;
        }
    };
    $scope.pickerPlannedBeginDt = {
        format : "DD/MM/YYYY",
        position: "top left",
        init: null,
        autoTrans : false,
        enableTime : false,
        enableDate : true,
        enableMinutes : false,
        defaultView : "DATE",
        maxDate: moment("2020/05/22", "YYYY/MM/DD"),
        minDate: now,
        getDate : function (date) {
            $scope.dayRes = date;
            $scope.installationRequest.planned_begin_dt = date;
        }
    };
    $scope.pickerPlannedEndDt = {
        format : "DD/MM/YYYY",
        position: "top left",
        init: null,
        autoTrans : false,
        enableTime : false,
        enableDate : true,
        enableMinutes : false,
        defaultView : "DATE",
        maxDate: moment("2020/05/22", "YYYY/MM/DD"),
        minDate: now,
        getDate : function (date) {
            $scope.dayRes = date;
            $scope.installationRequest.planned_end_dt = date;
        }
    };
    $scope.open2 = function() {
        $scope.popup2.opened = true;
    };
    $scope.popup2 = {
        opened: false
    };

    if($stateParams.id && $stateParams.id.length>0){
        $scope.mode="EDIT";
        I3InstallationGroupSrv.query({query:{_id:$stateParams.id},options:{populate:[{path:"installations._product _plateforme docs"}, {path:"_installationIds"}, {path:"_installationIds",populate:[{path:"_product", model:"product"}]}]}},1,0,function(res) {


            if (res && res.success &&  res.result.length>0){
                var installationRequest = res.result[0];
                $scope.getFiles(installationRequest.docs);
            if (installationRequest.preferred_dt) {
                $scope.pickerDay.init = moment(installationRequest.preferred_dt).clone();
            }
            if (installationRequest.planned_begin_dt) {
                $scope.pickerPlannedBeginDt.init = moment(installationRequest.planned_begin_dt).clone();
            }
            if (installationRequest.planned_end_dt) {
                $scope.pickerPlannedEndDt.init = moment(installationRequest.planned_end_dt).clone();
            }

                installationRequest.client_id={_id:installationRequest.client_id,name:installationRequest.client_name};
                installationRequest.plateform=installationRequest._plateforme;
            $scope.installationRequest = installationRequest;
               if($scope.installationRequest.comments || $scope.installationRequest.comments.length<=0)
                $scope.installationRequest.comments=[{owner:$rootScope.SESSION.user._id}];
        } else
           Notification.error("FIND_BY_ID_ERROR");
        },function(err){
            Notification.error("FIND_BY_ID_ERROR")
        })
    }else{
        $scope.mode = "CREATE";
        $scope.installationRequest = {comments:[{owner:$rootScope.SESSION.user._id}]};
    }



    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.platforms = response.result;

    }, function (err) {
        console.log(err);
    });

    $scope.remove=function(index){
        messageBox.confirm("REMOVE_INSTALLATION_FROM_LIST_TITLE","REMOVE_INSTALLATION_FROM_LIST_MESSAGE").then(function(res){
            if(res){
                $scope.installationRequest._installationIds.splice(index,1);
                $scope.installationRequest.installations.splice(index,1);
                $scope.installations = [];
                var installations = {};
                for (var i = 0; i < $scope.installationRequest._installationIds.length; i++) {
                    if ($scope.installationRequest._installationIds[i]._product) {
                        if (!installations[$scope.installationRequest._installationIds[i]._product._id]) {
                            installations[$scope.installationRequest._installationIds[i]._product._id] = {
                                _product: $scope.installationRequest._installationIds[i]._product,
                                product_options: $scope.installationRequest._installationIds[i].product_options,
                                asset_count: 0
                            }
                        }
                        installations[$scope.installationRequest._installationIds[i]._product._id].asset_count += 1;
                    }
                }
                $scope.installationRequest.installations = (Object.values(installations))
                I3InstallationGroupSrv.updateById($scope.installationRequest._id,{installations: $scope.installationRequest.installations,_installationIds:$scope.installationRequest._installationIds},function(res){
                    if(res && res.success){
                        Notification.success("Installation supprimée");
                    }else{
                        Notification.error("INSTALLATION_GROUP_ADD_ERROR");
                        //TODO handle error
                    }},function(err){
                })
            }
        },function(reject){

        })
    };
    $scope.edit=function(index){
        console.log("edit",index);
        ngDialog.open({
            template: 'app/commons/dialogs/installationsGroup/installation.add.dlg.tpl.html',
            controller: 'addInstallationDlgCtrl',
            scope: $scope,
            resolve:{
                installations:function(){
                    return $scope.installationRequest.installations[index];
                },
                index:function(){
                    return  index;
                }
            }
        })
    };
    /**
     * select and authenticate plateforme
     * @param platform
     */
    $scope.selectPlateform=function(platform){
        delete $scope.installationRequest.client_id;
        $scope.installationRequest.contact = {};
        $scope.installationRequest.client_name = {};
        $scope.clients=[];
        if(platform.type=="NGI_FLEET"){
            NGIFleetHelperSrv.setPlateFormAndLogin(platform,function(res){

                     if(res.result){

                    }else{
                         Notification.error("Impossile de se connecter à la plateforme "+ platform.name)
                     }
                console.log("NGIFleetHelperSrv" , res);
            },function(err){
                Notification.error("Une erreur s'est produite lor de la connexrion à la plateforme  "+ platform.name)
            })
        }else{
            plateformHelperSrv.setPlateFormAndLogin(platform,function(res){

                if(res && res.success){

                }else{
                    Notification.error("Impossile de se connecter à la plateforme "+ platform.name)
                }

            },function(err){
                console.log(err);
                Notification.error("Une erreur s'est produite lor de la connexrion à la plateforme  "+ platform.name)
            })
        }

    };
    $scope.selectClient=function(comapny){
        if(comapny.contact){

                if(!$scope.installationRequest.contact) $scope.installationRequest.contact={};
            $scope.installationRequest.contact.fax=comapny.contact.fax;
            $scope.installationRequest.contact.email=comapny.contact.mail;
            $scope.installationRequest.contact.phone=comapny.contact.phone;
            $scope.installationRequest.contact.name=comapny.contact.name;

        }

    };
    $scope.searchClient=function($select){

        if($rootScope.plateform && $rootScope.plateform.type=="NGI_FLEET"){
            NGIFleetClientSrv.getClient($select.search,10,0,function(res){



                $scope.clients=res.result;
            },function(err){
                console.log("err" , err)
            })
        }else{
           iaxeedCompanySrv.query({query:{name:{'$regex' :$select.search, '$options' : 'i'}}},10,0,function(res){

             $scope.clients=res.result;

             },function(err){

             })
        }


    };
    $scope.addInstallation=function(){

    /*    ngDialog.open({
            template: 'app/commons/dialogs/installationsGroup/installation.add.dlg.tpl.html',
            controller: 'addInstallationDlgCtrl',
            scope: $scope,
            resolve:{
                installations:function(){return null},
                index:function(){return null}
            }
        })*/


        var dialog = ngDialog.open({
            template: 'app/commons/dialogs/installationsGroup/installation.add.new.dlg.tpl.html',
            controller: 'addNewInstallationDlgCtrl',
            scope: $scope,
            //className: 'ngdialog-theme-default custom-width',
            width:'1200px',
            resolve:{
                installations:function(){return $scope.installationRequest},
                index:function(){return null}
            }
        })
        dialog.closePromise.then(function (data) {
            if(data){
                $scope.installationRequest._id = data.value
            }
        });
    };

    $scope.addClient = function(){
        var dialog = ngDialog.open({
            template: 'app/commons/dialogs/installation/add.client.dlg.tpl.html',
            controller: 'addClientDlgCtrl',
            width:'800px',
            scope: $scope,
            resolve:{
                installationRequest:function(){return null}
            }
        });
        dialog.closePromise.then(function (data) {
            if(data && data.value && data.value._id){
                $scope.installationRequest.client_id = data.value;
                if(data.value.contact){

                    if(!$scope.installationRequest.contact) $scope.installationRequest.contact={};
                    $scope.installationRequest.contact.fax=data.value.contact.fax;
                    $scope.installationRequest.contact.email=data.value.contact.mail;
                    $scope.installationRequest.contact.phone=data.value.contact.phone;

                }
            }
        });
    };


    $scope.cancel=function(){
        $state.go("app.home.installRequest");
    };


    /**
     * Getting files
     */
    $scope.getFiles=function(files) {
        I3DocumentSrv.query({query: {_id: {'$in': files}}}, 10000, 1, function (res) {
            if (res.success) {
                $scope.docs = res.result;
            }
        }, function (err) {
            console.log(err);
        });
    };
    if($scope.mode === 'CREATE'){
        $scope.installationRequest.docs = [];
    }
    $scope.uploadFiles = function(files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, function (res) {
            angular.forEach(res.result , function (file) {
                $scope.installationRequest.docs.push(file)
            });
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    };

    $scope.downloadFile=function(file){
        i3HelperSrv.downloadFile(file._id, file.original_name,function success(res){

        },function error(err){

        },function progress(evt){
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };


    $scope.save=function(installRequest){
        var install = angular.copy(installRequest);
        install.client_id=installRequest.client_id._id;
        install._plateforme=installRequest.plateform._id;
        install.client_name=installRequest.client_id.name;
        if(install._installationIds && install._installationIds.length>0){
            install._installationIds .map(function(e){
                install._installationIds = e._id;
            });
        }


        if(install.comments[0].msg && install.comments[0].msg.length>0){

        }else {
        delete   install.comments;
        }

        if(installRequest._id){
                angular.forEach($scope.documents, function(file) {
            install.docs.push(file._id);
        });

            I3InstallationGroupSrv.updateById(installRequest._id,install,function(res){

                if(res && res.success){

                    $state.go("app.home.installRequest")
                }else{
                    Notification.error("INSTALLATION_GROUP_ADD_EEROR");
                    //TODO handle error
                }},function(err){

                })
            Notification.success("INSTALLATION_GROUP_ADDED");
        }else
            {
                if($scope.documents){
                    install.docs = $scope.documents;
                }
                I3InstallationGroupSrv.add(install, function (res) {

                    if (res && res.success) {

                        $state.go("app.home.installRequest")
                    } else {
                        Notification.error("INSTALLATION_GROUP_ADD_EEROR");
                        //TODO handle error
                    }

                }, function (err) {

                })
                Notification.success("INSTALLATION_GROUP_ADDED");
            }
    }




});