/**
 * Created by Mohamed Saad on 18/12/2017.
 */

angular.module("app").controller("settingsEditCtrl",function ($scope, $state, I3SettingSrv,$stateParams,$filter,$translate,Notification, I3RoleSrv, i3HelperSrv, ngDialog) {

    $scope.config = {};
    $scope.role_list = [];
    $scope.config.rolesConfig = [];



    I3SettingSrv.getById($stateParams.id, function (response) {
        $scope.setting = response.result;
        $scope.role_list = $scope.setting.rolesConfig;

    }, function (err) {
        console.log(err);
    });


    $scope. removeRole=function(index){
        $scope.setting.rolesConfig.splice(index, 1);
    };



    $scope.addRole = function () {
        var dialog = ngDialog.open({
            templateUrl: "app/commons/dialogs/settings/setting.role.dlg.tpl.html",
            controller: "settingRoleDlgCtrl",
            width: '500px',
            scope:$scope,
            resolve: {
                updateCfg: function () {
                    return undefined;
                },
                isRoleConfig: function () {
                    return true;
                },
                isNewRole: function () {
                    return true;
                },
                modelId: function () {
                    return $scope.setting._id;
                }
            }
        })
        dialog.closePromise.then(function (data) {


            if (data && data.value) {

                if(!$scope.setting["rolesConfig"] ||  $scope.setting["rolesConfig"].length<0 ) $scope.setting["rolesConfig"]=[];

                $scope.setting.rolesConfig[index].update = data.value;

            }else if(data.value===false){
                $scope.setting.rolesConfig[index].update =false;
            }
        })
    };



    $scope.editSetting = function () {
        if(!Array.isArray($scope.config.rolesConfig)){
            $scope.config.rolesConfig = []
        }
        $scope.config._id = $scope.setting._id;
        $scope.config.rolesConfig = $scope.role_list;
        $scope.config.followers = $scope.setting.followers;
        $scope.config.created_by = $scope.setting.created_by;
        $scope.config.assigned_to = $scope.setting.assigned_to;
        I3SettingSrv.updateById($scope.config._id, $scope.config, function (response) {
            if(response && response.success){
                Notification.success($translate.instant('SETTING_ADDED'));
                $state.go("app.admin.settings")
            }else{
                Notification.error($translate.instant('FAILED'))
            }
        });
    }

   $scope. editFieldForUpdate=function(fieldName,updateCfg){



           var dialog = ngDialog.open({
               templateUrl: "app/commons/dialogs/settings/setting.role.dlg.tpl.html",
               controller: "settingRoleDlgCtrl",
               width: '500px',
               resolve: {
                   updateCfg: function () {
                       return updateCfg;
                   },
                   isRoleConfig: function () {
                       return false;
                   }, isNewRole: function () {
                       return false;
                   },
                   modelId: function () {
                       return $scope.setting._id;
                   }
               }
           })
           dialog.closePromise.then(function (data) {
               console.log(data.value);
               if (data && data.value) {
                  console.log(fieldName,data.value);
                   if(!$scope.setting[fieldName]) $scope.setting[fieldName]={};

                   $scope.setting[fieldName].update = data.value;

               }else if(data.value===false){
                   $scope.setting[fieldName].update =false;
               }
           })


   }
    $scope. editFieldForUpdateByRole=function(role,updateCfg,index){



           var dialog = ngDialog.open({
               templateUrl: "app/commons/dialogs/settings/setting.role.dlg.tpl.html",
               controller: "settingRoleDlgCtrl",
               width: '500px',
               resolve: {
                   updateCfg: function () {
                       return updateCfg;
                   },
                   isRoleConfig: function () {
                       return true;
                   },isNewRole: function () {
                       return false;
                   },
                   modelId: function () {
                       return $scope.setting._id;
                   }
               }
           })
           dialog.closePromise.then(function (data) {


               if (data && data.value) {

                   if(!$scope.setting["rolesConfig"] ||  $scope.setting["rolesConfig"].length<0 ) $scope.setting["rolesConfig"]=[];

                   $scope.setting.rolesConfig[index].update = data.value;

               }else if(data.value===false){
                   $scope.setting.rolesConfig[index].update =false;
               }
           })


   }

    $scope.isString=function(prop){



        return angular.isDefined(prop) &&  angular.isString(prop)


    }
});