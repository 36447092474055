angular
    .module("app.directives")
    .directive('fullCalendar',function ($cookieStore) {
        return {
            scope : {
                eventSource : '=ngModel',options : '=fcOptions'
            },
            link:function (scope, elm) {
                console.log("link")
                var CALENDAR_DEFAULTS = {
                    locale:$cookieStore.get('lang')
                };
                var calendar;
                init();
                scope.$watch('eventSource', watchEventSource,true);
                scope.$watch('options',watchDirective,true);
                scope.$on('$destroy', function () { destroy();});
                function init() {
                    console.log("init")
                    if (!calendar) {
                        calendar = $(elm).html('');
                    }
                    calendar.fullCalendar(getOptions(scope.options));
                }
                function destroy() {
                    if(calendar && calendar.fullCalendar){
                        calendar.fullCalendar('destroy');
                    }
                }
                function getOptions(options) {
                    return angular.extend(CALENDAR_DEFAULTS,{
                        events:scope.eventSource
                    },options);
                }
                function watchDirective(newOptions,oldOptions) {
                    console.log("watchDirective" , oldOptions ,newOptions)
                    if (newOptions !== oldOptions) {
                         destroy();
                         init();
                    } else if ((newOptions && angular.isUndefined(calendar))) {
                        init();
                    }
                }
                function watchEventSource(newEvents,OldEvents) {
                            if(OldEvents && OldEvents.length>0 ){
                                calendar  .fullCalendar( 'removeEvents');
                            }


                        calendar .fullCalendar( 'addEventSource', newEvents );


                }
            }
        };
    });