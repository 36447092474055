angular.module("app").controller('adminCtrl', function ($scope, $rootScope, $state) {
    /**
     * Make Platform the main admin route
     */
    if($state.current.name === 'app.admin'){
        $state.go('app.admin.platform');
    }
    $scope.search = {};
    $scope.numberPages = {};

});