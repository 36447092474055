/**
 * Created by ngiTeam on 12/03/2015.
 * the goe-service proxy to define the main geo-service will be used.
 * this service interact with the implementation of the geo-service. each geo-service must have the implementation of the method defined on this proxy
 * please the example of Benomad service named bndServiceAdapter.
 * if you like to add an other geo-service. create an angular service that implements the method given in this service proxy
 * and inject it in the this service
 */

var geoServiceProxy = angular.module("geoServiceProxyModule", ['bndGeoServices']);

geoServiceProxy.factory("geoServiceProxy", ['bndServiceAdapter', "$q", function (bndServiceAdapter, $q) {


  /**
   * Address geo-coding
   * @param maxresult
   * @param country
   * @param city
   * @param street
   * @param streetNumber
   * @param language
   * @param state
   * @param postalCode
   * @param district
   * @param countrycode
   */
  this.geoCodeAddress = function (maxresult, country, city, street, streetNumber, language, state, postalCode, district, countrycode) {
    if (current_geoserver === BENOMAD_GEO_SERVER_CONSTANT)
      return bndServiceAdapter.geoCodeAddress(maxresult, country, city, street, streetNumber, language, state, postalCode, district, countrycode);
    // you can add here other geoServer example : else if (current_geoserver==google_geo_service... )
    else
      alert("Unknown geo-server ");
  };

  /**
   * reverse geocode
   * @param maxresult
   * @param lng
   * @param lat
   * @param radius
   * @param language

   */


  this.reverseGeoCode = function (maxresult, lng, lat, radius, language) {
    if (current_geoserver === BENOMAD_GEO_SERVER_CONSTANT)
      return bndServiceAdapter.reverseGeoCode(maxresult, lng, lat, radius, language);
    // you can add here other geoServer example : else if (current_geoserver==google_geo_service... )
    else
      alert("Unknown geo-server ");
  };
  /**
   * Reverse Geocoding for Batch works is the process of translating a (Latitude, Longitude) location back to an address, place, city, state or identifying other attributes about or near the location.
   * @param maxresult
   * @param lng
   * @param lat
   * @param radius
   * @param language

   */
  this.revGeoBatch = function (maxresult, lng, lat, radius, language) {
    if (current_geoserver === BENOMAD_GEO_SERVER_CONSTANT)
      return bndServiceAdapter.revGeoBatch(maxresult, lng, lat, radius, language);
    // you can add here other geoServer example : else if (current_geoserver==google_geo_service... )
    else
      alert("Unknown geo-server ");

  };
  /**
   * calculate route for the given road steps
   * @param maxresult
   * @param roadSteps an array  of point define the steps of the road each point must has (lat , lng)  attribute as respectively latitude and longitude of point
   * @param criterias see {@link routingConst.criterias}
   * @param transportType {@link transportTypeConst}
   * @param language
   * @param option an array of string  represents the options to set for returned result see {@link routingConst.option} for available values
   */


  this.calculateRoute = function (roadSteps, criterias, options, transportType, language) {
    if (current_geoserver === BENOMAD_GEO_SERVER_CONSTANT)
      return bndServiceAdapter.calculateRoute(roadSteps, criterias, options, transportType, language);
    // you can add here other geoServer example : else if (current_geoserver==google_geo_service... )
    else
      alert("Unknown geo-server ");
  };
  /**
   * calculate route between two points
   * @param maxresult
   * @param departPoint a point object contains (lat , lng)  used as route departure
   * @param arrivalPoint a point object contains (lat , lng)  used as route arrival
   * @param criterias see {@link routingConst.criterias}
   * @param transportType {@link transportTypeConst}
   * @param language
   * @param option an array of string  represents the options to set for returned result see {@link routingConst.option} for available values
   */
  this.calculateRoute2Points = function (departPoint, arrivalPoint, criterias, transportType, language, option) {
    if (current_geoserver === BENOMAD_GEO_SERVER_CONSTANT)
      return bndServiceAdapter.calculateRoute2Points(roadSteps, criterias, transportType, language, option);
    // you can add here other geoServer example : else if (current_geoserver==google_geo_service... )
    else
      alert("Unknown geo-server ");

  };

  this.findPointOfInterests = function (maxResult, lng, lat, radius, language, classids, names, option) {
    if (current_geoserver === BENOMAD_GEO_SERVER_CONSTANT)
      return bndServiceAdapter.findPointOfInterests(maxResult, lng, lat, radius, language, classids, names, option);
    // you can add here other geoServer example : else if (current_geoserver==google_geo_service... )
    else
      alert("Unknown geo-server ");
  };

  return this;


}]);
