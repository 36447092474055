angular.module('app').controller('interventionDetailsCtrl', function ($scope,$rootScope, $state, $stateParams, $translate,ngDialog, Notification, I3InterventionSrv,I3UserSrv, I3DocumentSrv, i3HelperSrv, constantSrv, iaxeedAssetSrv, iaxeedSocketSrv) {

    $scope.status = constantSrv.getInterventionStatus();
    var now = moment().clone();
    $scope.dayRes = now;

    $scope.pickerDay = {
        format : "DD/MM/YYYY",
        position: "top left",
        init: null,
        autoTrans : false,
        enableTime : false,
        enableDate : true,
        enableMinutes : false,
        defaultView : "DATE",
        maxDate: moment("2020/05/22", "YYYY/MM/DD"),
        minDate: now,
        getDate : function (date) {
            $scope.dayRes = date;
            $scope.installationRequest.preferred_dt = date;
        }
    };
    $scope.open2 = function() {
        $scope.popup2.opened = true;
    };
    $scope.popup2 = {
        opened: false
    };
    $scope.opened = {};
    $scope.open = function($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened[elementOpened] = !$scope.opened[elementOpened];
    };

    $scope.emailFollow = "";

    $scope.uploadFiles = function(files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, function (res) {
            angular.forEach(res.result , function (file) {
                $scope.intervention.docs.push(file)
            });
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    };

    $scope.downloadFile=function(file){
        i3HelperSrv.downloadFile(file._id, file.original_name,function success(res){

        },function error(err){

        },function progress(evt){
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };


    $scope.getInterventions = function () {
        if($stateParams.id && $stateParams.id.length>0){
            I3InterventionSrv.query({query:{ _id: $stateParams.id },
                options:{populate:  ["_assigned_to", "_claim", "_created_by", "docs", {path:'_claim', populate : {path : '_plateforme'}},{path : 'followers.user'}, {path: 'comments.owner', select: 'login'}]}},1,0,function(res) {
                if (res && res.success &&  res.result.length>0){
                    $scope.intervention =  res.result[0];
                    console.log($scope.intervention)
                    $scope.plateforme = $scope.intervention._claim._plateforme;

                    /**
                     * Get Assets
                     */
                    if($scope.intervention._claim.asset && $scope.intervention._claim.asset.length>0){
                        if($scope.plateforme.type == 'NGI_FLEET'){
                            return;
                        }
                        var assetIds = [];
                        angular.forEach($scope.intervention._claim.asset, function (asset) {
                            assetIds.push(asset);
                        });

                        var query = {
                            _id: {
                                $in : assetIds
                            }
                        };
                        var options = {};

                        iaxeedAssetSrv.query({query : query, options:options}, 10000, 1, function (res1) {
                            if(res1.result){
                                $scope.assets = res1.result;
                            }
                        }, function (err) {
                            console.log(err);
                        });
                    }

                    $scope.getFiles($scope.intervention.docs);
                    delete $scope.intervention._created_by;

                    if($scope.intervention.comments || $scope.intervention.comments.length<=0)
                        $scope.intervention.comments=[{owner:$rootScope.SESSION.user._id}];


                } else
                    Notification.error("FIND_BY_ID_ERROR");
            },function(err){
                Notification.error("FIND_BY_ID_ERROR")
            })
        }
    };

    $scope.cancel = function () {
        $state.go('app.home.intervention');
    };


    $scope.getInterventions();


    /**
     * Following the current intervention
     */
    $scope.follow = function () {
        if($scope.emailFollow && $scope.emailFollow !== ""){

        var follower = {};
        if(!$scope.intervention.followers){
            $scope.intervention.followers = [];
        }

        follower.email =  $scope.emailFollow;


        var query = {
            "contact.mail" : $scope.emailFollow
        };
        var options =  {};

        var exist = false;

        angular.forEach($scope.intervention.followers, function (follower) {
            if(follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()){
                exist = true;
            }
        });

        if(!exist){
            I3UserSrv.query({query: query, options: options}, 10000, 0, function (res) {
                if(res.success){
                    follower.user = res.result[0];
                }

                $scope.intervention.followers.push(follower);

                delete $scope.intervention.__v;

                I3InterventionSrv.updateById($scope.intervention._id, $scope.intervention, function (response) {
                    if(response.success){
                        Notification.success({
                            title : "Following claim",
                            message : "The email address" + $scope.emailFollow  +" is now following intervention " + $scope.intervention._claim.object
                        });

                        $scope.emailFollow = "";
                    }else{
                        Notification.error({
                            title: "Erreur",
                            message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.intervention._claim.object +"!"
                        });
                        $scope.intervention  = {};
                    }
                }, function (err) {
                    var msg = "";
                    if( err.error.errorString){
                        msg = err.error.errorString;
                    }
                    Notification.error({
                        title: "Erreur",
                        message : msg
                    });
                })
            });
        }else{
            Notification.warning({title: "Already follower", message: "This email is already subscribed as a follower here"});
            $scope.emailFollow = "";
        }


        }


    };

    /**
     * Remove follower
     */
    $scope.removeFollower = function (follower) {
        if($scope.intervention.followers.indexOf(follower) >= 0){
            $scope.intervention.followers.splice($scope.intervention.followers.indexOf(follower), 1);
            delete $scope.intervention.__v;

            I3InterventionSrv.updateById($scope.intervention._id, $scope.intervention, function (response) {
                if(response.success){
                    Notification.success({
                        title : "Removing intervention follower",
                        message : "The follower of intervention " + $scope.intervention._claim.object+" was removed successfully"
                    });
                }else{
                    Notification.error({
                        title: "Erreur",
                        message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.intervention._claim.object +"!"
                    });
                    $scope.intervention  = {};
                }
            }, function (err) {
                var msg = "";
                if( err.error.errorString){
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: "Erreur",
                    message : msg
                });
            })
        }
    };

    $scope.add= function(content){
        if(content){
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded.owner = $rootScope.SESSION.user._id;
            commentAdded.msg = content;
            $scope.intervention.comments.push(commentAdded);
            I3InterventionSrv.updateById($scope.intervention._id, $scope.intervention, function (response) {
                if (response.success) {
                    Notification.success($translate.instant("ALERT_COMMENT_ADDED"));
                    $scope.getInterventions();
                }
            });
            $scope.content = "";
        }


    };
    $scope.updateInterventionStatus=function(intervention,$data){
        I3InterventionSrv.updateById(intervention._id,{status:$data}, function(res){
            if(res.success){
                Notification.success($translate.instant("INTERVENTION_UPDATED"));
            }
        },function(err){
        })
    };

    $scope.save = function (intervention) {
        I3InterventionSrv.updateById(intervention._id, intervention, function (res) {
            if(res.success){
                Notification.success({message : $translate.instant('INTERVENTION_UPDATED')});
                $state.go('app.home.intervention');
            }else{
                Notification.warning({message : $translate.instant('INTERVENTION_UPDATE_FAILED')});
            }
        }, function (err) {
            Notification.error({message : $translate.instant('INTERVENTION_UPDATE_FAILED')});
            console.log(err);
        })

    };
    $scope. updateInterventionProp=function(prop,intervention,$data){
        var updateData={};

        if(prop==="status"){
            if($data==="Planned" && !updateData.planned_begin_dt){
                updateData.planned_begin_dt=new Date();
                intervention.planned_begin_dt=new Date();
            }else if($data==="InProgress" && !updateData.begin_dt){
                updateData.begin_dt=new Date();
                intervention.begin_dt=new Date();
            }else if ($data==="Done" && !updateData.end_dt) {
                updateData.end_dt=new Date();
                intervention.end_dt=new Date();
            }

        }
        if(prop==="planned_begin_dt" && intervention.status==="New"){
            updateData.status="Planned";
            intervention.status="Planned"
        }
        if(prop==="begin_dt" && intervention.status!=="InProgress" && intervention.status!=="Done"){
            updateData.status="InProgress";
            intervention.status="InProgress"
        }
        if(prop==="end_dt" && intervention.status!=="Done"){
            updateData.status="Done";
            intervention.status="Done";
        }
        updateData[prop]=$data;
        if(prop==="asset"){
            updateData={asset:$data._id,asset_name:$data.name};
            intervention.asset_name=$data.name;

        }
        I3InterventionSrv.updateById(intervention._id,updateData, function(res){

            if(res && res.success){
                Notification.success($translate.instant("INTERVENTION_UPDATED"))
            }else{
                Notification.success($translate.instant("INTERVENTION_UPDAT_ERROR"))
            }

        },function(err){

            Notification.success($translate.instant("INTERVENTION_UPDAT_ERROR"));
        })
    };

    $scope.showRealtime = function (asset) {
        $scope.asset = asset;
        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });

        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });
    };


});