/**
 * Created by Mohamed Saad on 15/12/2017.
 */

angular.module("app").controller("adminSettingCtrl",function ($scope,$rootScope, $state, $translate, I3RoleSrv,ngDialog, Notification, constantSrv, I3SettingSrv) {

    $scope.itemsByPage=50;
    $scope.models = constantSrv.getModel();

    $scope.loadSettings = function () {
        I3SettingSrv.query({},100,0, function (data) {
            if (data.success) {
                $scope.settings = data.result;
                console.log($scope.settings)
            } else {
                console.error(data.error)
            }

        }, function (data) {
            console.log(data)
        });
    };


    $scope.addRole = function (setting) {
        $scope.setting = setting;
        ngDialog.open({
            templateUrl : "app/commons/dialogs/settings/setting.role.dlg.tpl.html",
            controller : "settingRoleDlgCtrl",
            scope: $scope,
            width:'500px'
        })
    };
  $scope.edit = function (setting) {

         $state.go("app.admin.settings.edit",{id:setting._id})
    };

    $scope.remove = function (setting,role) {
        setting.rolesConfig.splice(role.role.indexOf(role.role), 1);
        I3SettingSrv.updateById(setting._id, setting, function (response) {
            if(response && response.success){
                Notification.success($translate.instant('SETTING_UPDATED'));
                $scope.loadSettings();
            }else{
                Notification.error($translate.instant('FAILED'))
            }
        })
    }

});