angular.module('app.dialogs')
    .controller('profileDlgCtrl', function ($scope, $rootScope, I3UserSrv, Notification) {
        $scope.user = {};
        $scope.warning = false;

        $scope.user = angular.copy($rootScope.SESSION.user);

        $scope.save = function () {
            var newUser = {};
            if($scope.user.newPass && $scope.user.newPass !== ""){
                if($scope.user.newPass === $scope.user.confirm){
                    newUser.password = $scope.user.newPass;

                    newUser.firt_name = $scope.user.firt_name;
                    newUser.last_name = $scope.user.last_name;
                    newUser.login = $scope.user.login;
                    if($scope.user.contact){
                        newUser.contact = {};
                        newUser.contact.mail = $scope.user.contact.mail;
                        newUser.contact.phone = $scope.user.contact.phone;
                        newUser.contact.fax = $scope.user.contact.fax;
                    }

                    newUser.apiKey = $scope.user.apiKey;


                    I3UserSrv.updateById($scope.user._id, newUser, function (res) {
                        if(res.success){
                            Notification.success({title: "Profile updated", message : "Votre profile a été modifier avec succès!"});
                            $scope.closeThisDialog();
                        }else{
                            Notification.error({title: "Profile update failed", message : "Un erreur s'est produite lors de la modification de votre profile!"});
                            $scope.closeThisDialog();
                        }
                    }, function (err) {
                        Notification.error({title: "Profile update failed", message : "Un erreur s'est produite lors de la modification de votre profile!"});
                        $scope.closeThisDialog();
                    })


                }else{
                    $scope.warning = true;
                    $scope.warningMsg = "Veuillez vérifier votre mot de passe!"
                }
            }else{
                newUser.first_name = $scope.user.first_name;
                newUser.last_name = $scope.user.last_name;
                newUser.login = $scope.user.login;

                if($scope.user.contact){
                    newUser.contact = {};
                    newUser.contact.mail = $scope.user.contact.mail;
                    newUser.contact.phone = $scope.user.contact.phone;
                    newUser.contact.fax = $scope.user.contact.fax;
                }
                if($scope.user.apiKey && $scope.user.apiKey !== ""){
                    newUser.apiKey = $scope.user.apiKey;
                }

                I3UserSrv.updateById($scope.user._id, newUser, function (res) {
                    if(res.success){
                        Notification.success({title: "Profile updated", message : "Votre profile a été modifier avec succès!"});
                        $scope.closeThisDialog();
                    }else{
                        Notification.error({title: "Profile update failed", message : "Un erreur s'est produite lors de la modification de votre profile!"})
                        $scope.closeThisDialog();
                    }
                }, function (err) {
                    Notification.error({title: "Profile update failed", message : "Un erreur s'est produite lors de la modification de votre profile!"})
                    $scope.closeThisDialog();
                })
            }


        }

    });