angular.module('app').controller('adminDocumentsCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3DocumentSrv,i3HelperSrv,messageBox,I3UserSrv ) {


    $scope.itemsByPage = 50;
    //$scope.search = {};


    /**
     * Getting users
     */
    $scope.loadDocuments  = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;


        var query = {};
        var options={populate:"_created_by"}
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
           // start = pagination.start / number + 1 || 1;
            $scope.numberPages.documentItemByPages = number
            if($scope.numberPages.documentNumberPages){
                start = $scope.numberPages.documentNumberPages
            }else{
                start = pagination.start / number + 1 || 1;
            }
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }

/*        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.original_name) {
                query["original_name"] = {"$regex": tableState.search.predicateObject.original_name, "$options": "i"};
            }
            if (tableState.search.predicateObject.fileName) {
                query["fileName"] = {"$regex": tableState.search.predicateObject.fileName, "$options": "i"};
            }
        }*/
        if(!$scope.search.original_name) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.original_name) {
                    $scope.search.original_name = tableState.search.predicateObject.original_name
                    query["original_name"] = {"$regex": tableState.search.predicateObject.original_name, "$options": "i"};
                }
            }else{
                query.original_name = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.original_name = $scope.search.original_name
            }
            query.original_name = {'$regex' :$scope.search.original_name, '$options' : 'i'}
        }
        if(!$scope.search.fileName) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.fileName) {
                    $scope.search.fileName = tableState.search.predicateObject.fileName
                    query.fileName = {'$regex': tableState.search.predicateObject.fileName, '$options': 'i'}
                }
            }else{
                query.fileName = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.fileName = $scope.search.fileName
            }
            query.fileName = {'$regex' :$scope.search.fileName, '$options' : 'i'}
        }

        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.original_name = tableState.search.predicateObject.original_name
        }
        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.fileName = tableState.search.predicateObject.fileName
        }

        if($scope.search  && $scope.search._created_by){
            query["_created_by"]=$scope.search._created_by._id;
        }

        I3DocumentSrv.query({query: query,options:options} , number, start, function (response) {
            if(response.success){
                $scope.documents = response.result;
                $scope.documentCount = response.total_count;
                $scope.documentCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                tableState.pagination. start=start*number-1>=0? start*number-1: 0;
                $scope.tableState = tableState;
            }
        });
    };


    /**
     * Removing product
     * @param product
     */
    $scope.remove = function (doc) {



            messageBox.confirm("DELETE_DOCUMENT_TITLE", "DELETE_DOCUMENT_MESSAGE").then(function (res) {

                if (res) {
                    I3DocumentSrv.delete(doc._id, function (res) {
                        if (res && res.success) {
                            Notification.success($translate.instant("DOCUMENT_DELETED"));
                            if ($scope.stCtrl) {
                                $scope.stCtrl.pipe();
                            }
                        } else {
                            Notification.warn($translate.instant("DOCUMENT_DELETE_ERROR"));
                        }

                    }, function (err) {
                        Notification.error($translate.instant("DOCUMENT_DELETE_ERROR"));
                    })

                }

            }, function (reject) {
                //Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
            })

    };

    $scope. searchUser=function($select){

        I3UserSrv.query({query:{$or:[
            {login:{'$regex' :$select.search, '$options' : 'i'}},
            {first_name:{'$regex' :$select.search, '$options' : 'i'}},
            {last_name:{'$regex' :$select.search, '$options' : 'i'}}
        ]}},10,0,function(res){

            $scope.users=res.result;
        },function(err){

        })
    }
    $scope.downloadFile=function(file){
        i3HelperSrv.downloadFile(file._id, file.original_name,function success(res){

        },function error(err){

        },function progress(evt){
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    }

    $scope.onPageChange = function (val) {
        $scope.numberPages.documentNumberPages = val
        $scope.numberPages.documentItemByPages = $scope.itemsByPage
    }

    if(!$scope.numberPages.documentItemByPages || $scope.numberPages.documentItemByPages === undefined){
        $scope.itemsByPage = 50
    }else{
        $scope.itemsByPage = $scope.numberPages.documentItemByPages
    }

});