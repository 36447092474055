/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */
angular
    .module('app').run(function($rootScope,$transitions,$window,$location,$state,CONFIG,$translate,$cookieStore,i3HelperSrv,plateformHelperSrv,NGIFleetHelperSrv){
    $rootScope.config=CONFIG;
    $rootScope.$state = $state;
    $rootScope.appData={};
    //set language from cookies
    $translate.use($cookieStore.get('lang'));
    // Check permissions
    $rootScope.checkPermission=function(model,action,type){

        var authorized=false;

        if($rootScope.SESSION.user._role.permissions){

            $rootScope.SESSION.user._role.permissions.forEach(function(value){

                var splitted=value.split("_");
                var crud=splitted[0];
                var accessType=splitted[1];
                var roleModel = splitted.slice(2, splitted.length).join("_");
                // Check if crud is read only (Do not show on admin)
                if(roleModel===model){
                    if(crud.indexOf(action)>=0){
                        authorized = !(type && accessType !== type);
                    }else{
                        authorized=false;
                    }
                }
            })
        }
        return authorized;
    };

    if ($window.sessionStorage.token) {
        $rootScope.SESSION =JSON.parse($window.sessionStorage.SESSION);
        i3HelperSrv.setLoggedInStatus(true);
        if($window.sessionStorage.plateformToken){
            try {
                var plateformSession = JSON.parse($window.sessionStorage.plateformSession);
                $rootScope. plateform = JSON.parse($window.sessionStorage.plateform);
                if($rootScope. plateform=="NGI_FLEET"){
                    NGIFleetHelperSrv.setPlateForm($rootScope. plateform ,plateformSession);
                }else{
                    plateformHelperSrv.setPlateForm($rootScope. plateform ,plateformSession);
                }

            }catch(e){
                console.warn(e);
            }


        }

    }else{
        $location.path("/login");
    }

    //Check route accessibility based on permissions
    $rootScope.checkRouteAccess = function (access) {
        return $rootScope.checkPermission(access[0], access[1]);
    };

    /**
     *
     * Intercept transition and check route accessibility
     * - If allowed : return
     * - If not allowed:
     * # if 'fromState' exist in navigation history then go to 'fromState'.
     * # otherwise (URL wasn't clicked but typed which results 'toState' with no 'fromState') go to 'login'.
     *
     */
    $transitions.onBefore({},function($transitions) {
        var fromState = $transitions.$from();
        var toState = $transitions.$to();
        var signedIn = $rootScope.SESSION;
        if(signedIn){
            if(fromState.name === "" || fromState.name === "login"){

            }


            var pub = angular.isUndefined(toState.access);

            if(!pub){
                if(!$rootScope.checkRouteAccess(toState.access)){
                    if(fromState !== ""){
                        return false;
                    }else{
                        $state.go("login");
                        return false;
                    }
                }
            }
        }



    });

})
    .factory('authInterceptor', function ($rootScope, $q, $window,CONFIG) {
            return {
                request: function (config) {
                    config.headers = config.headers || {};

                    if(config.url.indexOf(CONFIG.API_URI)>=0) {

                        if ($window.sessionStorage.token && !config.headers.Authorization) {
                           config.headers.Authorization = 'Bearer ' + $window.sessionStorage.token;
                           config.headers["oo-apikey"] =$rootScope.SESSION.user.oo_key;
                        }


                    }else if( $rootScope. plateform &&
                        $rootScope. plateform.url &&
                        config.url.indexOf(  $rootScope. plateform.url)>=0){

                        if ($window.sessionStorage.plateformToken && !config.headers.Authorization) {
                             config.headers.Authorization = 'Bearer ' + $window.sessionStorage.plateformToken;
                        }
                    }

                    return config;
                },
                response: function (response) {
                    /* if(response.status === 400 || response.status === 401 || response.status === 403 ) {
                         $rootScope.$broadcast("sessionExpired", response.status);
                     }*/


                    return response || $q["when"](response);
                },

                responseError:function(response){
                    //
                    //Except total api for session expired
                    if(response.status===401 && response.config.url && response.config.url.indexOf(CONFIG.API_URI)>=0 && response.config.url.indexOf(CONFIG.API_URI+'/total/')>=0 ) {
                        $rootScope.$broadcast("TotalSessionExpired", response);
                    }else  if(response.status===401 && response.config.url && response.config.url.indexOf(CONFIG.API_URI)>=0 && $rootScope.SESSION.user.oo_key) {
                        $rootScope.$broadcast("sessionExpired", response);
                    }else{
                        $rootScope.$broadcast("responseError", response);
                    }
                    return response;
                }
            }
        }
    )
    .config(['$qProvider', function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);

    }])
    .run(["$rootScope","$q","$state",function($rootScope,$q, $state){
        $rootScope.$on("sessionExpired",function(response){

            $state.go("login");
        });
    }]).config(["$httpProvider",function ($httpProvider) {
    $httpProvider.interceptors.push('authInterceptor');

}]).run(function($rootScope ,$q){
        if(typeof Stimulsoft !== 'undefined' ) {


        }else{
            var defer = $q.defer();
            $rootScope.loadScriptPromise=defer.promise;
            zip.installJS("app/assets/utils/stimulsoft_flat/cdn.zip", ["stimulsoft.reports.js", "stimulsoft.viewer.js"], function () {

                //console.log("script loaded ");

                if($rootScope.loadScriptPromise) {
                    defer.resolve();
                }

            });

        }
    });
