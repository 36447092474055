angular.module('app').controller("addInstallationDlgCtrl", function ($scope,I3ProductSrv, Notification,installations,index) {

    if(installations){
        $scope.installation=  installations;
        $scope.product=installations._product;
    }else{
        $scope.installation={}
    }

    $scope. searchProduct=function($select){

        I3ProductSrv.query({query:{name:{'$regex' :$select.search, '$options' : 'i'}}},10,0,function(res){

            $scope.products=res.result;
        },function(err){

        })
    }
    $scope.selectProduct=function(product){
        $scope.product=product;

    }
    $scope.add=function(installation){

        if(Number.isFinite(index)){
            $scope.installationRequest.installations[index]=installation;

        }else{
            if(!$scope.installationRequest.installations ||  !Array.isArray($scope.installationRequest.installations )) $scope.installationRequest.installations=[]
            $scope.installationRequest.installations.push(installation);
        }


        $scope.closeThisDialog()
    }

});