angular.module("app.services").factory('iaxeedSensorSrv', function ($translate) {

    var service = {};

    service.getRealSensorValue=function(voltage, minValueVolt,  maxValueVolt,  realMinValue,  realMaxValue){
        var realValue = voltage;
        if(Number.isFinite(minValueVolt) && Number.isFinite(maxValueVolt) && Number.isFinite(realMinValue) && Number.isFinite(realMaxValue)) {
            realValue = (voltage - minValueVolt)*(realMaxValue - realMinValue)/(maxValueVolt - minValueVolt) + realMinValue;
        }

        return realValue;
    };


    service.getOdoAbsoluteSRC=function() {
        return  ["rt.io.CAN_ODO","rt.io.odo_abs"];
    }
    service.getUnitList=function(){
        return [
            {"name":$translate.instant("NO_UNIT"), "code": " "},
            {"name":$translate.instant("LITRE"), "code": "L"},
            {"name":$translate.instant("CELSIUS_DEGREE"), "code": "°C"},
            {"name":$translate.instant("FARENHEIT_DEGREE"), "code": "°F"},
            {"name":$translate.instant("KILOMETRE"), "code": "Km"},
            {"name":$translate.instant("KILOGRAM"), "code": "Kg"},
            {"name":$translate.instant("KILOMETRE_PER_HOUR"), "code": "Km/h"},
            {"name":$translate.instant("MILE_PER_HOUR"), "code": "Mph"},
            {"name":$translate.instant("DEGREE"), "code": "°"},
            {"name":$translate.instant("METER"), "code": "M"},
            {"name":$translate.instant("MILLI_AMPERES"), "code": "mA"},
            {"name":$translate.instant("ROTARY_PER_MINUTE"), "code": "Rpm"},
            {"name":$translate.instant("VOLTS"), "code": "V"},
            {"name":$translate.instant("PERCENTAGE"), "code": "%"}
        ]
    };

    /**
     * Get Sensor list by family
     * @returns {{BINARY: [string,string,string,string], ANALOGIC: [string,string,string,string,string,string,string]}}
     */
    service.getSensorsTypes = function () {
        return {
            BINARY :  ["TAXI_STATUS", "DOOR_STATUS", "REFRIGERATION_STATUS", "SPINNING_STATUS"],
            STATUS: {
                "TAXI_STATUS" : {
                    enabled : "AVAILABLE",
                    disabled : "OCCUPIED"
                },
                "DOOR_STATUS" : {
                    enabled : "OPENED",
                    disabled : "CLOSED"
                },
                "REFRIGERATION_STATUS" : {
                    enabled : "ENABLED",
                    disabled : "DISABLED"
                },
                "SPINNING_STATUS" : {
                    enabled : "SPINNING",
                    disabled : "STOP"
                }
            },
            ANALOGIC : [ "TEMPERATURE", "FUEL_LEVEL", "GAS", "FUEL_USED", "PRESSURE", "ODOMETER", "RPM"],
            ICONS :  {
                "DOOR_STATUS_ON": "door-opened",
                "DOOR_STATUS_OFF": "door-closed",
                "ODOMETER": "kilometrage",
                "TEMPERATURE" : "temperature",
                "FUEL_LEVEL" : "fuel",
                "SPINNING_STATUS_OFF": "rotation-stop",
                "SPINNING_STATUS_ON": "rotation",
                "TAXI_STATUS_ON": "taxi-available",
                "TAXI_STATUS_OFF": "taxi-unavailable",
                "FUEL_USED": "fuel-used",
                "GAS": "gas",
                "RPM": "rpm",
                "REFRIGERATION_STATUS_ON" : "refrigerator-start",
                "REFRIGERATION_STATUS_OFF" : "refrigerator-stop",
                "PRESSURE" : "pressure"

            }
        }
    };

    /**
     * Sensor family from type
     * @param type
     * @returns {string}
     */
    service.getSensorFamily = function (type) {
        var family = "ANALOGIC";
        if(service.getSensorsTypes().BINARY.indexOf(type) > -1){
            family = "BINARY";
        }
        if(service.getSensorsTypes().ANALOGIC.indexOf(type) > -1){
            family = "ANALOGIC";
        }

        return family;
    };

    service.isAbsoluteValue=function(sensor){

        console.log("IS Absolute ", sensor)
        if(sensor.type=="ODOMETER"){

            if(service.getOdoAbsoluteSRC().indexOf(sensor.src)>=0){
                sensor.isAbsolute=true;
            }else{
                sensor.isAbsolute=false;
            }



        }
        console.log("IS Absolute 2 ", sensor)
        return    sensor;
    }
    return service;

});
