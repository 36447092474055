angular.module('app.dialogs').controller('confirmDeletePlatformDlgCtrl', function ($scope, $rootScope, ngDialog, I3PlateformeSrv, Notification) {

    $scope.confirm = function () {
        I3PlateformeSrv.delete($scope.platform._id, function (response) {
            if(response.success){
                $scope.platforms.splice($scope.platforms.indexOf($scope.platform), 1);
                Notification.success({
                    title: "Platform deleted",
                    message : "Platform was deleted successfully!"
                });
                $scope.platform = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : "Une erreur est survenue lors de la suppression !"
                });
                $scope.platform = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: "Erreur",
                message : "Une erreur est survenue lors de la suppression  !"
            });

            $scope.platform = {};
        });

        $scope.platform = {};
        $scope.closeThisDialog();
    }

});