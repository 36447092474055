/**
 * Created by Kais CHAIBI on 29/11/2017.
 */
var  I3_SERVICES=[

    {name:"I3ProductSrv",path:"product"},
    {name:"I3PlateformeSrv",path:"plateforme"},
    {name:"I3ActionLogSrv",path:"actionLog"},
    {name:"I3InstallationSrv",path:"installation"},
    {name:"I3InterventionSrv",path:"intervention"},
    {name:"I3ClaimSrv",path:"claim"},
    {name:"I3InstallationGroupSrv",path:"installationgroup"},
    {name:"I3UserSrv",path:"user"},
    {name:"I3RoleSrv",path:"role"},
    {name:"I3DocumentSrv",path:"document"},
    {name:"I3SettingSrv",path:"setting"},
    {name:"I3ClientFormSrv",path:"clientform"}

];
/**
 * ad constant service for further use
 */
angular.module("app.services").constant("I3_SERVICES",I3_SERVICES)



// create services
    I3_SERVICES.forEach(function (val) {

        angular.module("app.services").factory(val.name, ["AbstractApiSrv","CONFIG",function (AbstractApiSrv,CONFIG) {

            var service=new AbstractApiSrv(val)
                service.setApiUri(CONFIG.API_URI);
            return service;
        }])
    })




