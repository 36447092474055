/**
 * Created by Kais CHAIBI on 29/11/2017.
 */


angular.module("app.services").service('i3HelperSrv',function ($http,$rootScope, $window,AbstractApiSrv,I3_SERVICES,$injector,Upload,$timeout,FileSaver, Blob,messageBox) {


            function I3HelperSrv(){

            }

            I3HelperSrv. prototype.setLoggedInStatus=function(status){
                    this. loggedIn = status;
            }
            I3HelperSrv.prototype.login=function(user,success,error){



                var  self=this;
                    $http.post($rootScope.config.API_URI  + '/' + "login" , user).then(function (response) {
                        // this callback will be called asynchronously
                        // when the response is available

                        try {
                            if (response && response.data && response.data.success) {
                                $window.sessionStorage.token = response.data.result.token;


                                $rootScope.SESSION = response.data.result;
                                $window.sessionStorage.SESSION = JSON.stringify(response.data.result);
                                self.loggedIn = true;
                                success(response.data);

                            }
                            else if (!response.data.success && response.data.error) {

                                success(response.data)
                            }
                            else {

                                error(response);
                            }
                        }catch(e){
                            console.log(e);
                        }
                    },function(err){

                        error(err);
                    })

                }


    I3HelperSrv.prototype.logout=function(success){
        delete $window.sessionStorage.token;
        delete $window.sessionStorage.SESSION;
        delete $window.sessionStorage.plateformToken;
        delete $window.sessionStorage.plateformSession;

        if (success) success()
    }
    I3HelperSrv.prototype.getEnums=function(success, error){

        $http.get($rootScope.config.API_URI+'/enums').then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){
                success(response.data.result);
            }
            else if (!response.data.success && response.data.error){
                success(response.data)
            }
            else{

                error(response);
            }



        },function(data) {
            error(data)

        });
    };

    I3HelperSrv.prototype.getFieldsByCollectionName=function(collectionName,success, error){

        $http.get($rootScope.config.API_URI+'/schema/'+collectionName).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){
                success(response.data.result);
            }
            else if (!response.data.success && response.data.error){
                success(response.data)
            }
            else{

                error(response);
            }



        },function(data) {
            error(data)

        });
    };

    I3HelperSrv.prototype.uploadFiles=function(files ,success,error,progress){
        //open progress bar
        try {
            if (files && files.length) {
                messageBox.showSpinnerPercent("UPLOAD", files);
                Upload.upload({
                    url: $rootScope.config.API_URI + '/' + "document",
                    data: {
                        file: files
                    }
                }).then(function (response) {

try{
                    $rootScope.$broadcast("progressClose");
                    success(response.data);
                }catch(e){
                    console.error(e);
                }

                }, function (response) {

                    $rootScope.$broadcast("progressClose");


                    if (response.status > 0) {

                        error(response);
                    } else {
                        success(response);
                    }


                }, function (evt) {


                    $rootScope.$broadcast("progress", evt);
                    progress(evt);

                });
            } else {
                error("NO_FILES");
            }
        }catch(e){
            console.log("error",e);
        }


    }

    I3HelperSrv.prototype.downloadFile=function(id ,fileName,success,error,progress){
        //open progress bar
        messageBox.showSpinnerPercent("DOWNLOAD",fileName);
        $http({method:"GET",url:$rootScope.config.API_URI  + '/' + "document/download/"+id , responseType: "blob" ,onProgress: function(event) {
            $timeout(function() {

                progress(event);
                $rootScope.$broadcast("progress",event)
            });
        }}).then(function (data, status, headers) {

            try {

                var data = new Blob([data.data]);
                FileSaver.saveAs(data, fileName);
                $rootScope.$broadcast("progressClose");
                success("FILE_LOADED");
            }catch(e){
                console.error(e);
            }

        }),error(function (data) {
            $rootScope.$broadcast("progressClose");

            error(data)
        },function notify(evt) {
            console.log('notified',evt);
        });

    }

    I3HelperSrv.prototype.getInstallationTempKey=function(id,success, error){

        $http.get($rootScope.config.API_URI+'/installationgroup/temp_token/'+id).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available
            if (response && response.data && response.data.success){
                success(response.data);
            }
            else if (!response.data.success && response.data.error){
                success(response.data)
            }
            else{

                error(response);
            }



        },function(data) {
            error(data)

        });
    };
    I3HelperSrv.prototype.getTplByName=function(tpl,success,error){
        $http.get( $rootScope.config.API_URI + '/report/getReportTpl/'+tpl).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success!==false){

                success(response.data);
            }
            else{

                error(data);
            }



        },function(data) {
            error(data)

        });
    };

    return  new I3HelperSrv();
});