/**
 * Created by Kais CHAIBI on 29/11/2017.
 */

angular.module("app.services").factory('plateformHelperSrv', function ($http, $rootScope, $window, AbstractApiSrv, PLATFORM_SERVICES, Notification,$injector) {

    function PlateformHelperSrv() {

    }

    PlateformHelperSrv.prototype.getPlatform = function () {
        return this.plateForm;
    }
    PlateformHelperSrv.prototype.setPlateFormAndLogin = function (plateform, success, error) {
        this.plateForm = plateform;
        $rootScope.plateform=undefined
        this.login(success, error);
    }
    PlateformHelperSrv.prototype.login = function (success, error) {
        var self = this;

        if (this.plateForm && this.plateForm.url) {

            $http.post(this.plateForm.url + '/' + "login" , {
                login: this.plateForm.username,
                password: this.plateForm.password
            }).then(function (response) {
                // this callback will be called asynchronously
                // when the response is available
                if (response && response.data && response.data.success) {

                    try {



                        PLATFORM_SERVICES.forEach(function (val) {
                           var service =  $injector.get(val.name);

                            service .setApiUri(self.plateForm.url)
                        })
                    }catch(e){
                        console.warn(e);
                    }
                    $window.sessionStorage.plateformToken = response.data.result.token;


                    self.plateformSession = response.data.result;
                     $rootScope. plateform = self.plateForm;
                    $window.sessionStorage.plateformSession = JSON.stringify(response.data.result);
                    $window.sessionStorage.plateform = JSON.stringify(self.plateForm);
                    success(response.data);
                }
                else if (!response.data.success && response.data.error) {

                    success(response.data)
                }
                else {

                    error(response);
                }

            },function(err){

                error(err);
            })
        } else {
            error("INVALID_PLATEFORM")
        }
    }
    PlateformHelperSrv.prototype.testLogin = function (plateform, success, error) {
        if (plateform && plateform.url) {

            $http.post(plateform.url + '/' + "login", {
                login: plateform.username,
                password: plateform.password
            }).then(function (response) {
                // this callback will be called asynchronously
                // when the response is available
                if (response && response.data && response.data.success) {
                    success(response.data);


                }
                else if (!response.data.success && response.data.error) {

                    success(response.data)
                }
                else {

                    error(response);
                }

            })
        } else {
            error("INVALID_PLATEFORM")
        }
    }

    PlateformHelperSrv.prototype.setPlateForm = function (plateform, plateformSession) {
        this.plateForm = plateform;
        this.plateformSession = plateformSession;
        $rootScope. plateform = plateform;

        if (plateform && plateform.url) {

            PLATFORM_SERVICES.forEach(function (val) {


                $injector.get(val.name).setApiUri(plateform.url)
            })
        }

    }


    return new PlateformHelperSrv();
});