angular
    .module('app')
    .controller('interventionCtrl', function ($scope, $translate, I3InterventionSrv,I3UserSrv,I3ClaimSrv,Notification, messageBox, constantSrv,iaxeedCompanySrv) {
        $scope.interventions = [];
        $scope.users = [];
        $scope.claims = [];
        //$scope.search={};
        //$scope.itemsByPage = 50;
        $scope.predefinedClaimStatus = constantSrv.getPredefinedStatus();
        $scope.states = constantSrv.getInterventionStatus();
        $scope.interventionStatus = constantSrv.getInterventionStatus();
        $scope.interventionStatus.push('NotClosed');
        var now = moment().clone();
        $scope.dayRes = now;

        $scope.pickerDay = {
            format : "DD/MM/YYYY",
            position: "top left",
            init: null,
            autoTrans : false,
            enableTime : false,
            enableDate : true,
            enableMinutes : false,
            defaultView : "DATE",
            maxDate: moment("2020/05/22", "YYYY/MM/DD"),
            minDate: now,
            getDate : function (date) {
                $scope.dayRes = date;
                $scope.installationRequest.preferred_dt = date;
            }
        };
        $scope.open2 = function() {
            $scope.popup2.opened = true;
        };
        $scope.popup2 = {
            opened: false
        };
        $scope.opened = {};
        $scope.open = function($event, elementOpened) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[elementOpened] = !$scope.opened[elementOpened];
        };

        /**
         * Removing intervention
         * @param intervention
         */
        $scope.remove = function (intervention) {

            messageBox.confirm("DELETE_INTERVENTION_TITLE","DELETE_INTERVENTION_MESSAGE").then(function(res){

                if(res){
                    I3InterventionSrv.delete(intervention._id,function(res){
                        if(res && res.success){
                            Notification.success($translate.instant("INTERVENTION_DELETED"));
                            if($scope.stCtrl){
                                $scope.stCtrl.pipe();
                            }
                        }else{
                            Notification.warn($translate.instant("INTERVENTION_DELETE_ERROR"));
                        }

                    },function(err){
                        Notification.error($translate.instant("INTERVENTION_DELETE_ERROR"));
                    })

                }

            },function(reject){
                //Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
            })
        };

        /**
         * Loading intervention list
         */
        $scope.loadInterventions = function (tableState,ctrl) {
            if (!$scope.stCtrl && ctrl) {
                $scope.stCtrl = ctrl;
            }

            if (!tableState && $scope.stCtrl) {
                $scope.stCtrl.pipe();
                return;
            }
            var start = 0;
            var number = $scope.itemsByPage;

            if(tableState.pagination  && tableState.pagination.number){
                number=tableState.pagination.number;
            }
            /*if(tableState.pagination  && tableState.pagination.start){
                start=Math.ceil((tableState.pagination.start+1)/number);
            }*/
            $scope.numberPages.interventionItemByPages = number
            if($scope.numberPages.interventionNumberPages){
                start = $scope.numberPages.interventionNumberPages
            }else{
                start = tableState.pagination.start / number + 1 || 1;
            }
            var options = {
                populate : [
                    "_assigned_to",
                    "_claim",
                    "_created_by"
                ],
                sortBy: {creation_dt: -1}
            };
            var query = {};


            if ($scope.search && $scope.search._claim) {
                query._claim= $scope.search._claim._id;
            }
            if ($scope.search && $scope.search.claims) {
                query._claim= {$in:$scope.search.claims.claims};
            }
            if ($scope.search && $scope.search._created_by) {
                query._created_by= $scope.search._created_by._id;
            }
            if ($scope.search && $scope.search.interventionStatus) {
                if($scope.search.interventionStatus === "NotClosed"){
                    query.status= {$nin:constantSrv.getPredefinedStatus()};
                }else {
                    query.status = $scope.search.interventionStatus;
                }
            }

            if ($scope.search && $scope.search._assigned_to) {
                query._assigned_to= $scope.search._assigned_to._id;
            }

            I3InterventionSrv.query({query: query, options: options}, number, start, function (res) {
                if(res.success){

                    $scope.interventions = res.result;

                    $scope.interventionsCount = res.total_count;
                    tableState.pagination.numberOfPages = Math.ceil(res.total_count / number);
                    tableState.pagination.totalItemCount = res.total_count;
                    tableState.pagination. start=start*number-1>=0? start*number-1: 0;
                    $scope.tableState = tableState;
                }
            }, function (err) {
                console.log(err);
            })
        };

        /**
         * Loading users list
         */
        I3UserSrv.query({}, 10000, 1, function (res) {
            if(res.success){
                $scope.users = res.result;
            }
        }, function (err) {
            console.log(err);
        });

        I3ClaimSrv.query({}, 10000, 1, function (res) {
            if(res.success){
                $scope.claims = res.result;
            }
        }, function (err) {
            console.log(err);
        });
        I3ClaimSrv.query({}, 10000, 1, function (res) {
            if(res.success){
                $scope.claims = res.result;
            }
        }, function (err) {
            console.log(err);
        });
        //client_name:{$first:'$client_name'}
        $scope.searchClientId=function($select){
            var query = [
                {$match:{client_name:{'$regex' :$select.search, '$options' : 'i'}}},
                {$group: {
                    _id: '$client_id',
                    claims: {$push: '$_id'},
                    client_name:{$first:'$client_name'}
                }}];

            I3ClaimSrv.agg({aggregate:query},1000,0,function(res){
                $scope.clients = res.result;
            },function(err){

            })
        };


        $scope.updateAssignTo=function(user,$data){
            if($scope.intervention._assigned_to){
                if($scope.intervention._assigned_to.length > 0 && $scope.intervention._assigned_to[0]._id){
                    interv._assigned_to = [];
                    angular.forEach(intervention._assigned_to, function (user) {
                        interv._assigned_to.push(user._id);
                    });
                }else{
                    delete interv._assigned_to;
                }
            }
            I3InterventionSrv.updateById(user._id,{_assigned_to:$data}, function(res){
                if(res.success){
                    Notification.success($translate.instant("INTERVENTION_UPDATED"));
                }
            },function(err){
            })
        };

       /* $scope.updateInterventionStatus=function(intervention,$data){
            I3InterventionSrv.updateById(intervention._id,{status:$data}, function(res){
                if(res.success){
                    Notification.success($translate.instant("INTERVENTION_UPDATED"));
                }
            },function(err){
            })
        }*/
        $scope. updateInterventionProp=function(prop,intervention,$data){
            var updateData={};

            if(prop==="status"){
                if($data==="Planned" && !updateData.planned_begin_dt){
                    updateData.planned_begin_dt=new Date();
                    intervention.planned_begin_dt=new Date();
                }else if($data==="InProgress" && !updateData.begin_dt){
                    updateData.begin_dt=new Date();
                    intervention.begin_dt=new Date();
                }else if ($data==="Done" && !updateData.end_dt) {
                    updateData.end_dt=new Date();
                    intervention.end_dt=new Date();
                    updateData._assigned_to = [];
                    intervention._assigned_to = [];
                    angular.forEach($scope.users,function (user) {
                        if(user._role === 'SUPPORT'){

                            updateData._assigned_to.push(user._id)
                            intervention._assigned_to.push(user._id)
                        }
                    })
                }

            }
            if(prop==="planned_begin_dt" && intervention.status==="New"){
                updateData.status="Planned";
                intervention.status="Planned"
            }
            if(prop==="begin_dt" && intervention.status!=="InProgress" && intervention.status!=="Done"){
                updateData.status="InProgress";
                intervention.status="InProgress"
            }
            if(prop==="end_dt" && intervention.status!=="Done"){
                updateData.status="Done";
                intervention.status="Done";
            }
            updateData[prop]=$data;
            if(prop==="asset"){
                updateData={asset:$data._id,asset_name:$data.name};
                intervention.asset_name=$data.name;

            }
            I3InterventionSrv.updateById(intervention._id,updateData, function(res){

                if(res && res.success){
                    $scope.loadInterventions();
                    Notification.success($translate.instant("INTERVENTION_UPDATED"))
                }else{
                    Notification.success($translate.instant("INTERVENTION_UPDATE_ERROR"))
                }

            },function(err){

                Notification.success($translate.instant("INTERVENTION_UPDATE_ERROR"));
            })
        };

        $scope.removeStatus = function (predefined) {
            var index = $scope.predefinedClaimStatus.indexOf(predefined);
            if (index > -1) {
                $scope.predefinedClaimStatus.splice(index, 1)
                $scope.loadInterventions();
            }
        };

        if(!$scope.search.interventionStatus && $scope.search.interventionStatus !== null){
            $scope.search.interventionStatus="NotClosed"
        }


        $scope.onPageChange = function (val) {
            $scope.numberPages.interventionNumberPages = val
            $scope.numberPages.interventionItemByPages = $scope.itemsByPage
        }

        if(!$scope.numberPages.interventionItemByPages || $scope.numberPages.interventionItemByPages === undefined){
            $scope.itemsByPage = 50
        }else{
            $scope.itemsByPage = $scope.numberPages.interventionItemByPages
        }
    });