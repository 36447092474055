angular.module('app').controller('adminUserEditCtrl', function ($scope,$rootScope, $state, $stateParams, $translate, I3UserSrv, I3RoleSrv,ngDialog, Notification) {

    $scope.user = {};
    $scope.passwordEdit = '';

    I3UserSrv.getById($stateParams.userId, function (response) {
        $scope.user = response.result;
        $scope.password = $scope.user.password;
        $scope.getRole()
    }, function (err) {
        console.log(err);
    });

    /**
     * Getting roles
     */
    I3RoleSrv.query({}, 10000, 1, function (response) {
        if(response.success){
            $scope.roles = response.result;
        }
    });
    /**
     * Getting role
     */
    $scope.getRole = function () {
        I3RoleSrv.getById($scope.user._role, function (response) {
            if (response.success) {
                $scope.user._role = response.result;
            }
        });
    };


    /**
     * Save user
     */
    $scope.save = function () {
        if(!$scope.passwordEdit){
            $scope.user.password = $scope.password
        }else{
            $scope.user.password = $scope.passwordEdit
        }
        I3UserSrv.updateById($scope.user._id, $scope.user, function (response) {
            if(response.success){
                Notification.success({
                    title : "User updated",
                    message : "User " + $scope.user.login + " was updated successfully!"
                });
                $state.go('app.admin.users');
                $scope.user  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.user.login +"!"
                });
                $scope.mode = "VIEW";
                $scope.user  = {};
            }
        }, function (err) {
            var msg = "";
            if( err.error.errorString){
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message : msg
            });
        })
    };


    /**
     * Removing user
     * @param user
     */
    $scope.remove = function (user) {
        $scope.user = user;

        ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/user.delete.dlg.tpl.html",
            controller : "confirmDeleteUserDlgCtrl",
            scope: $scope
        })
    };

    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.user = {};
        $state.go('app.admin.users');
    }

});