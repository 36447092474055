angular.module('app.dialogs').controller('settingRoleDlgCtrl', function ($scope, $rootScope, ngDialog, I3RoleSrv, I3SettingSrv, Notification, $translate,i3HelperSrv,updateCfg,isRoleConfig,modelId,isNewRole) {

    try {
        $scope.res = [];
        var keys = '';


        if (isRoleConfig && isNewRole) {
            var roles = [];

            $scope.isNewRole = true;
            $scope.roleConfig = {role: null, create: false, update: false};

            if ($scope.setting.rolesConfig && $scope.setting.rolesConfig.length > 0) {
                roles = $scope.setting.rolesConfig.map(function (e) {
                    return e.role;
                });
            }


            var query={};
            if (roles && roles.length > 0) {
                query = {_id: {$nin: roles}};
            }
            I3RoleSrv.query({query: query}, 10000, 0, function (data) {
                if (data.success) {
                    $scope.roles = data.result;
                } else {
                    console.error(data.error)
                }

            }, function (data) {
                console.log(data)
            });
        }

        i3HelperSrv.getFieldsByCollectionName(modelId.toUpperCase(), function (res) {
            if (res) {

                $scope.fields = [];
                var fieldKeys = Object.keys(res);

                var res = "";
                if (typeof updateCfg != 'boolean' && updateCfg != undefined && updateCfg.length > 0) {

                    res = updateCfg.split(" ");
                }
                angular.forEach(fieldKeys, function (e) {
                    console.log(e, res.indexOf(e) >= 0)
                    if (res.indexOf(e) >= 0) {
                        $scope.fields.push({code: e, value: true});
                    } else {
                        $scope.fields.push({code: e, value: false});
                    }


                })
                if (updateCfg === true) {
                    $scope.all = true
                } else if (updateCfg === false) {
                    $scope.nothing = true
                }
            } else {
                console.log('error')
            }
        }, function (err) {
            console.log(err)
        });

        $scope.getSelectedFields = function () {
            keys = '';
            angular.forEach($scope.fields, function (e, index) {

                if (e.value == true) {
                    if (keys === '') {
                        keys += e.code;
                    } else {
                        keys += " " + e.code;
                    }
                }

            })

            console.log('$scope.fields', $scope.fields)
        };


        $scope.confirm = function () {

            if (isNewRole) {
                var roleConfig = angular.copy($scope.roleConfig);
                roleConfig.role = $scope.roleConfig.role._id;

                if ($scope.all) {
                    roleConfig.update = true;
                    $scope.setting.rolesConfig.push(roleConfig);
                    $scope.closeThisDialog();
                } else if (keys && keys !== '') {

                    roleConfig.update = keys;
                    $scope.setting.rolesConfig.push(roleConfig);
                    $scope.closeThisDialog();
                } else {
                    roleConfig.update = false;
                    $scope.setting.rolesConfig.push(roleConfig);

                    $scope.closeThisDialog();
                }
            } else {


                if ($scope.all) {
                    $scope.closeThisDialog(true);
                } else if (keys && keys !== '') {
                    $scope.closeThisDialog(keys);
                } else {
                    $scope.closeThisDialog(false);
                }
            }

        }
    }catch(e){
        console.error(e);
    }

});