angular.module('app.dialogs').controller('confirmDeleteProductDlgCtrl', function ($scope, $rootScope, ngDialog, I3ProductSrv, Notification) {

    $scope.confirm = function () {
        I3ProductSrv.delete($scope.product._id, function (response) {

            if(response.success){
                //$scope.products.splice($scope.products.indexOf($scope.product), 1);
                $scope.loadProducts()
                Notification.success({
                    title: "Product deleted",
                    message : "Product was deleted successfully!"
                });
                $scope.product = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : "Une erreur est survenue lors de la suppression !"
                });
                $scope.product = {};
            }
        }, function (err) {
            console.log(err);

            Notification.error({
                title: "Erreur",
                message : "Une erreur est survenue lors de la suppression  !"
            });

            $scope.product = {};
        });

        $scope.product = {};
        $scope.closeThisDialog();
    }

});