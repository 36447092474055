angular.module('app').controller('installationRequestDetailsCtrl', function ($rootScope, $scope,i3HelperSrv, I3InstallationGroupSrv,I3UserSrv,I3DocumentSrv, I3PlateformeSrv,iaxeedSocketSrv, plateformHelperSrv,iaxeedCompanySrv,I3ProductSrv,ngDialog,Notification,$state ,$stateParams,messageBox,I3InstallationSrv ,constantSrv,$translate,iaxeedAssetSrv,NGIFleetAssetSrv,NGIFleetHelperSrv,reportingSrv) {

    $scope.itemsByPage = 50;
    $scope.search = {};
    $scope.Object=Object;
    $scope.statusList=constantSrv.getInstallationGroupStatus();
    $scope.statusInstallationsList=constantSrv.getInstallationStatus();
    $scope.emailFollow = "";
    $scope.showButton = true
    var end_dt = ''
    $scope.showPrint = false;
    $scope.export=function(fileType){
        var info= {
            date:($scope.installationRequest && $scope.installationRequest.begin_dt ? $scope.installationRequest.begin_dt:""),
            company:($scope.installationRequest && $scope.installationRequest.client_name ? $scope.installationRequest.client_name:""),
            telFax:($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.fax ?$scope.installationRequest.contact.fax:""),
            mail:($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.email ? $scope.installationRequest.contact.email:""),
            contact:($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.name ? $scope.installationRequest.contact.name:""),
            tel:($scope.installationRequest && $scope.installationRequest.contact && $scope.installationRequest.contact.phone ? $scope.installationRequest.contact.phone:""),
            adressInstal:"",
            data:true
        }
        data= [];
        $scope.listInstallations.forEach(function(val){
            data.push({
                "marque": "",
                "model": "",
                "imm": val.asset_name,
                "date": "",
                "type":val.device_type,
                "sn": "",
                "sim": ""})
        })
        var ds= {name:"pvMultiinstall",data:{info:info,data:data}}
        if(fileType=="XLSX"){
            reportingSrv.generateExcel("templatePVinstallMulti.mrt",ds,function(){

            },function(){
                Notification.error("L'export a échoué ");
            })
        }else if(fileType=="DOCX"){
            reportingSrv.generateWord("templatePVinstallMulti.mrt",ds,function(){

            },function(){
                Notification.error("L'export a échoué ");
            })
        }else if(fileType=="preview"){
            console.log("preview")
            reportingSrv.preview("templatePVinstallMulti.mrt",ds,function(){

            },function(){
                Notification.error("La prévisualisation  a échoué ");
            })
        }
        else{
            reportingSrv.generatePdf("templatePVinstallMulti.mrt",ds,function(){

            },function(){
                Notification.error("L'export a échoué ");
            })
        }

    }
    $scope.loadInstallations = function(){
        if($stateParams.id && $stateParams.id.length>0){

            I3InstallationGroupSrv.query({query:{_id:$stateParams.id},options:{
                populate:[{path:"_created_by"},{path:"installations._product"},{path : 'followers.user'}, {path:"docs"},{path:"_plateforme"},{path:"_installationIds",populate:[{path:"_product", model:"product"}, {path:"_assigned_to", model:"user"}, {path:"docs"}]}
                ]}
            },1,0,function(res) {


                if (res && res.success &&  res.result.length>0){
                    $scope.installationRequest = res.result[0];
                    end_dt = $scope.installationRequest.end_dt
                    $scope.plateforme = $scope.installationRequest._plateforme;
                    $scope.selectPlateform($scope.plateforme)
                    $scope.installationRequest.installCount=0;
                    if($scope.installationRequest && $scope.installationRequest.installations && $scope.installationRequest.installations.length>0){
                        $scope.installationRequest.installations.map(function(e){
                            $scope.installationRequest.installCount+= e.asset_count;
                        })
                    }


                } else{
                    $state.go("app.home.installRequest");
                    Notification.error("FIND_BY_ID_ERROR");
                }

            },function(err){
                Notification.error("FIND_BY_ID_ERROR")
            })
        }else{

            $state.go("app.home.installRequest");
        }
    };

    $scope.loadInstallations();

    /**
     * select and authenticate plateforme
     * @param platform
     */
    $scope.selectPlateform=function(platform){

        $scope.assets=[];
        if(platform.type=="NGI_FLEET"){
            NGIFleetHelperSrv.setPlateFormAndLogin(platform,function(res){

                if(res.result){

                }else{
                    Notification.error("Impossile de se connecter à la plateforme "+ platform.name)
                }
                console.log("NGIFleetHelperSrv" , res);
            },function(err){
                Notification.error("Une erreur s'est produite lor de la connexrion à la plateforme  "+ platform.name)
            })
        }else{
            plateformHelperSrv.setPlateFormAndLogin(platform,function(res){

                if(res && res.success){

                }else{
                    Notification.error("Impossile de se connecter à la plateforme "+ platform.name)
                }

            },function(err){
                console.log(err);
                Notification.error("Une erreur s'est produite lor de la connexrion à la plateforme  "+ platform.name)
            })
        }

    };
    $scope.spreadInstallations=function(){

        if($scope.installationRequest._installationIds && $scope.installationRequest._installationIds.length>0){

            console.log("installation already spreaded ")
        }else{
            if($scope.installationRequest && $scope.installationRequest.installations.length>0){

                var installations = [];
                $scope.installationRequest.installations.forEach(function(e){

                    installations.push({
                        _installation_group:$scope.installationRequest._id,
                        _product: e._product._id,
                        product_options: e.product_options,
                        sim_included: $scope.installationRequest.sim_included

                    })

                });
                console.log(installations);
                installations.forEach(function(val){

                    I3InstallationSrv.add(val,function(res){
                        if(res && res.success){
                            $scope.installationRequest._installationIds.push(res.result);
                            $scope.loadInstallations();
                            $scope.showSpread = false
                        }else{
                            Notification.error("Create installation error ");
                        }

                    },function(err){

                    })
                })

            }



        }

    };


    /**
     * Following the current claim
     */
    $scope.follow = function () {
        if($scope.emailFollow && $scope.emailFollow !== "") {

            var follower = {};
            if (!$scope.installationRequest.followers) {
                $scope.installationRequest.followers = [];
            }

            follower.email = $scope.emailFollow;


            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};

            var exist = false;

            angular.forEach($scope.installationRequest.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });

            if (!exist) {
                I3UserSrv.query({query: query, options: options}, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }

                    $scope.installationRequest.followers.push(follower);

                    delete $scope.installationRequest.__v;

                    I3InstallationGroupSrv.updateById($scope.installationRequest._id, $scope.installationRequest, function (response) {
                        if (response.success) {
                            Notification.success({
                                title: "Following claim",
                                message: "The email address" + $scope.emailFollow + " is now following Installation request"
                            });

                            $scope.emailFollow = "";
                        } else {
                            Notification.error({
                                title: "Erreur",
                                message: $translate.instant("ERROR_OCCURED_UPDATE") + "!"
                            });
                            $scope.installationRequest = {};
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: "Erreur",
                            message: msg
                        });
                    })
                });
            } else {
                Notification.warning({
                    title: "Already follower",
                    message: "This email is already subscribed as a follower here"
                });
                $scope.emailFollow = "";
            }

        }

    };
    /**
     * Remove follower
     */
    $scope.removeFollower = function (follower) {
        if($scope.installationRequest.followers.indexOf(follower) >= 0){
            $scope.installationRequest.followers.splice($scope.installationRequest.followers.indexOf(follower), 1);
            delete $scope.installationRequest.__v;

            I3InstallationGroupSrv.updateById($scope.installationRequest._id, $scope.installationRequest, function (response) {
                if(response.success){
                    Notification.success({
                        title : "Removing installation request follower",
                        message : "The follower of installation request was removed successfully"
                    });
                }else{
                    Notification.error({
                        title: "Erreur",
                        message : $translate.instant("ERROR_OCCURED_UPDATE") +"!"
                    });
                    $scope.installationRequest  = {};
                }
            }, function (err) {
                var msg = "";
                if( err.error.errorString){
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: "Erreur",
                    message : msg
                });
            })
        }
    };

    $scope.editInstallation=function(installation,index){

        console.log(installation,index);
        try {
            ngDialog.open({
                template: 'app/commons/dialogs/installation/installation.add.dlg.tpl.html',
                controller: 'installationAddDlgCtrl',
                scope: $scope,
                resolve: {
                    installations: function () {
                        return angular.copy(installation)
                    },
                    index: function () {
                        return index
                    },plateforme:function(){return $scope.installationRequest._plateforme},
                    installationRequest:function(){return $scope.installationRequest}
                }
            })
        }catch(e){
            console.error(e);
        }
    };
    $scope.addInstallation=function(){

        ngDialog.open({
            template: 'app/commons/dialogs/installation/installation.add.dlg.tpl.html',
            controller: 'installationAddDlgCtrl',
            scope: $scope,
            resolve:{
                installations:function(){return null},
                index:function(){return null},
                plateforme:function(){return $scope.installationRequest._plateforme},
                installationRequest:function(){return $scope.installationRequest}
            }
        })
    };


    $scope.showInstallationDetails=function(id){
        $state.go('app.home.installDetails',{id:id});
    }

    $scope.loadAssets=function($select){
        if($rootScope.plateform && $rootScope.plateform.type=="NGI_FLEET"){
            NGIFleetAssetSrv.getAssetByClient($scope.installationRequest.client_id,$select.search,500,0,function(res){
                $scope.assets=res.result;
            },function(err){
                console.log("err" , err)
            })
        }else{
            iaxeedAssetSrv.query({query:{name:{'$regex' :$select.search, '$options' : 'i'},"_company_owner":$scope.installationRequest.client_id}},500,0,function(res){
                $scope.assets=res.result;
            },function(err){

            })
        }
    };

    /*  $scope.loadAssets=function(){
          if($rootScope.plateform && $rootScope.plateform.type=="NGI_FLEET"){
              NGIFleetAssetSrv.getAssetByClient($scope.installationRequest.client_id,null,1000,0,function(res){
                  $scope.assets=res.result;
              },function(err){
                  console.log(err);
              })
          }else{
              iaxeedAssetSrv.query({query:{"_company_owner":$scope.installationRequest.client_id}},1000,0,function(res){
                  $scope.assets=res.result;
              },function(err){
              })
          }
          console.log('assets',$scope.assets)

      };*/
    $scope. updateInstallationRequestProp=function(prop,installation,$data){
        var updateData={};
        var compare = false;
        updateData[prop]=$data;
        for(var i= 0; i<$scope.installationRequest._installationIds.length; i++){
            if($scope.installationRequest._installationIds[i].status !== 'Done'){
                compare = false;
                break
            }else{
                compare = true;
            }
        }
        if(compare && $data == 'Done' || $scope.installationRequest._installationIds == 0){
            I3InstallationGroupSrv.updateById($scope.installationRequest._id,updateData,function(res){
                if(res && res.success){
                    Notification.success($translate.instant("INSTALLATION_GROUP_UPDATED"));
                }
            },function(err){
            })
        }else if($data != 'Done') {
            I3InstallationGroupSrv.updateById($scope.installationRequest._id,updateData,function(res){
                if(res && res.success){
                    Notification.success($translate.instant("INSTALLATION_GROUP_UPDATED"));
                }
            },function(err){
            })
        }else{
            Notification.error('Il y a des installations ne sont pas encore terminées')
            prop.$cancel();
        }

    };

    $scope. updateInstallationProp=function(prop,installation,$data){
        var updateData={};
        if(prop==="status"){
            if($data==="Planned" && !updateData.planned_begin_dt){
                updateData.planned_begin_dt=new Date();
                installation.planned_begin_dt=new Date();
            }else if($data==="InProgress" && !updateData.begin_dt){
                updateData.begin_dt=new Date();
                installation.begin_dt=new Date();
            }else if ($data==="Done" && !updateData.end_dt) {
                updateData.end_dt=new Date();
                installation.end_dt=new Date();
            }

        }
        if(prop==="planned_begin_dt" && installation.status==="New"){
            updateData.status="Planned";
            installation.status="Planned"
        }
        if(prop==="begin_dt" && installation.status!=="InProgress" && installation.status!=="Done"){
            updateData.status="InProgress";
            installation.status="InProgress"
        }
        if(prop==="end_dt" && installation.status!=="Done"){
            updateData.status="Done";
            installation.status="Done";
        }
        updateData[prop]=$data;
        if(prop==="asset"){
            if($data && $data._id && $data.name){
                updateData={asset:$data._id,asset_name:$data.name};
                installation.asset_name=$data.name;
            }
        }
        I3InstallationSrv.updateById(installation._id,updateData, function(res){
            if(res && res.success){
                $scope.loadInstallations();
            }
        },function(err){

        })
    };

    $scope.remove = function ($index) {

        messageBox.confirm("DELETE_INSTALLATION_TITLE","DELETE_INSTALLATION_MESSAGE").then(function(res){

            if(res){
                I3InstallationSrv.delete($scope.installationRequest._installationIds[$index]._id,function(res){
                    if(res && res.success){
                        Notification.success($translate.instant("INSTALLATION_DELETED"));
                        $scope.installationRequest._installationIds.splice($index,1);
                        $scope.addInstallations();

                    }else{
                        Notification.warn($translate.instant("INSTALLATION_DELETE_ERROR"));
                    }

                },function(err){
                    Notification.error($translate.instant("INSTALLATION_DELETE_ERROR"));
                })

            }

        },function(reject){
            //Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
        })
    };
    function saveInstallations(installIndex, callback) {
        if(installIndex<$scope.installationRequest._installationIds.length) {
            I3InstallationSrv.delete($scope.installationRequest._installationIds[installIndex], function (res) {
                if (res && res.success) {
                    installIndex = installIndex+1;
                    saveInstallations(installIndex, callback)
                } else {
                    callback('ERROR')
                    Notification.error("Create installation error ");
                }

            }, function (err) {
                callback('ERROR')
            })
        }else{
            callback()
        }
    }

    $scope.addInstallations = function () {

        saveInstallations(0,function (err, res) {
            try{
                $scope.installations = [];
                var installations = {};
                for (var i = 0; i < $scope.installationRequest._installationIds.length; i++) {
                    if ($scope.installationRequest._installationIds[i]._product) {
                        if (!installations[$scope.installationRequest._installationIds[i]._product._id]) {
                            installations[$scope.installationRequest._installationIds[i]._product._id] = {
                                _product: $scope.installationRequest._installationIds[i]._product,
                                product_options: $scope.installationRequest._installationIds[i].product_options,
                                asset_count: 0
                            }
                        }
                        installations[$scope.installationRequest._installationIds[i]._product._id].asset_count += 1;
                    }
                }
                $scope.installationRequest.installations = (Object.values(installations))
                I3InstallationGroupSrv.updateById($scope.installationRequest._id,{installations: $scope.installationRequest.installations},function(res){
                    if(res && res.success){
                        $scope.cancel()
                    }else{
                        Notification.error($translate.instant("INSTALLATION_GROUP_ADD_EEROR"));
                        //TODO handle error
                    }},function(err){


                })
            }
            catch (e){
                console.log('e',e)
            }
            Notification.success($translate.instant("SAVE_INSTALLATION_SUCCESS"));
        })

    }



    $scope.opened = {};

    $scope.open = function($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened[elementOpened] = !$scope.opened[elementOpened];
    };

    $scope.uploadFiles = function(files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;

        if($scope.installationRequest && (!$scope.installationRequest.docs || !Array.isArray($scope.installationRequest.docs)))
            $scope.installationRequest.docs=[];
        i3HelperSrv.uploadFiles(files, function (res) {
            angular.forEach(res.result , function (file) {
                $scope.installationRequest.docs.push(file)
            });
            I3InstallationGroupSrv.updateById($stateParams.id,{docs:$scope.installationRequest.docs},function(res){
                if(res && res.success){
                    Notification.success($translate.instant("INSTALLATION_GROUP_UPDATED"));
                }else{
                    Notification.error($translate.instant("INSTALLATION_GROUP_UPDATED_ERROR"));
                }},function(err){

            })

        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });

    };

    $scope.downloadFile=function(file){
        i3HelperSrv.downloadFile(file._id, file.original_name,function success(res){

        },function error(err){

        },function progress(evt){
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };


    $scope.assetRT = function (id) {
        $scope.asset = {};

        var query = {
            _id : id
        };
        var options = {};


        iaxeedAssetSrv.query({query: query, options:options}, 10000, 1, function (response) {
            if(response.success){
                $scope.asset = response.result[0];
            }
        }, function (err) {
            console.log(err);
        });

        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });

        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });

    }

    $scope.verify = function () {
        ngDialog.open({
            controller: "confirmClientDlgCtrl",
            templateUrl: "app/commons/dialogs/client/confirm.client.dlg.tpl.html",
            scope: $scope,
            width: '900px',
            resolve:{
                installation:function(){
                    return $scope.installationRequest;
                }
            }
        });
    }

    $scope.changeProductOptions = function (installation) {
        var dialog = ngDialog.open({
            controller: "pvDlgCtrl",
            templateUrl: "app/commons/dialogs/installation/pv.dlg.tpl.html",
            scope: $scope,
            width: '700px',
            resolve:{
                installation:function(){
                    return installation;
                }
            }
        });
        dialog.closePromise.then(function () {
           $scope.loadInstallations()
        });
    }

    $scope.generateTempToken =function(id){
        i3HelperSrv. getInstallationTempKey(id,function(res){

            if(res && res.success){
                var dialog = ngDialog.open({
                    controller: "publicLinkDlgCtrl",
                    templateUrl: "app/commons/dialogs/client/publiclink.dlg.tpl.html",
                    scope: $scope,
                    resolve:{
                        access:function(){
                            return res.result;
                        }
                    },
                    className: 'ngdialog-theme-default custom-width'
                });


            }else{
                console.log("error, ",res);
            }
        },function(err){
            console.log(err)
        })
    }
    $scope.listInstallations = [];
    $scope.listInstallation = function(installation, install, selectAll){
        if(selectAll === true){
            if(install === true){
                $scope.install = true
                $scope.listInstallations = installation
            }else{
                $scope.install = false
                $scope.listInstallations = []
            }
        }else{
            $scope.installAll = false
            if(install === true){
                $scope.listInstallations.push(installation)
            }else{
                $scope.listInstallations.splice($scope.listInstallations.indexOf(installation), 1);
            }
        }
    }

    $scope.printInstallationGroup = function (installation) {
        var printContents = document.getElementById(installation).innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload(true);
    }





});