angular.module('app').controller("openProjectTaskAddDlgCtrl", function ($scope,I3ProductSrv, Notification,openProjectSrv,$translate,I3ClaimSrv) {


    $scope.task = {};

    $scope.searchProject=function(){
        openProjectSrv.getProjects( function(res){

            if(res.count && res.count>0){

                $scope.projects=res._embedded.elements;
            }else{
                console.log("NO PROJECT FOUND");
            }

        },function(err){
            console.log(err);
        })
    }
    $scope.searchStatus=function(){
        openProjectSrv.getStatus( function(res){

            if(res.count && res.count>0){

                $scope.stauses=res._embedded.elements;
            }else{
                console.log("NO Status FOUND");
            }

        },function(err){
            console.log(err);
        })
    }

    $scope.selectProject=function(project){
        $scope.searchTicketTypes(project.id);
    }
    $scope.searchTicketTypes=function(projectId){
        openProjectSrv.getTypes(projectId,function(res){

            if(res.count && res.count>0){

                $scope.taskTypes=res._embedded.elements;
            }else{
                console.log("NO PROJECT FOUND");
            }

        },function(err){
            console.log(err);
        })
    }

    $scope.add=function(task){


        var task2=angular.copy(task)
        task2.project=task2.project.id;
        task2.type=task2.type.name;

     var workPackage =    {
            "subject":task.subject,
            "description": {
            "format": "textile",
                "raw":  task.description
        },
            "_links": {
            "type": {"href":"/api/v3/types/"+task.type.id},
            "project": {"href":"/api/v3/projects/"+task.project.id}


        }
        }
       openProjectSrv.createTask(workPackage,function(res){


            if(res && res.status){
                if(res.status==401){
                    // problème d'authentification
                    Notification.error($translate.instant("CREDENTIALS_OPEN_PROJECT_ERROR"));
                }else{


                    Notification.error($translate.instant("OPEN_PROJECT_ERROR"));
                }
            }else{
                Notification.success($translate.instant("TICKET_ADDED"));

                try {
                    I3ClaimSrv.updateById($scope.claim._id, {ticket: res.id}, function (updateRes) {
                        if (updateRes && updateRes.success) {
                           $scope. closeThisDialog(res)
                            Notification.success($translate.instant("OPEN_PROJECT_ERROR"));
                        } else {
                            Notification.error($translate.instant("OPEN_PROJECT_ERROR"));
                        }


                    }, function (err) {
                        Notification.error($translate.instant("OPEN_PROJECT_ERROR"));
                    })
                }catch(e){
                    console.log(e);
                }

            }
       },function(err){
           Notification.error($translate.instant("TICKET_ADD_ERROR"));
       })
    }

    $scope.searchProject();
    $scope.searchStatus();
});