angular.module('app.dialogs')
    .controller('realtimeDlgCtrl', function ($scope, $rootScope, $translate, $q,$window, $timeout, iaxeedSensorSrv, geoServiceProxy, iaxeedSocketSrv) {

        iaxeedSocketSrv.connect($scope.plateforme);
        iaxeedSocketSrv.on('server:connected', function () {
            iaxeedSocketSrv.emit('user:connected', {user: JSON.parse($window.sessionStorage.plateformSession).user});
        });


        var NGI = L.tileLayer.wms($rootScope.config.LBS_WMS, {
            maxZoom: 19,
            name: "NGI",
            attribution: 'Powered by iaxeed',
            icon: 'app/commons/directives/map/images/ngiMap.png'
        });

        var googleTerrain = L.tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
            maxZoom: 19,
            name: "GOOGLE_SAT",
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            icon: 'app/commons/directives/map/images/gSat.png'

        });

        var googleRoadmap = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 19,
            name: "GOOGLE_NOR",
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            icon: 'app/commons/directives/map/images/gNorm.png'

        });

        var googleHybrid = L.tileLayer('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
            maxZoom: 19,
            name: "GOOGLE_HYB",
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            icon: 'app/commons/directives/map/images/gHybr.png'
        });

        var osm = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            name: "OPEN_STREET",
            icon: 'app/commons/directives/map/images/osmMap.png'

        });

        var baseMaps = {
            "NGI": NGI,
            "Google Roadmap": googleRoadmap,
            "Google Terrain": googleTerrain,
            "Google Hybrid": googleHybrid,
            "Open Street Map": osm
        };

        $timeout(function () {
            $scope.map = L.map('map', {
                center: [36.843602, 10.206953],
                zoom: 14,
                layers : [NGI]
            });

            L.control.layers(baseMaps, null).addTo($scope.map);

            $scope.updateRealtime();
        }, 500);

        $scope.assetAddress = function (asset) {

            if ($scope.asset.rt && $scope.asset.rt.loc && $scope.asset.rt.loc.coordinates && $scope.asset.rt.loc.coordinates[1] && $scope.asset.rt.loc.coordinates[1] !== 0 && $scope.asset.rt.loc.coordinates[0] && $scope.asset.rt.loc.coordinates[0] !== 0) {
                var lng =$scope.asset.rt.loc.coordinates[0];
                var lat =$scope.asset.rt.loc.coordinates[1];
                var deferred = $q.defer();
                geoServiceProxy.findPointOfInterests(10, lng, lat, 10, "xx", "", "", "").then(function (res) {
                    if (res && res.BND && res.BND.GeocodingElements.GeocodingElement.length > 0) {
                        var postalAddress = res.BND.GeocodingElements.GeocodingElement[0].PostalAddress;

                        var address = "";

                        if(postalAddress){
                            if(postalAddress.StreetNumber){
                                address+= postalAddress.StreetNumber;
                            }
                            if(postalAddress.Street){
                                address+= " " +postalAddress.Street;
                            }
                            if(postalAddress.District){
                                address+=" " +postalAddress.District;
                            }
                            if(postalAddress.City){
                                address+=" " +postalAddress.City;
                            }
                            if(postalAddress.Country){
                                address+=", " +postalAddress.Country;
                            }
                        }


                    } else {
                        address = $translate.instant("NOT_AVAILABLE");
                    }

                    deferred.resolve(address);
                }, function (error) {
                });

                return deferred.promise;

            }
        };
        $scope.compareDate = function (date1, date2) {
            var dt1 = new Date(date1).getTime();
            var dt2 = new Date(date2).getTime();
            return dt1 !== dt2
        };

        $scope.updateRealtime = function () {

            if($scope.map.hasLayer($scope.marker)){
                $scope.map.removeLayer($scope.marker);
            }

            if(!$scope.asset)
                $scope.asset = {};

            $scope.asset.sensorData = [];
            var sensors = $scope.asset.sensors;
            if (sensors && sensors.length > 0) {
                angular.forEach(sensors, function (sensor) {
                    var sensorName = sensor.name;
                    var src = sensor.src.slice(sensor.src.lastIndexOf('.') + 1);
                    var srcDt = src+"_dt";

                    if (sensor.type && sensor.src && Number.isFinite($scope.asset.rt.io[src])) {
                        var realVal = $scope.asset.rt.io[src];
                        if (Number.isFinite(sensor.minSrc) && Number.isFinite(sensor.maxSrc) && Number.isFinite(sensor.min) && Number.isFinite(sensor.max)) {
                            realVal = iaxeedSensorSrv.getRealSensorValue($scope.asset.rt.io[src], sensor.minSrc, sensor.maxSrc, sensor.min, sensor.max);
                        }
                        if (Number.isFinite(realVal))
                            $scope.asset.sensorData.push({
                                name: sensorName,
                                type: sensor.type,
                                family: sensor.family,
                                date: $scope.asset.rt.io[srcDt],
                                visible : sensor.visible,

                                ON: sensor.ON,
                                OFF: sensor.OFF,
                                unit: sensor.unit,
                                value: parseFloat(realVal.toFixed(2))
                            });
                    }
                })
            }
            if ($scope.asset.rt) {
                if($scope.asset.rt && $scope.asset.rt.loc && $scope.asset.rt.loc.coordinates.length > 0){
                    var promise = $scope.assetAddress($scope.asset);
                    promise.then(function (address) {
                        $scope.asset.address = address;
                        var angle = 0;
                        var odometer = 0;
                        data = {
                            "name": "",
                            "family": "",
                            "type" : "",
                            "date": '',
                            "spd": '',
                            "ang": 0,
                            "odo": '',
                            'lng': 0,
                            'lat': 0,
                            'con': 0
                        };
                        data.name = $scope.asset.name;
                        if ($scope.asset.family)
                            data.family = $scope.asset.family;

                        if($scope.asset.type)
                            data.type = $scope.asset.type;

                        if (sensors.length > 0) {
                            angular.forEach(sensors, function (sensor) {

                                var src = sensor.src.slice(sensor.src.lastIndexOf('.') + 1);
                                if (sensor.type && sensor.type==="ODOMETER" && sensor.src && Number.isFinite($scope.asset.rt.io[src])) {
                                    var realVal = $scope.asset.rt.io[src];
                                    if(Number.isFinite(sensor.minSrc)  && Number.isFinite(sensor.maxSrc) && Number.isFinite(sensor.min) && Number.isFinite(sensor.max) ) {
                                        realVal = iaxeedSensorSrv.getRealSensorValue($scope.asset.rt.io[src], sensor.minSrc, sensor.maxSrc, sensor.min, sensor.max);
                                    }
                                    if(Number.isFinite(realVal) )
                                        odometer =parseFloat(realVal.toFixed(2));
                                }
                            })
                        }
                        if ($scope.asset.rt) {
                            if ($scope.asset.rt.gps_dt) {
                                data.gps_dt = moment($scope.asset.rt.gps_dt);
                            }
                            if(odometer){
                                data.odo = odometer;
                            } else if ($scope.asset.rt.odo) {
                                data.odo = Math.round($scope.asset.rt.odo).toFixed(1);
                            }

                            if ($scope.asset.rt.io) {
                                if ($scope.asset.rt.io.spd)
                                    data.spd = $scope.asset.rt.io.spd;
                                if ($scope.asset.rt.io.ang)
                                    data.ang = $scope.asset.rt.io.ang;
                                if ($scope.asset.rt.io.con)
                                    data.con = $scope.asset.rt.io.con;
                            }
                            if ($scope.asset.rt.loc) {
                                if ($scope.asset.rt.loc.coordinates && $scope.asset.rt.loc.coordinates[1] && $scope.asset.rt.loc.coordinates[1] !== 0 && $scope.asset.rt.loc.coordinates[0] && $scope.asset.rt.loc.coordinates[0] !== 0) {
                                    data.lng = $scope.asset.rt.loc.coordinates[0];
                                    data.lat = $scope.asset.rt.loc.coordinates[1];
                                }
                            }
                        }
                        if ($scope.asset.address){
                            data.address = $scope.asset.address;
                        }


                        if (data.type !== 'WAREHOUSE') {
                            angle = data.ang;
                        }


                        if (data.lng !== 0 && data.lat !== 0) {
                            content = '<h5 class="text-primary"><i class="fa fa-car"></i> ' + data.name + '</h5>';
                            if(angular.isDefined(data.gps_dt) && data.gps_dt !== ""){
                                content += '<i  class="fa fa-clock-o"></i><b> '+$translate.instant("DATE")+'</b> ' + moment(data.gps_dt ).format('DD/MM/YYYY HH:mm:ss')+ '<br/>';
                            }
                            if(angular.isDefined(data.spd) && data.spd !== ""){
                                content += '<i class="fa fa-tachometer"></i><b> '+$translate.instant("SPEED")+'</b> ' + data.spd + ' km/h<br/>';
                            }
                            if(angular.isDefined(data.odo) && data.odo !== ""){
                                content += '<i class="fa fa-calculator"></i><b> '+$translate.instant("ODOMETER")+'</b> ' + parseFloat(parseFloat(data.odo).toFixed(2).toString()) + ' km<br/>';
                            }
                            if(angular.isDefined(data.address) && data.address !== ""){
                                content += '<i class="fa fa-map-marker"></i><b> '+$translate.instant("ADDRESS")+'</b> ' + data.address;
                            }

                            if(data.type !== 'WAREHOUSE'){
                                var assetIcon = L.icon({
                                    html: data.name,
                                    iconSize: [30, 62],
                                    iconAnchor: [6, 15]
                                });
                            }else{
                                var assetIcon = L.icon({
                                    html: data.name,
                                    iconSize: [62, 62],
                                    iconAnchor: [31, 31]
                                });
                            }


                            $scope.marker = new L.Marker(new L.LatLng(data.lat,data.lng)).bindPopup(content);
                            var label = "<span class='tooltip-name'>"+data.name+"</span>";
                            if(Number.isFinite(data.spd))
                                label += "<br><span class='tooltip-speed'>"+ data.spd+ " Km/h</span>";

                            $scope.marker.bindTooltip(label, { direction:'right', permanent: true ,offset: [15, 0] ,className: 'tooltip-marker' });
                            $scope.marker.addTo($scope.map);
                            $scope.map.panTo($scope.marker.getLatLng());
                            $timeout(function () {
                                $scope.map.invalidateSize();
                            })
                        }
                    });
                }else{

                    var angle = 0;
                    var odometer = 0;
                    data = {
                        "name": "",
                        "family": "",
                        "type" : "",
                        "date": '',
                        "spd": '',
                        "ang": 0,
                        "odo": '',
                        'lng': 0,
                        'lat': 0,
                        'con': 0
                    };
                    data.name = $scope.asset.name;
                    if ($scope.asset.family)
                        data.family = $scope.asset.family;

                    if($scope.asset.type)
                        data.type = $scope.asset.type;

                    if (sensors.length > 0) {
                        angular.forEach(sensors, function (sensor) {

                            var src = sensor.src.slice(sensor.src.lastIndexOf('.') + 1);
                            if (sensor.type && sensor.type==="ODOMETER" && sensor.src && Number.isFinite($scope.asset.rt.io[src])) {
                                var realVal = $scope.asset.rt.io[src];
                                if(Number.isFinite(sensor.minSrc)  && Number.isFinite(sensor.maxSrc) && Number.isFinite(sensor.min) && Number.isFinite(sensor.max) ) {
                                    realVal = iaxeedSensorSrv.getRealSensorValue($scope.asset.rt.io[src], sensor.minSrc, sensor.maxSrc, sensor.min, sensor.max);
                                }
                                if(Number.isFinite(realVal) )
                                    odometer =parseFloat(realVal.toFixed(2));
                            }
                        })
                    }
                    if ($scope.asset.rt) {
                        if ($scope.asset.rt.gps_dt) {
                            data.gps_dt = moment($scope.asset.rt.gps_dt);
                        }
                        if(odometer){
                            data.odo = odometer;
                        } else if ($scope.asset.rt.odo) {
                            data.odo = Math.round($scope.asset.rt.odo).toFixed(1);
                        }

                        if ($scope.asset.rt.io) {
                            if ($scope.asset.rt.io.spd)
                                data.spd = $scope.asset.rt.io.spd;
                            if ($scope.asset.rt.io.ang)
                                data.ang = $scope.asset.rt.io.ang;
                            if ($scope.asset.rt.io.con)
                                data.con = $scope.asset.rt.io.con;
                        }
                        if ($scope.asset.rt.loc) {
                            if ($scope.asset.rt.loc.coordinates && $scope.asset.rt.loc.coordinates[1] && $scope.asset.rt.loc.coordinates[1] !== 0 && $scope.asset.rt.loc.coordinates[0] && $scope.asset.rt.loc.coordinates[0] !== 0) {
                                data.lng = $scope.asset.rt.loc.coordinates[0];
                                data.lat = $scope.asset.rt.loc.coordinates[1];
                            }
                        }
                    }
                    if ($scope.asset.address){
                        data.address = $scope.asset.address;
                    }


                    if (data.type !== 'WAREHOUSE') {
                        angle = data.ang;
                    }


                    if (data.lng !== 0 && data.lat !== 0) {
                        content = '<h5 class="text-primary"><i class="fa fa-car"></i> ' + data.name + '</h5>';
                        if(angular.isDefined(data.gps_dt) && data.gps_dt !== ""){
                            content += '<i  class="fa fa-clock-o"></i><b> '+$translate.instant("DATE")+'</b> ' + moment(data.gps_dt ).format('DD/MM/YYYY HH:mm:ss')+ '<br/>';
                        }
                        if(angular.isDefined(data.spd) && data.spd !== ""){
                            content += '<i class="fa fa-tachometer"></i><b> '+$translate.instant("SPEED")+'</b> ' + data.spd + ' km/h<br/>';
                        }
                        if(angular.isDefined(data.odo) && data.odo !== ""){
                            content += '<i class="fa fa-calculator"></i><b> '+$translate.instant("ODOMETER")+'</b> ' + parseFloat(parseFloat(data.odo).toFixed(2).toString()) + ' km<br/>';
                        }
                        if(angular.isDefined(data.address) && data.address !== ""){
                            content += '<i class="fa fa-map-marker"></i><b> '+$translate.instant("ADDRESS")+'</b> ' + data.address;
                        }

                        if(data.type !== 'WAREHOUSE'){
                            var assetIcon = L.icon({
                                html: data.name,
                                iconSize: [30, 62],
                                iconAnchor: [6, 15]
                            });
                        }else{
                            var assetIcon = L.icon({
                                html: data.name,
                                iconSize: [62, 62],
                                iconAnchor: [31, 31]
                            });
                        }


                        var marker = new L.Marker(new L.LatLng(data.lat,data.lng)).bindPopup(content);
                        var label = "<span class='tooltip-name'>"+data.name+"</span>";
                        if(Number.isFinite(data.spd))
                            label += "<br><span class='tooltip-speed'>"+ data.spd+ " Km/h</span>";

                        marker.bindTooltip(label, { direction:'right', permanent: true ,offset: [15, 0] ,className: 'tooltip-marker' });
                        marker.addTo($scope.map);
                        $scope.map.panTo(marker.getLatLng());
                        $timeout(function () {
                            $scope.map.invalidateSize();
                        })
                    }
                }


            }
        };


        /**
         * Realtime data
         */
        $rootScope.$on('newMsg', function (event, data) {
            if($scope.asset._id === data._id){
                $scope.asset.rt = data.rt;
                //get sensors and address

                var sensors = $scope.asset.sensors;
                $scope.asset.sensorData = [];
                if (sensors.length > 0) {
                    angular.forEach(sensors, function (sensor) {
                        var sensorName = sensor.name;
                        var src = sensor.src.slice(sensor.src.lastIndexOf('.') + 1);
                        var srcDt = src+"_dt";

                        if (sensor.type && sensor.src && Number.isFinite($scope.asset.rt.io[src])) {
                            var realVal = $scope.asset.rt.io[src];
                            if (Number.isFinite(sensor.minSrc) && Number.isFinite(sensor.maxSrc) && Number.isFinite(sensor.min) && Number.isFinite(sensor.max)) {
                                realVal = iaxeedSensorSrv.getRealSensorValue($scope.asset.rt.io[src], sensor.minSrc, sensor.maxSrc, sensor.min, sensor.max);
                            }
                            if (Number.isFinite(realVal))
                                $scope.asset.sensorData.push({
                                    name: sensorName,
                                    type: sensor.type,
                                    family: sensor.family,
                                    date: $scope.asset.rt.io[srcDt],
                                    visible : sensor.visible,

                                    ON: sensor.ON,
                                    OFF: sensor.OFF,
                                    unit: sensor.unit,
                                    value: parseFloat(realVal.toFixed(2))
                                });
                        }
                    })
                }
                if($scope.asset.rt && $scope.asset.rt.loc && $scope.asset.rt.loc.coordinates.length > 0){
                    var promise = $scope.assetAddress($scope.asset);
                    promise.then(function (address) {
                        $scope.asset.address = address;
                        $scope.updateRealtime();
                    });
                }
            }
        });

    });