angular.module('app').controller("addNewInstallationDlgCtrl", function ($scope,I3ProductSrv, Notification,installations,index, I3InstallationSrv, I3InstallationGroupSrv, $translate) {
    $scope.itemsByPage = 3;
    $scope.installation = {};

    if(installations._id) {
        $scope.confirmAdd = true;
        install = installations.installations;
        $scope.installationGroup = installations._id
    }else{
        $scope.confirmAdd = false;
    }

    $scope.confirm = function () {
        var install = angular.copy(installations);
        install.client_id=installations.client_id._id;
        install._plateforme=installations.plateform._id;
        install.client_name=installations.client_id.name;
        if(installations._id){
            $scope.installation=  installations;
            $scope.product=installations._product;

            $scope.confirmAdd = true;
        }else{
            $scope.installation={}
            I3InstallationGroupSrv.add(install, function (res) {

                if (res && res.success) {
                    $scope.installationGroup = res.result._id;
                    $scope.confirmAdd = true;
                } else {
                    Notification.error($translate.instant("INSTALLATION_GROUP_ADD_ERROR"));
                    //TODO handle error
                }

            }, function (err) {

            })
        }
    };

    $scope.installationList = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }
        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        if(tableState.pagination  && tableState.pagination.number){
            number=tableState.pagination.number;
        }
        if(tableState.pagination  && tableState.pagination.start){
            start=Math.ceil((tableState.pagination.start+1)/number);
        }
        if($scope.installList.length>0){
            $scope.interventionsCount = $scope.installList.length;
            tableState.pagination.numberOfPages = Math.ceil($scope.installList.length / number);
            tableState.pagination.totalItemCount = $scope.installList.length;
            $scope.tableState = tableState;
        }
    };


    $scope. searchProduct=function($select){

        I3ProductSrv.query({query:{name:{'$regex' :$select.search, '$options' : 'i'}}},10,0,function(res){

            $scope.products=res.result;
        },function(err){

        })
    };

    $scope.selectProduct=function(product){
        $scope.product=product;
    };
    var install = [];
    $scope.installList = [];

    $scope.add=function(installation, closeDialog){
        if(installation){
            if($scope.installation.asset_data && $scope.installation.asset_data.vin){
                install.push({
                    _installation_group:$scope.installationGroup,
                    asset_name:$scope.installation.asset_name,
                    asset_data:{vin : $scope.installation.asset_data.vin},
                    _product: $scope.installation._product._id,
                    _product_name: $scope.installation._product.name,
                    product_options: $scope.installation.product_options

                });
            }else{
                install.push({
                    _installation_group:$scope.installationGroup,
                    asset_name:$scope.installation.asset_name,
                    _product: $scope.installation._product._id,
                    _product_name: $scope.installation._product.name,
                    product_options: $scope.installation.product_options

                });
            }

            $scope.installList = install
        }
        if(closeDialog){
         $scope.addInstallations();
        }else{
            $scope.installation.asset_data.vin = '';
            $scope.installation.asset_name = '';
        }
    };

    $scope.cancel = function () {
        if($scope.installationGroup){
            if($scope.installationRequest._installationIds.length>0){
            }
            $scope.closeThisDialog($scope.installationGroup)
        }else{
            $scope.closeThisDialog()
        }
    }

     function saveInstallations(installIndex, callback) {
         if(installIndex<install.length) {
             I3InstallationSrv.add(install[installIndex], function (res) {
                 if (res && res.success) {
                     installIndex = installIndex+1;
                     saveInstallations(installIndex, callback)
                 } else {
                     callback('ERROR')
                     Notification.error("Create installation error ");
                 }

             }, function (err) {
                 callback('ERROR')
             })
         }else{
             callback()
         }
    }

    $scope.addInstallations = function () {

            saveInstallations(0,function (err, res) {
                try{
                if(Number.isFinite(index)){
                    $scope.installationRequest._installationIds[index]=installation;
                    //TODO Close dialog
                }else {
                    if(!$scope.installationRequest._installationIds ||  !Array.isArray($scope.installationRequest._installationIds )) $scope.installationRequest._installationIds=[]
                    if(install && install.length>0)
                        $scope.installationRequest._installationIds = $scope.installationRequest._installationIds.concat(install);
                }
                $scope.installations = [];
                var installations = {};
                for (var i = 0; i < $scope.installationRequest._installationIds.length; i++) {
                    if ($scope.installationRequest._installationIds[i]._product) {
                        if (!installations[$scope.installationRequest._installationIds[i]._product._id]) {
                            installations[$scope.installationRequest._installationIds[i]._product._id] = {
                                _product: $scope.installationRequest._installationIds[i]._product,
                                product_options: $scope.installationRequest._installationIds[i].product_options,
                                asset_count: 0
                            }
                        }
                        installations[$scope.installationRequest._installationIds[i]._product._id].asset_count += 1;
                    }
                }
                $scope.installationRequest.installations = (Object.values(installations))
                I3InstallationGroupSrv.updateById($scope.installationGroup,{installations: $scope.installationRequest.installations},function(res){
                    if(res && res.success){
                        $scope.cancel()
                    }else{
                        Notification.error($translate.instant("INSTALLATION_GROUP_ADD_EEROR"));
                        //TODO handle error
                    }},function(err){


                })
            }
                catch (e){
                    console.log('e',e)
                }
            Notification.success($translate.instant("SAVE_INSTALLATION_SUCCESS"));
        })

    }

});