angular.module('app').controller('adminProductsEditCtrl', function ($scope, $rootScope, $state, $stateParams, $translate, $filter, Notification, ngDialog, I3ProductSrv) {

    $scope.product = {};
    $scope.options_list = [];
    $scope.device_types_list = [];


    I3ProductSrv.getById($stateParams.productId, function (response) {
        $scope.product = response.result;
        $scope.options_list = $scope.product.options ;
        $scope.device_types_list = $scope.product.device_types ;
    }, function (err) {
        console.log(err);
    });

    $scope.save = function () {
        $scope.product.options = $scope.options_list;
        $scope.product.device_types = $scope.device_types_list;
            // Update platform
            I3ProductSrv.updateById($scope.product._id, $scope.product, function (response) {
                if(response.success){
                    Notification.success({
                        title: $translate.instant("PRODUCT_UPDATED"),
                        message : "Product " + $scope.product.name + " was updated successfully!"
                    });
                    $state.go('app.admin.products');
                    $scope.product = {};
                }else{
                    Notification.error({
                        title: $translate.instant('ERROR'),
                        message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.product.name +"!"
                    });
                    $scope.product = {};
                }
            }, function (err) {
                console.log(err);

                Notification.error({
                    title: $translate.instant('ERROR'),
                    message : $translate.instant("ERROR_OCCURED_UDPDATE") +" "+ $scope.product.name +"!"
                });
                $scope.product = {};

            })
    };


    /**
     * Cancel creating/editing product
     */
    $scope.cancel = function () {
        $scope.product = {};
        $state.go('app.admin.products');
    };


    /**
     * Add options
     */
    $scope.addOptions = function (opt) {
        if(opt){
            if($scope.options_list.indexOf(opt)>=0){
                Notification.error($translate.instant("ALREADY_EXIST"));
            }else{
                $scope.options_list.push(opt);
            }
        }else{
            Notification.error($translate.instant("NO_OPTIONS"));
        }
        $scope.options = '';
    };

    /**
     * Remove options
     */
    $scope. removeOptions=function(index){
        $scope.options_list.splice(index, 1);
    };

    /**
     * Add device type
     */
    $scope.addDeviceType = function (deviceType) {
        if(deviceType){
            if($scope.device_types_list.indexOf(deviceType)>=0){
                Notification.error($translate.instant("ALREADY_EXIST"));
            }else{
                $scope.device_types_list.push(deviceType);
            }
        }else{
            Notification.error($translate.instant("NO_DEVICE_TYPE"));
        }
        $scope.device_types = '';
    }

    /**
     * Remove device type
     */
    $scope. removeDeviceType=function(index){
        $scope.device_types_list.splice(index, 1);
    };

});