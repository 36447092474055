angular.module('app').controller('adminProductsCtrl', function ($scope, $rootScope, $state, $translate, $filter, Notification, ngDialog, I3ProductSrv) {

    $scope.product = {};
    //$scope.itemsByPage = 50;
    //$scope.search = {};


    /**
     * Loading products
     */
    I3ProductSrv.query({}, 10000, 1, function (response) {
        $scope.products = response.result;
    }, function (err) {
        console.log(err);
    });

    /**
     * Getting users
     */
    $scope.loadProducts  = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;


        var query = {};

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            //start = pagination.start / number + 1 || 1;
            $scope.numberPages.productItemByPages = number
            if($scope.numberPages.productNumberPages){
                start = $scope.numberPages.productNumberPages
            }else{
                start = pagination.start / number + 1 || 1;
            }
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }

        if(!$scope.search.name) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.name) {
                    $scope.search.name = tableState.search.predicateObject.name
                    query["name"] = {"$regex": tableState.search.predicateObject.name, "$options": "i"};
                }
            }else{
                query.name = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.name = $scope.search.name
            }
            query.name = {'$regex' :$scope.search.name, '$options' : 'i'}
        }

        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.name = tableState.search.predicateObject.name
        }


        I3ProductSrv.query({query: query} , number, start, function (response) {
            if(response.success){
                $scope.products = response.result;
                $scope.productCount = response.total_count;
                $scope.productCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                tableState.pagination. start=start*number-1>=0? start*number-1: 0;
                $scope.tableState = tableState;
            }
        });
    };


    /**
     * Removing product
     * @param product
     */
    $scope.remove = function (product) {
        $scope.product = product;

        ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/product.delete.dlg.tpl.html",
            controller : "confirmDeleteProductDlgCtrl",
            scope: $scope
        })

    };
    $scope.onPageChange = function (val) {
        $scope.numberPages.productNumberPages = val
        $scope.numberPages.productItemByPages = $scope.itemsByPage
    }

    if(!$scope.numberPages.productItemByPages || $scope.numberPages.productItemByPages === undefined){
        $scope.itemsByPage = 50
    }else{
        $scope.itemsByPage = $scope.numberPages.productItemByPages
    }



});