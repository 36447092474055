/**
 * Created by Mohamed Saad on 10/01/2018.
 */


angular.module("app").controller('actionLogsCtrl', function ($scope, I3ActionLogSrv, Notification,CONFIG,$translate,$state,ngDialog, I3UserSrv) {

    //$scope.itemsByPage=50;
    //$scope.search = {};

    $scope.actionTypes=["DELETE","UPDATE","SIGN_IN","CREATE"];
    $scope.models=["INTERVENTION","CLAIM","INSTALLATION","installation_group","PRODUCT","USER","ROLE","DOCUMENTS","SETTING"];


    $scope.loadActionLogs=function (tableState,ctrl){

        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options =  {populate: '_user ',sortBy:{creation_dt:-1}}

        var query = {};

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            //start = pagination.start / number + 1 || 1;
            $scope.numberPages.actionItemByPages = number
            if($scope.numberPages.actionNumberPages){
                start = $scope.numberPages.actionNumberPages
            }else{
                start = pagination.start / number + 1 || 1;
            }
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }
        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.ip_browser) {

                query["$or"] =
                    [
                        { "sessionData.remoteIpAddress": {"$regex": tableState.search.predicateObject.ip_browser, "$options": "i"}},
                        {"sessionData.remoteBrowser": {"$regex":tableState.search.predicateObject.ip_browser, "$options": "i"} },
                        {"sessionData.geoData.countryCode": {"$regex":tableState.search.predicateObject.ip_browser, "$options": "i"} },
                        {"sessionData.geoData.city": {"$regex":tableState.search.predicateObject.ip_browser, "$options": "i"} },
                        {"sessionData.geoData.country": {"$regex":tableState.search.predicateObject.ip_browser, "$options": "i"} },
                        {"sessionData.geoData.org": {"$regex":tableState.search.predicateObject.ip_browser, "$options": "i"} },
                        {"sessionData.geoData.isp": {"$regex":tableState.search.predicateObject.ip_browser, "$options": "i"} }
                    ]
            }
        }


        if($scope.search){
            if ($scope.search._action) {
                query["_action"] = {'$regex': $scope.search._action, $options: '-i'}
            }
            if ($scope.search._user) {
                query._user= $scope.search._user._id;
            }
            if ($scope.search._model) {
                query["_model"] =   {'$regex': $scope.search._model, $options: '-i'}
            }
        }


        I3ActionLogSrv.query({query: query, options: options } , number, start,function(data){
            if(data.success){
                $scope.actionLogs=data.result;
                $scope.count = data.total_count;

                tableState.pagination.numberOfPages =Math.ceil(data.total_count/ number);
                tableState.pagination.totalItemCount = data.total_count;
                tableState.pagination. start=start*number-1>=0? start*number-1: 0;
            }else{
                console.log(data.error)
            }

        },function(){

        })
    };

    /**
     * Loading users list
     */
    I3UserSrv.query({}, 10000, 1, function (res) {
        if(res.success){
            $scope.users = res.result;
        }
    }, function (err) {
        console.log(err);
    });

    $scope.showDiff=function(object){
        console.log(object)
        ngDialog.open({
            template: 'app/commons/dialogs/actionlogs/action.log.diff.view.dlg.html',
            controller: 'ActionLogDiffCtrl',
            scope: $scope,
            resolve: {
                action_log: function action_logFactory() {
                    return object ;
                }
            }

        }).closePromise.then(function (data) {


        });
    }

    $scope.onPageChange = function (val) {
        $scope.numberPages.actionNumberPages = val
        $scope.numberPages.actionItemByPages = $scope.itemsByPage
    }

    if(!$scope.numberPages.actionItemByPages || $scope.numberPages.actionItemByPages === undefined){
        $scope.itemsByPage = 50
    }else{
        $scope.itemsByPage = $scope.numberPages.actionItemByPages
    }

});
