angular.module('app').controller('claimsDashboardCtrl', function ($scope, I3ClaimSrv,_) {


    $scope.selectedPeriod = 'month';
    var now = moment().clone();

    $scope.startRes = moment().startOf("day").clone();
    $scope.endRes = now;
    $scope.dayRes = now;
    $scope.monthRes = now;

    $scope.pickerStart = {
        format : "DD/MM/YYYY",
        init: moment().startOf("day").clone(),
        position: "top left",
        autoTrans : false,
        enableTime : false,
        enableDate : true,
        enableMinutes : false,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.startRes = date;
            $scope.pickerEnd.minDate = date;
            getClaimsData();
        }
    };

    $scope.pickerEnd = {
        format : "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans : false,
        enableTime : false,
        enableDate : true,
        enableMinutes : false,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.endRes = date;
            $scope.pickerStart.maxDate = date;
            getClaimsData();
        }
    };

    $scope.pickerDay = {
        format : "DD/MM/YYYY",
        position: "top left",
        init: now,
        autoTrans : false,
        enableTime : false,
        enableDate : true,
        enableMinutes : false,
        defaultView : "DATE",
        getDate : function (date) {
            $scope.dayRes = date;
            getClaimsData();
        }
    };

    $scope.pickerMonth = {
        name:"pickerMonth",
        format : "MM/YYYY",
        position: "top left",
        init: now,
        autoTrans : false,
        enableTime : false,
        enableDate : true,
        enableMinutes : false,
        defaultView : "MONTH",
        getDate : function (date) {
            $scope.monthRes = date;
            getClaimsData();
        }
    };

    $scope.openCalendar = function (e, picker) {
        $scope[picker].open = true;
    };


    $scope.selectPeriod = function(period){
        $scope.selectedPeriod = period;
        if($scope.selectedPeriod === "month"){
            getClaimsData();
        }else if($scope.selectedPeriod === "day"){
            getClaimsData();
        } else if($scope.selectedPeriod === "week"){
            getClaimsData();
        }else if($scope.selectedPeriod === "free"){
            getClaimsData();

/*            var start_dt = $scope.startRes.clone();
            var end_dt = $scope.endRes.clone();

            if(end_dt.diff(start_dt, 'days') <= 1){
             //   $scope.getHourlyData();
            }else{
                if(end_dt.diff(start_dt, 'days') > 1 && end_dt.diff(start_dt, 'days') <= 30){
                  //  $scope.getDailyData();
                }else{
                 //   Notification.error($translate.instant("LARGE_PERIOD_ERROR_MSG"));
                }
            }*/
        }
    };

    var getQuery = function () {

        var  match={};
        var agg=getDailyQuery();

        if($scope.selectedPeriod === 'day'){
            match = {

                "creation_dt" : {
                        "$gt":'#ISODATE#'+$scope.dayRes.clone().startOf('day').format('YYYY-MM-DD') + "T00:00:00.000Z"+'#ISODATEEND#',
                        "$lt":'#ISODATE#'+$scope.dayRes.clone().add('1', 'days').format('YYYY-MM-DD') + "T00:00:00.000Z"+'#ISODATEEND#'
                    }


        }

        }else if($scope.selectedPeriod === 'week'){
            match = {

                "creation_dt" : {
                        "$gt":'#ISODATE#'+$scope.dayRes.clone().subtract(7,'d').format('YYYY-MM-DD') +"T00:00:00.000Z"+'#ISODATEEND#',
                        "$lt" :'#ISODATE#'+$scope.dayRes.clone().format('YYYY-MM-DD') +"T00:00:00.000Z"+'#ISODATEEND#'
                    }

            };
        }else if($scope.selectedPeriod === 'month'){
            match = {

                "creation_dt" : {
                        "$gt":'#ISODATE#'+$scope.monthRes.clone().startOf('month').format('YYYY-MM-DD') +"T00:00:00.000Z"+'#ISODATEEND#',
                        "$lt" :'#ISODATE#'+$scope.monthRes.clone().endOf('month').format('YYYY-MM-DD') +"T00:00:00.000Z"+'#ISODATEEND#'
                    }

            };
        }else if($scope.selectedPeriod === 'free'){
            match = {

                "creation_dt" : {
                        "$gt":'#ISODATE#'+$scope.startRes.clone().format('YYYY-MM-DD') +"T00:00:00.000Z"+'#ISODATEEND#',
                        "$lt" :'#ISODATE#'+$scope.endRes.clone().add('1', 'days').format('YYYY-MM-DD') +"T00:00:00.000Z"+'#ISODATEEND#'
                    }

            };
        }else{
            match = {

                "creation_dt" : {
                        "$gt":'#ISODATE#'+$scope.startRes.clone().format('YYYY-MM-DD') +"T00:00:00.000Z"+'#ISODATEEND#',
                        "$lt" :'#ISODATE#'+$scope.endRes.clone().add('1', 'days').format('YYYY-MM-DD') +"T00:00:00.000Z"+'#ISODATEEND#'
                    }

            };
        }

        var query =
            [
                {
                    "$match": match
                },                {
                $lookup: {
                    from: "plateforme",
                    localField: "_plateforme",
                    foreignField: "_id",
                    as: "plateforme"
                }
            },
                {
                    "$project":{

                        "pl": {$arrayElemAt: ["$plateforme", 0]},
                        "status": "$status",
                        "dt": "$creation_dt",
                        'date':agg.project
                    }
                },
                {
                    $group: {
                        _id: agg.group,

                        "count": {"$sum": 1},

                        "data": {
                            $push: {
                                "pl" : "$pl.name",
                                "status": "$status",
                                "dt": "$dt",
                                "count": {"$sum": 1}

                            }
                        }

                    }
                },
                {"$sort": {"_id": 1}}

            ];


        return query
    };

    var getDailyQuery=function(){
        return {


            "project": {

                "y": {"$year":{"$add":["$creation_dt",moment().utcOffset()*60*1000]}},
                "m": {"$month":{"$add":["$creation_dt",moment().utcOffset()*60*1000]}},
                "d": {"$dayOfMonth":{"$add":["$creation_dt",moment().utcOffset()*60*1000]}}

            },
            "group": {
                "y": "$date.y",
                "m": "$date.m",
                "d": "$date.d"
            }



        }
    };


    var getClaimsData=function() {
        var claims=[];
        var histoData=[];
        I3ClaimSrv.agg({aggregate:getQuery()}, 100000, 0, function (res) {

            $scope.claimsPerDay=[];
            $scope.claimsDataOpen=[];
            $scope.claimsDataClosed=[];
            $scope.seriesPl=[];


            if (res && res.success && res.result && res.result.length>0) {

                 claims = angular.copy(res.result);

                claims.forEach(function (value) {


                    var _date=moment({ year :value._id.y,
                        //month :value._id.m-1,
                        month :value._id.m-1,
                        day :value._id.d
/*                        hour :value._id.h || 0,
                        minute :value._id.mm || 0,
                        second :value._id.ss || 0,
                        millisecond :0*/
                    });


                    if (value && value.data && value.data.length > 0) {
                        var  elemTab = value.data;
                        var counter1=0;
                        var counter2=0;

                        elemTab.forEach(function (elem) {
                             histoData.push(elem);

      //    console.log('elem',elem)
                            if(elem && elem.status=='New' || elem.status=='NEW'|| elem.status=='Open'|| elem.status=='InIntervention'|| elem.status=='InDevelopment'|| elem.status=='InProgress'|| elem.status=='OnHold'){
                                counter1+=elem.count;

                            }

                            if(elem && elem.status=='Done' || elem.status=='Rejected'){
                                counter2+=elem.count;

                            }


                        });
                        $scope.claimsDataOpen.push([_date.valueOf(),counter1]);
                        $scope.claimsDataClosed.push([_date.valueOf(),counter2]);


                    }

                    $scope.claimsPerDay.push([_date.valueOf(),value.count]);

                });

                var groups = _.groupBy(histoData, function (val) {
                  //  return moment(val.dt).startOf('day').format();
                    return val.pl;
                });


                angular.forEach(groups, function(value, key) {
                    var elem={};

                  if(key){
                      elem.name=key;

                      if(value){
                              elem.y=value.length
                      }

                      $scope.seriesPl.push(elem)

                  }


                });


           $scope.showChart();

            } else {
                $scope.showChart();

                Notification.error('Erreur de chargement')
            }
        }, function (err) {
        });

    };


    $scope.showChart = function () {
        Highcharts.chart('chart', {
            chart: {
                zoomType: 'x'
            },
            title: {
                text: 'Nombre des Réclamations par jour'
            },

            subtitle: {
                text:''
            },

            yAxis: {
                title: {
                    text: 'Réclamations'
                }
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    month: '%e. %b',
                    year: '%b'
                },
                title: {
                    text: 'Date'
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },

            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    pointStart: 2017
                }
            },

            series: [{
                name: 'Réclamations',
                data: $scope.claimsPerDay
            },{
                name: 'Réclamations ouvertes',
                data: $scope.claimsDataOpen
            },{
                name: 'Réclamations fermées',
                data: $scope.claimsDataClosed
            }
            ],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            credits: {
                enabled: false
            }
        });



        Highcharts.chart('chart2', {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Nombre des Réclamations par Platforme'
            },
            tooltip: {
                pointFormat: 'Nombre : <b>{point.y}</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    }
                }
            },
            series: [{
                name: 'Plateforme',
                colorByPoint: true,
                data: $scope.seriesPl
            }],
            credits: {
                enabled: false
            }
        });

    }





});