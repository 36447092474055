angular
    .module('app')
    .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/login");

    $stateProvider
        .state('login', {
            url: '/login',
            templateUrl: 'app/pages/login/login.tpl.html',
            controller: 'loginCtrl'
        })
        .state('client', {
            url: '/client',
            templateUrl: "app/pages/clients/installationClient.tpl.html",
            controller: "installationClientCtrl"

        })
        .state('app', {
            url: '/app',
            templateUrl: 'app/pages/main.tpl.html',
            controller : 'mainCtrl',
            abstract: true
        })
        .state('app.dashboard', {
            url: '/dashboard',
            views: {
                sidebar : {
                    templateUrl: "app/pages/dashboard/sidebar/sidebar.tpl.html",
                    controller: "sidebarDashboardCtrl"
                },
                content : {
                    templateUrl: "app/pages/dashboard/dashboard.tpl.html",
                    controller: "dashboardCtrl"
                }
            }

        })
        .state('app.dashboard.install', {
            url: '/installations',
            views: {
                sidebar : {
                    templateUrl: "app/pages/dashboard/sidebar/sidebar.tpl.html",
                    controller: "sidebarDashboardCtrl"
                },
                content : {
                    templateUrl: "app/pages/dashboard/installations/installations.dashboard.tpl.html",
                    controller: "installDashboardCtrl"
                }
            }

        })
        .state('app.dashboard.request', {
            url: '/installrequest',
            views: {
                sidebar : {
                    templateUrl: "app/pages/dashboard/sidebar/sidebar.tpl.html",
                    controller: "sidebarDashboardCtrl"
                },
                content : {
                    templateUrl: "app/pages/dashboard/installations-request/installations.requests.dashboard.tpl.html",
                    controller: "installationRequestDashboardCtrl"
                }
            }

        })
        .state('app.dashboard.claims', {
            url: '/claims',
            views: {
                sidebar : {
                    templateUrl: "app/pages/dashboard/sidebar/sidebar.tpl.html",
                    controller: "sidebarDashboardCtrl"
                },
                content : {
                    templateUrl: "app/pages/dashboard/claims/claims.dashboard.tpl.html",
                    controller: "claimsDashboardCtrl"
                }
            }

        })
        .state('app.dashboard.interventions', {
            url: '/claims',
            views: {
                sidebar : {
                    templateUrl: "app/pages/dashboard/sidebar/sidebar.tpl.html",
                    controller: "sidebarDashboardCtrl"
                },
                content : {
                    templateUrl: "app/pages/dashboard/interventions/interventions.dashboard.tpl.html",
                    controller: "interventionsDashboardCtrl"
                }
            }

        })
        .state('app.home', {
            url: '/home',
            views: {
                sidebar : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/home.tpl.html",
                    controller: "homeCtrl"
                }
            }

        })
        .state('app.home.installRequest', {
            url: '/installrequest',
            views: {
                sidebar : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/installations-request/installations.requests.tpl.html",
                    controller: "installationRequestCtrl"
                }
            }

        })
        .state('app.home.installRequestAdd', {
            url: '/installrequest/add',
            views: {
                sidebar : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/installations-request/installations.requests.add.tpl.html",
                    controller: "installationRequestAddCtrl"
                }
            }

        })
        .state('app.home.installRequestEdit', {
            url: '/installrequest/edit/:id',
            views: {
                sidebar : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/installations-request/installations.requests.add.tpl.html",
                    controller: "installationRequestAddCtrl"
                }
            }

        }).state('app.home.installRequestDetails', {
            url: '/installrequest/details/:id',
            views: {
                sidebar : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/installations-request/installationsRequestDetails.tpl.html",
                    controller: "installationRequestDetailsCtrl"
                }
            }

        })
        .state('app.home.clientForm', {
            url: '/clientform',
            views: {
                sidebar : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/clients-form/clients.forms.tpl.html",
                    controller: "clientFormListCtrl"
                }
            }

        }).state('app.home.clientFormDetails', {
            url: '/clientform/details/:id',
            views: {
                sidebar : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/clients-form/clientFormDetails.tpl.html",
                    controller: "clientFormDetailsCtrl"
                }
            }

        }).state('app.home.installDetails', {
            url: '/install/details/:id',
            views: {
                sidebar : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/installations-request/installationDetails.tpl.html",
                    controller: "installationDetailsCtrl"
                }
            }

        })
        .state('app.home.intervention', {
            url: '/intervention',
            views:{
                sidebar: {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content :{
                    templateUrl: "app/pages/home/intervention/intervention.tpl.html",
                    controller: "interventionCtrl"
                }
            }
        })
        .state('app.home.interventionAdd', {
            url: '/intervention/add',
            views:{
                sidebar: {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content :{
                    templateUrl: "app/pages/home/intervention/intervention.add.tpl.html",
                    controller: "interventionAddCtrl"
                }
            }
        })
        .state('app.home.interventionEdit', {
            url: '/intervention/edit/:id',
            views:{
                sidebar: {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content :{
                    templateUrl: "app/pages/home/intervention/intervention.add.tpl.html",
                    controller: "interventionAddCtrl"
                }
            }
        })
        .state('app.home.interventionDetails', {
            url: '/intervention/details/:id',
            views: {
                sidebar : {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/intervention/interventionDetails.tpl.html",
                    controller: "interventionDetailsCtrl"
                }
            }
        })
        .state('app.home.planning',{
            url: '/planning',
            views : {
                sidebar: {
                    templateUrl: "app/pages/home/sidebar/sidebar.tpl.html",
                    controller: "sidebarHomeCtrl"
                },
                content : {
                    templateUrl: "app/pages/home/planning/planning.tpl.html",
                    controller: "planningCtrl"
                }
            }
        })
        .state('app.admin', {
            url: '/admin',
            views: {
                sidebar : {
                    templateUrl: "app/pages/admin/sidebar/admin.sidebar.tpl.html",
                    controller: "adminSidebarCtrl"
                },
                content : {
                    templateUrl: "app/pages/admin/admin.tpl.html",
                    controller: "adminCtrl"
                }
            }

        })
        .state('app.admin.platform', {
            url : '/platforms',
            templateUrl: 'app/pages/admin/platforms/platforms.tpl.html',
            controller: "adminPlatformCtrl"
        })
        .state('app.admin.users', {
            url : '/users',
            templateUrl: 'app/pages/admin/users/users.tpl.html',
            controller: "adminUserCtrl"
        })
        .state('app.admin.users.add', {
            url: '/add',
            templateUrl: 'app/pages/admin/users/user.add.tpl.html',
            controller: "adminUserAddCtrl"
        })
        .state('app.admin.users.edit', {
            url: '/edit/:userId',
            templateUrl: 'app/pages/admin/users/user.edit.tpl.html',
            controller: "adminUserEditCtrl"

        })
        .state('app.admin.products', {
            url: '/products',
            templateUrl: 'app/pages/admin/products/products.tpl.html',
            controller: "adminProductsCtrl"
        })
        .state('app.admin.products.add', {
            url: '/add',
            templateUrl: 'app/pages/admin/products/product.add.tpl.html',
            controller: "adminProductsAddCtrl"
        })
        .state('app.admin.products.edit', {
            url: '/edit/:productId',
            templateUrl: 'app/pages/admin/products/product.edit.tpl.html',
            controller: "adminProductsEditCtrl"

        })
        .state('app.admin.claims', {
        url : '/claims',
        templateUrl: 'app/pages/admin/claims/claims.tpl.html',
        controller: "adminClaimsCtrl"
        })
        .state('app.admin.claims.add', {
            url: '/add',
            templateUrl: 'app/pages/admin/claims/claim.add.tpl.html',
            controller: "adminClaimAddCtrl"
        })
        .state('app.admin.claims.detail', {
            url: '/detail/:claimId',
            templateUrl: 'app/pages/admin/claims/claim.detail.tpl.html',
            controller: "adminClaimDetailCtrl"

        }) .state('app.admin.documents', {
            url: '/documents',
            templateUrl: 'app/pages/admin/documents/document.tpl.html',
            controller: "adminDocumentsCtrl"
        })
        .state('app.admin.roles', {
            url : '/roles',
            templateUrl: 'app/pages/admin/roles/role.tpl.html',
            controller: "adminRoleCtrl"
        })
        .state('app.admin.roles.add', {
            url: '/add',
            templateUrl: 'app/pages/admin/roles/role.add.tpl.html',
            controller: "adminRoleAddCtrl"
        })
        .state('app.admin.roles.edit', {
            url: '/edit/:roleId',
            templateUrl: 'app/pages/admin/roles/role.edit.tpl.html',
            controller: "adminRoleEditCtrl"

        })
        .state('app.admin.actionlogs', {
            url : '/actionlogs',
            templateUrl: 'app/pages/admin/actionlogs/actionlogs.tpl.html',
            controller: "actionLogsCtrl"
        })
        .state('app.home.claims', {
            url : '/claims',
            views: {
                sidebar : {
                    templateUrl: "app/pages/admin/sidebar/admin.sidebar.tpl.html",
                    controller: "adminSidebarCtrl"
                },
                content : {
                    templateUrl: 'app/pages/home/claims/claims.tpl.html',
                    controller: "homeClaimsCtrl"
                }
            }
        })
        .state('app.home.claims.add', {
            url: '/add',
            views: {
                sidebar : {
                    templateUrl: "app/pages/admin/sidebar/admin.sidebar.tpl.html",
                    controller: "adminSidebarCtrl"
                },
                content : {
                    templateUrl: 'app/pages/home/claims/claim.add.tpl.html',
                    controller: "homeClaimAddCtrl"
                }
            }


        })
        .state('app.home.claims.detail', {
            url: '/detail/:claimId',

            views: {
                sidebar : {
                    templateUrl: "app/pages/admin/sidebar/admin.sidebar.tpl.html",
                    controller: "adminSidebarCtrl"
                },
                content : {
                    templateUrl: 'app/pages/home/claims/claim.detail.tpl.html',
                    controller: "homeClaimDetailCtrl"
                }
            }


        })
        .state('app.admin.installations', {
            url : '/installations',
            templateUrl: 'app/pages/admin/installations/installations.tpl.html',
            controller: "adminInstallationsCtrl"
        })
        .state('app.admin.installations.details', {
            url : '/detail/:id',
            templateUrl: "app/pages/admin/installations/installationDetailsAdmin.tpl.html",
            controller: "installationDetailsAdminCtrl"
        })

        .state('app.admin.settings', {
            url : '/settings',
            templateUrl: 'app/pages/admin/settings/settings.tpl.html',
            controller: "adminSettingCtrl"
        }).state('app.admin.settings.add', {
            url : '/add',
            templateUrl: 'app/pages/admin/settings/settings.add.tpl.html',
            controller: "settingsAddCtrl"
        }).state('app.admin.settings.edit', {
            url : '/edit/:id',
            templateUrl: 'app/pages/admin/settings/settings.edit.tpl.html',
            controller: "settingsEditCtrl"
        })
}
