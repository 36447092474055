angular.module('app').controller("installationListAddDlgCtrl", function ($rootScope,$scope,I3ProductSrv, Notification,installations,index,plateforme,iaxeedAssetSrv,I3InstallationSrv,constantSrv,plateformHelperSrv,I3UserSrv,$translate,NGIFleetHelperSrv,NGIFleetAssetSrv) {


    $scope.assets=[];
    try {
        var isLoggedOnToPlateforme = false;
        if (installations === null) {
            $scope.sim_included = $scope.installationRequest.sim_included

        } else {
            $scope.sim_included = installations.sim_included;
            $scope.installationRequest=installations._installation_group;

            $scope.installation  = installations;
            if(installations.asset) {
                $scope.asset = {
                    _id: installations.asset,
                    name: installations.asset_name
                }
            }else{
                    $scope.asset=null;


        }
        }
        $scope.statusList = constantSrv.getInstallationStatus();
        $scope.selectPlateform = function (platform, cb) {

            $scope.assets = [];
            if (platform.type == "NGI_FLEET") {
                NGIFleetHelperSrv.setPlateFormAndLogin(platform, function (res) {

                    if (res.result) {
                        cb(null, true);
                    } else {
                        cb(true, false);
                        Notification.error("Impossile de se connecter à la plateforme " + platform.name)
                    }
                    console.log("NGIFleetHelperSrv", res);
                }, function (err) {
                    cb(true, false);
                    Notification.error("Une erreur s'est produite lor de la connexrion à la plateforme  " + platform.name)
                })
            } else {
                plateformHelperSrv.setPlateFormAndLogin(platform, function (res) {

                    if (res && res.success) {
                        cb(false, true);
                    } else {
                        cb(true, false);
                        Notification.error("Impossile de se connecter à la plateforme " + platform.name)
                    }

                }, function (err) {
                    cb(true, false);
                    Notification.error("Une erreur s'est produite lor de la connexrion à la plateforme  " + platform.name)
                })
            }

        }
        /**
         * init
         */


        if (installations) {

            if (plateforme._id != $rootScope.plateform._id) {
                $scope.selectPlateform(plateforme, function (err, res) {
                    if (!error) {
                        $scope.installation = installations;
                        $scope.product = installations._product;
                        isLoggedOnToPlateforme = true;

                    }

                })
            } else {
                isLoggedOnToPlateforme = true;
            }

        } else {
            $scope.installation = {}
        }


        $scope.searchUser = function ($select) {

            I3UserSrv.query({
                query: {
                    $or: [
                        {login: {'$regex': $select.search, '$options': 'i'}},
                        {first_name: {'$regex': $select.search, '$options': 'i'}},
                        {last_name: {'$regex': $select.search, '$options': 'i'}}
                    ]
                }
            }, 10, 0, function (res) {

                $scope.users = res.result;
            }, function (err) {

            })
        }
        $scope.selectProduct = function (product) {
            $scope.product = product;

        }
        $scope.add = function (installation) {

            installation.sim_included = $scope.sim_included;
            if (installation.asset) {

            }

            if (Number.isFinite(index)) {
                //$scope.installationRequest._installationIds[index]=installation;
                I3InstallationSrv.updateById(installation._id, installation, function (res) {
                    if (res && res.success) {
                        Notification.success($translate.instant("SAVE_INSTALLATION_SUCCESS"));
                        $scope.closeThisDialog(res.result)
                    } else {
                        Notification.error($translate.instant("SAVE_INSTALLATION_ERROR"))
                    }

                }, function (err) {
                    Notification.error($translate.instant("SAVE_INSTALLATION_ERROR"))
                })

            } else {
                if (!$scope.installationRequest._installationIds || !Array.isArray($scope.installationRequest._installationIds)) $scope.installationRequest._installationIds = []
                //   $scope.installationRequest._installationIds.push(installation);
                if (!installation._installation_group) {
                    installation._installation_group = $scope.installationRequest._id;
                }
                I3InstallationSrv.add(installation, function (res) {
                    if (res && res.success) {
                        Notification.success($translate.instant("SAVE_INSTALLATION_SUCCESS"));
                        $scope.closeThisDialog()
                        I3InstallationSrv.query({
                            query: {_id: res.result._id},
                            options: {populate: "_assigned_to _product"}
                        }, 1, 0, function (install) {
                            if (install && install.success && install.result && install.result.length > 0) {
                                console.log(install)
                                $scope.installationRequest._installationIds.push(install.result[0]);
                            }

                        }, function (err) {
                            console.error(err);
                        })
                    } else {
                        Notification.error($translate.instant("SAVE_INSTALLATION_ERROR"))
                    }

                }, function (err) {
                    Notification.error($translate.instant("SAVE_INSTALLATION_ERROR"))
                })
            }
        };

        $scope.selectAsset = function (asset) {
            $scope.installation.asset_name = asset.name;
            $scope.installation.asset = asset._id;
        }

        var noReloadAsset = false;
        $scope.searchAsset = function ($select) {
            if ($rootScope.plateform && $rootScope.plateform._id === plateforme._id) {
                if (plateforme.type == "NGI_FLEET") {
                     if(noReloadAsset) return ;
                    NGIFleetAssetSrv.getAssetByClient($scope.installation._installation_group.client_id, $select.search, 1000, 0, function (res) {
                        noReloadAsset=true;

                        $scope.assets = res.result;
                    }, function (err) {
                        console.log(err);
                    })
                } else {
                    iaxeedAssetSrv.query({
                        query: {
                            name: {'$regex': $select.search, '$options': 'i'},
                            _company_owner: $scope.installation._installation_group.client_id
                        }
                    }, 10, 0, function (res) {
                        $scope.assets = res.result;
                    }, function (err) {
                        console.error(err);
                    })
                }
            } else {


                $scope.selectPlateform(plateforme, function (err, res) {
                    if (platform.type == "NGI_FLEET") {

                        NGIFleetAssetSrv.getAssetByClient($scope.installation._installation_group.client_id, $select.search, 1000, 0, function (res) {


                            $scope.assets = res.result;
                        }, function (err) {
                            console.log(err);
                        })
                    } else {
                        iaxeedAssetSrv.query({
                            query: {
                                name: {'$regex': $select.search, '$options': 'i'},
                                _company_owner: $scope.installation._installation_group.client_id
                            }
                        }, 10, 0, function (res) {
                            $scope.assets = res.result;
                        }, function (err) {
                            console.error(err);
                        })
                    }

                });
            }

        }
    }catch(e){
        console.log(e);
    }
});