angular.module('app').controller('homeClaimDetailCtrl', function ($scope,$rootScope, $q, $state,$timeout, $stateParams, $window, $translate,iaxeedSocketSrv,geoServiceProxy, iaxeedSensorSrv, I3ClaimSrv, I3PlateformeSrv, ngDialog, Notification, iaxeedAssetSrv, plateformHelperSrv, I3UserSrv, I3InterventionSrv, constantSrv, I3DocumentSrv, i3HelperSrv ,openProjectSrv) {

    $scope.claim = {};
    $scope.status = constantSrv.getClaimsStatus();
    $scope.emailFollow = "";



    /**
     * select and authenticate plateforme
     * @param platform
     */


    $scope.uploadFiles = function(files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        i3HelperSrv.uploadFiles(files, function (res) {
            angular.forEach(res.result , function (file) {
                $scope.claim.docs.push(file)
            });
            I3ClaimSrv.updateById($stateParams.claimId,{docs:$scope.claim.docs},function(res){
                if(res && res.success){
                    Notification.success("CLAIM_UPDATED");
                }else{
                    Notification.error("CLAIM_UPDATED_ERROR");
                }},function(err){

            })
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    };

    $scope.downloadFile=function(file){
        i3HelperSrv.downloadFile(file._id, file.original_name,function success(res){

        },function error(err){

        },function progress(evt){
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };

    $scope.getInterventions=function(claim) {
        I3InterventionSrv.query({query: {_claim: {'$in': claim}}}, 10000, 1, function (res) {
            if (res.success) {
                $scope.interventions = res.result;
            }
        }, function (err) {
            console.log(err);
        });
    };

    $scope.getClaims = function () {
        var query = {
            query: {_id: $stateParams.claimId},
            options: {
                populate: [
                    {
                        path: '_created_by'
                    },
                    {
                        path: '_plateforme'
                    },
                    {
                        path : 'docs'
                    },
                    {
                        path : 'followers.user'
                    },
                    {
                        path: 'comments.owner',
                        select: 'login'
                    }
                ]
            }
        };
        I3ClaimSrv.query(query,1000,0, function (response) {
            $scope.claim = response.result[0];
            $scope.plateforme = $scope.claim._plateforme;
            if($scope.plateforme.type == 'NGI_FLEET'){
                return
            }
            plateformHelperSrv.setPlateFormAndLogin($scope.plateforme, function (res) {

                if(res.success) {
                    $scope.plateformeToken = res.token;

                    /**
                     * Platform authentication is available
                     * Connecting to socket
                     */


                    var assetIds = [];
                    angular.forEach($scope.claim.asset, function (asset) {
                        assetIds.push(asset.asset_id);
                    });

                    var query = {
                        _id: {
                            $in : assetIds
                        }
                    };
                    var options = {};

                    iaxeedAssetSrv.query({query : query, options:options}, 10000, 1, function (res1) {
                        if(res1.result){
                            $scope.assets = res1.result;

                        }
                    }, function (err) {
                        console.log(err);
                    });

                }
            }, function (err) {
                console.log(err)
            });

            if($scope.claim.ticket && $rootScope.SESSION.user.oo_key){
                openProjectSrv.getTaskById($scope.claim.ticket,function(res){

                    if(res){
                        console.log(res);
                        $scope.ticket=res;
                    }else{
                        Notification.error($translate.instant("CANNOT_GET_OPEN_PROJECT_TICKET",{id:$scope.claim.ticket}));
                    }

                },function(err){
                    Notification.error($translate.instant("CANNOT_GET_OPEN_PROJECT_TICKET_ERROR"));
                })
            }else if($scope.claim.ticket ){
                $scope.ticketUrl = $rootScope.config.OPEN_PROJECT_URI+'work_packages/'+$scope.claim.ticket +'/activity';
            }
            $scope.getInterventions($scope.claim._id);
        }, function (err) {
            console.log(err);
        });
    };


    $scope.showRealtime = function (asset) {
        $scope.asset = asset;
        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });

        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });
    };




    /**
     * Following the current claim
     */
    $scope.follow = function () {
        if($scope.emailFollow && $scope.emailFollow !== "") {
            var follower = {};
            if (!$scope.claim.followers) {
                $scope.claim.followers = [];
            }

            follower.email = $scope.emailFollow;


            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};

            var exist = false;

            angular.forEach($scope.claim.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });

            if (!exist) {
                I3UserSrv.query({query: query, options: options}, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }

                    $scope.claim.followers.push(follower);

                    delete $scope.claim.__v;

                    I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                        if (response.success) {
                            Notification.success({
                                title: "Following claim",
                                message: "The email address" + $scope.emailFollow + " is now following claim " + $scope.claim._plateforme.name
                            });

                            $scope.emailFollow = "";
                        } else {
                            Notification.error({
                                title: "Erreur",
                                message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.claim._plateforme.name + "!"
                            });
                            $scope.claim = {};
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: "Erreur",
                            message: msg
                        });
                    })
                });
            } else {
                Notification.warning({
                    title: "Already follower",
                    message: "This email is already subscribed as a follower here"
                });
                $scope.emailFollow = "";
            }
        }

    };

    /**
     * Remove follower
     */
    $scope.removeFollower = function (follower) {
        if($scope.claim.followers.indexOf(follower) >= 0){
            $scope.claim.followers.splice($scope.claim.followers.indexOf(follower), 1);
            delete $scope.claim.__v;

            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if(response.success){
                    Notification.success({
                        title : "Removing claim follower",
                        message : "The follower of claim " + $scope.claim._plateforme.name+" was removed successfully"
                    });
                }else{
                    Notification.error({
                        title: "Erreur",
                        message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.claim._plateforme.name +"!"
                    });
                    $scope.claim  = {};
                }
            }, function (err) {
                var msg = "";
                if( err.error.errorString){
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: "Erreur",
                    message : msg
                });
            })
        }
    };

    /**
     * Getting claim
     */
    $scope.add= function(content){
        if(content){
            var commentAdded = {};
            commentAdded.date = new Date();
            commentAdded.owner = $rootScope.SESSION.user._id;
            commentAdded.msg = content;
            $scope.claim.comments.push(commentAdded);
            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if (response.success) {
                    Notification.success($translate.instant("ALERT_COMMENT_ADDED"));
                    $scope.getClaims();
                }
            });
            $scope.content = "";
        }


    };
    /**
     * Save user
     */
    $scope.save = function () {
        angular.forEach($scope.documents, function(file) {
            if(!$scope.claim.docs){
                $scope.claim.docs = [];
            }
            $scope.claim.docs.push(file._id);
        });
        I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
            if(response.success){
                Notification.success({
                    title : "User updated",
                    message : "Claim " + $scope.claim._plateforme.name + " was updated successfully!"
                });
                $state.go('app.home.claims');
                $scope.claim  = {};
            }else{
                Notification.error({
                    title: "Erreur",
                    message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.claim._plateforme.name +"!"
                });
                $scope.claim  = {};
            }
        }, function (err) {
            var msg = "";
            if( err.error.errorString){
                msg = err.error.errorString;
            }
            Notification.error({
                title: "Erreur",
                message : msg
            });
        })


    };

    /**
     * Cancel editing mode
     */
    $scope.cancel = function () {
        $scope.claim = {};
        $state.go('app.home.claims');
    };

    /**
     * Add intervention
     * @param claim
     */
    $scope.addIntervention = function (claim) {
        $scope.claim = claim;
        var modal=   ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/add.intervention.dlg.tpl.html",
            controller : "addInterventionDlgCtrl",
            scope: $scope
        })
        modal.closePromise.then(function() {
            $scope.claim.status= "InIntervention";
            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if(response.success){

                }else{
                    Notification.error({
                        title: "Erreur",
                        message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.claim._plateforme.name +"!"
                    });
                }
            }, function (err) {
                var msg = "";
                if( err.error.errorString){
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: "Erreur",
                    message : msg
                });
            })
        });
    };
    $scope.addDevTicket=function(){
        var modal=   ngDialog.open({
            template: 'app/commons/dialogs/openproject/task.add.dlg.tpl.html',
            controller: 'openProjectTaskAddDlgCtrl',
            scope: $scope

        });

        modal.closePromise.then(function(res) {
            console.log(res);
            if(res && res.value){
                $scope.claim.ticket=res.value.id;
                $scope.ticket=res.value
            }
            $scope.claim.status= "InDevelopment";
            I3ClaimSrv.updateById($scope.claim._id, $scope.claim, function (response) {
                if(response.success){

                }else{
                    Notification.error({
                        title: "Erreur",
                        message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.claim._plateforme.name +"!"
                    });
                }
            }, function (err) {
                var msg = "";
                if( err.error.errorString){
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: "Erreur",
                    message : msg
                });
            })

        });
    };
    $scope.getClaims();

    $scope.downloadFile=function(file){
        i3HelperSrv.downloadFile(file._id, file.original_name,function success(res){

        },function error(err){

        },function progress(evt){
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    }

});