/**
 * Created by Kais CHAIBI on 07/12/2017.
 */
angular.module("app.services").factory('constantSrv', function (){

    var service= {};

    service.getInstallationStatus=function(){
        return  [
            "New",
            "Planned",
            "Rejected",
            "Done",
            "Cancelled"
        ]
    };
    service.getInstallationGroupStatus=function(){
        return  [
            "New",
            "Planned",
            "InProgress",
            "Done"
        ]
    };
    service.getInterventionStatus=function(){
        return  [
            "New",
            "Planned",
            "InProgress",
            "Done",
            "Rejected",
            "OnHold",
            "Postponed",
            "Cancel"
        ]
    };

    service.getClaimsStatus=function(){
        return  [
            "New",
            "InProgress",
            "InDevelopment",
            "InIntervention",
            "Done",
            "Rejected",
            "OnHold"
        ]
    };

    service.getClaimsSource=function(){
        return  [
            "Application",
            "phone",
            "Skype",
            "Mail",
            "autre"
        ]
    };

    service.getModel=function(){
        return  [
            "Demande installations",
            "Installations",
            "Interventions",
            "Réclamation"
        ]
    };

    service.getPredefinedStatus = function(){
        return  [
            "Done",
            "Rejected",
            "Cancel"
        ]
    };

    service.getProducts = function(){
        return  [
            "Carburant",
            "Tempétature",
            "Dépassement vitess",
            "Portes Ouvertes",
            "Autre"
        ]
    };
    service.getClaimType = function(){
        return  [
            "Désinstallation ",
            "Réinstallation",
            "Transfert"
        ]
    };
    service.getPhase = function(){
        return  [
            "InProcpection",
            "InProcess",
            "InValidation"
        ]
    };
    service.getStatusProscpection  = function(){
        return  [
            "Created",
            "Sent",
            "Received"
        ]
    };
    service.getStatusValidation  = function(){
        return  [
            "InValidation",
            "Validated"
        ]
    };

    return service;
})