angular.module('app').controller("pvDlgCtrl", function ($rootScope,$scope,I3ProductSrv, Notification,installation,I3InstallationSrv) {

    I3InstallationSrv.query({query:{_id:installation},options:{populate:{path:"_product"}}},1,0, function (res) {
        if(res && res.success){
            $scope.installation = res.result[0];
            $scope.product = $scope.installation._product;
        }else{
            Notification.error("INSTALLATION_ERROR");
    }},function(err){
    });

    $scope.update = function (install) {
        I3InstallationSrv.updateById(installation,install, function (res) {
            if(res && res.success){
                Notification.success("INSTALLATION_UPDATED");
                $scope.closeThisDialog()
            }else{
                Notification.error("INSTALLATION_ERROR");
            }},function(err){
        })
    }
});