angular.module('app').controller('installationDetailsCtrl', function ($rootScope, $scope,I3PlateformeSrv,I3UserSrv,plateformHelperSrv,iaxeedCompanySrv,I3ProductSrv,ngDialog,Notification,$state ,$stateParams,messageBox,I3InstallationSrv ,constantSrv,$translate,iaxeedAssetSrv,I3ActionLogSrv,ObjectDiff, iaxeedSocketSrv,i3HelperSrv,reportingSrv) {

    function filter(obj1, obj2) {
        var result = {};
        for (key in obj1) {
            if (obj2[key] !== obj1[key]) result[key] = obj2[key];
            if (typeof obj2[key] === 'array' && typeof obj1[key] === 'array')
                result[key] = arguments.callee(obj1[key], obj2[key]);
            if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object')
                result[key] = arguments.callee(obj1[key], obj2[key]);
        }
        return result;
    }
    $scope.Object=Object;
    $scope.statusList=constantSrv.getInstallationStatus();
    if($stateParams.id && $stateParams.id.length>0){

        I3InstallationSrv.query({query:{_id:$stateParams.id},options:{
            populate:[{path:"_created_by"},{path:"docs"},{path:"comments.owner", model:"user"} ,{path:"_assigned_to"},{path:"_product" },{path : 'followers.user'},{path:"_installation_group" ,populate:{path:"_plateforme" ,model:"plateforme"}}]}},1,0,function(res) {


            if (res && res.success &&  res.result.length>0){
               $scope.installation= res.result[0];

               $scope.plateforme = $scope.installation._installation_group._plateforme;
                I3ActionLogSrv.query({query:{"_model" : "INSTALLATION","_object._id":"#OBJID#"+$scope.installation._id+"#OBJIDEND#"},options:{populate:"_user",sortBy:{creation_dt:-1}}},10,0,function(res){
                    if(res && res.result){
                        var  action_logs=[];
                         res.result.forEach(function(val,index){

                            if(val._action === "UPDATE") {

                                if (index < res.result.length - 1) {

                                    var  changes = ObjectDiff.diff(res.result[index+1]._object,val._object);

                                  var   objectChanges={others:[]};
                                    if(changes.changed ==="object change"){

                                        if(changes.value && Object.keys(changes.value).length>0){
                                            Object.keys(changes.value).forEach(function(key){
                                                if(key==="status" && changes.value[key].changed==="primitive change"){
                                                    objectChanges.STATUS={oldVal:changes.value[key].removed,newVal:changes.value[key].added}
                                                }
                                                else if(key==="begin_dt" && changes.value[key].changed==="primitive change"){
                                                    objectChanges.START_DATE={oldVal:changes.value[key].removed,newVal:changes.value[key].added}
                                                }
                                                else if(key==="end_dt" && changes.value[key].changed==="primitive change"){
                                                    objectChanges.END_DATE={oldVal:changes.value[key].removed,newVal:changes.value[key].added}
                                                }
                                                else if(key==="asset_name" && changes.value[key].changed==="primitive change"){
                                                    objectChanges.ASSET={oldVal:changes.value[key].removed,newVal:changes.value[key].added}
                                                }else if( key!=='modif_dt' && changes.value[key].changed==="primitive change" || changes.value[key].changed==="object change" ){
                                                    objectChanges.others.push(key);
                                                }
                                            });
                                            if(Object.keys(objectChanges).length>1 || objectChanges.others.length>0){
                                                val.changes=objectChanges;
                                            }

                                        }
                                    }


                                }
                                if(val.changes)
                                action_logs.push(val);
                            }else{
                                action_logs.push(val);
                            }



                        });
                        $scope.actionLogs=action_logs;
                        if($scope.plateforme.type == 'NGI_FLEET'){
                            return;
                        }
                        plateformHelperSrv.setPlateFormAndLogin($scope.plateforme, function (res) {

                            if(res.success){
                                $scope.plateformeToken = res.result.token;

                                $scope.asset = {};

                                var query = {
                                    _id : $scope.installation.asset
                                };
                                var options = {};


                                iaxeedAssetSrv.query({query: query, options:options}, 10000, 1, function (response) {

                                    if(response.success){
                                        $scope.asset = response.result[0];
                                    }
                                }, function (err) {
                                    console.log(err);
                                })

                            }
                        });
                    }
                },function(err){

                })
        } else{
                $state.go("app.home.installRequest");
                Notification.error("FIND_BY_ID_ERROR");
            }

        },function(err){
            Notification.error("FIND_BY_ID_ERROR")
        })
    }else{

        $state.go("app.home.installRequest");
    }


    /**
     * Following the current installation
     */
    $scope.follow = function () {
        if($scope.emailFollow && $scope.emailFollow !== "") {

            var follower = {};
            if (!$scope.installation.followers) {
                $scope.installation.followers = [];
            }

            follower.email = $scope.emailFollow;


            var query = {
                "contact.mail": $scope.emailFollow
            };
            var options = {};

            var exist = false;

            angular.forEach($scope.installation.followers, function (follower) {
                if (follower.email.toLowerCase() === $scope.emailFollow.toLowerCase()) {
                    exist = true;
                }
            });

            if (!exist) {
                I3UserSrv.query({query: query, options: options}, 10000, 0, function (res) {
                    if (res.success) {
                        follower.user = res.result[0];
                    }

                    $scope.installation.followers.push(follower);

                    delete $scope.installation.__v;

                    I3InstallationSrv.updateById($scope.installation._id, $scope.installation, function (response) {
                        if (response.success) {
                            Notification.success({
                                title: "Following claim",
                                message: "The email address" + $scope.emailFollow + " is now following installation " + $scope.installation._installation_group._plateforme.name
                            });

                            $scope.emailFollow = "";
                        } else {
                            Notification.error({
                                title: "Erreur",
                                message: $translate.instant("ERROR_OCCURED_UPDATE") + " " + $scope.installation._installation_group._plateforme.name + "!"
                            });
                            $scope.installation = {};
                        }
                    }, function (err) {
                        var msg = "";
                        if (err.error.errorString) {
                            msg = err.error.errorString;
                        }
                        Notification.error({
                            title: "Erreur",
                            message: msg
                        });
                    })
                });
            } else {
                Notification.warning({
                    title: "Already follower",
                    message: "This email is already subscribed as a follower here"
                });
                $scope.emailFollow = "";
            }


        }

    };

    /**
     * Remove follower
     */
    $scope.removeFollower = function (follower) {
        if($scope.installation.followers.indexOf(follower) >= 0){
            $scope.installation.followers.splice($scope.installation.followers.indexOf(follower), 1);
            delete $scope.installation.__v;

            I3InstallationSrv.updateById($scope.installation._id, $scope.installation, function (response) {
                if(response.success){
                    Notification.success({
                        title : "Removing installation follower",
                        message : "The follower of installation " + $scope.installation._installation_group._plateforme.name+" was removed successfully"
                    });
                }else{
                    Notification.error({
                        title: "Erreur",
                        message : $translate.instant("ERROR_OCCURED_UPDATE") +" "+ $scope.installation._installation_group._plateforme.name +"!"
                    });
                    $scope.installation  = {};
                }
            }, function (err) {
                var msg = "";
                if( err.error.errorString){
                    msg = err.error.errorString;
                }
                Notification.error({
                    title: "Erreur",
                    message : msg
                });
            })
        }
    };


    $scope.loadAssets=function(){

        iaxeedAssetSrv.query({query:{"_company_owner":$scope.installationRequest.client_id}},1000,0,function(res){

            $scope.assets=res.result;
        },function(err){
            console.error(err);
        })
    };


    $scope. updateInstallationProp=function(prop,installation,$data){
        var updateData={};
        if(prop==="status"){
            if($data==="Planned" && !updateData.planned_begin_dt){
                updateData.planned_begin_dt=new Date();
                installation.planned_begin_dt=new Date();
            }else if($data==="InProgress" && !updateData.begin_dt){
                updateData.begin_dt=new Date();
                installation.begin_dt=new Date();
            }else if ($data==="Done" && !updateData.end_dt) {
                updateData.end_dt=new Date();
                installation.end_dt=new Date();
            }

        }
        if(prop==="planned_begin_dt" && installation.status==="New"){
            updateData.status="Planned";
            installation.status="Planned"
        }
        if(prop==="begin_dt" && installation.status!=="InProgress" && installation.status!=="Done"){
            updateData.status="InProgress";
            installation.status="InProgress"
        }
        if(prop==="end_dt" && installation.status!=="Done"){
            updateData.status="Done";
            installation.status="Done";
        }
        updateData[prop]=$data;
        if(prop==="asset"){
            updateData={asset:$data._id,asset_name:$data.name};
            installation.asset_name=$data.name;

        }
        I3InstallationSrv.updateById(installation._id,updateData, function(res){

        },function(err){

        })
    };

    $scope.removeComment=function(index){
        messageBox.confirm("DELETE_COMMENTS_CONFIRM_TITLE","DELETE_COMMENTS_CONFIRM_MESSSAGE").then(function(res){
            if(res){
                $scope.installation.comments.splice(index,1);
                I3InstallationSrv.updateById($scope.installation._id, {"comments":  $scope.installation.comments}, function (res) {
                    if (res && res.success) {
                        Notification.success($translate.instant("COMMENT_DELETED"));
                        var comment ={msg:msg,date:new Date(),owner:$rootScope.SESSION.user};
                        $scope.installation.comments.push(comment);


                    } else {
                        Notification.error($translate.instant('COMMENT_DELETE_FAIL'));
                    }


                }, function (err) {
                    Notification.error($translate.instant('COMMENT_DELETE_FAIL'));
                })
            }

        })
    };
    $scope.addComment=function(msg){

        if(msg && msg.length>0) {

            var comment ={msg:msg,date:new Date(),owner:$rootScope.SESSION.user._id};

            if (!$scope.installation.comments) $scope.installation.comments = [];
            var installationCmt=angular.copy($scope.installation.comments);
            installationCmt.push(comment);


            I3InstallationSrv.updateById($scope.installation._id, {"comments":  installationCmt}, function (res) {
                if (res && res.success) {
                    Notification.success($translate.instant("COMMENT_ADDED"));
                    var comment ={msg:msg,date:new Date(),owner:$rootScope.SESSION.user};
                   $scope.installation.comments.push(comment);
                    $scope.comment=null;

                } else {
                    Notification.error($translate.instant('COMMENT_ADD_FAIL'));
                }

            }, function (err) {
                Notification.error($translate.instant('COMMENT_ADD_FAIL'));
            })
        }
    };

    $scope.opened = {};

    $scope.open = function($event, elementOpened) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.opened[elementOpened] = !$scope.opened[elementOpened];
    };

    $scope.deviceInfo = function () {
        var dialog = ngDialog.open({
            controller: "realtimeDlgCtrl",
            templateUrl: "app/commons/dialogs/realtime/realtime.dlg.tpl.html",
            scope: $scope,
            className: 'ngdialog-theme-default custom-width'
        });

        dialog.closePromise.then(function () {
            iaxeedSocketSrv.closeListner('newMsg');
        });
    };

    $scope.uploadFiles = function(files, errFiles) {
        $scope.files = files;
        $scope.errFiles = errFiles;
        if($scope.installation && (!$scope.installation.docs || !Array.isArray($scope.installation.docs)))
            $scope.installation.docs=[];
        i3HelperSrv.uploadFiles(files, function (res) {
            angular.forEach(res.result , function (file) {

                $scope.installation.docs.push(file)


            });
            I3InstallationSrv.updateById($stateParams.id,{docs:$scope.installation.docs},function(res){
                if(res && res.success){
                    Notification.success("INSTALLATION_UPDATED");
                }else{
                    Notification.error("INSTALLATION_UPDATED_ERROR");
                }},function(err){

            })
            file.result = res.data;
        }, function (response) {
            if (response.status)
                $scope.errorMsg = response.status + ': ' + response.data;
        }, function (evt) {

        });
    };

    $scope.downloadFile=function(file){
        i3HelperSrv.downloadFile(file._id, file.original_name,function success(res){

        },function error(err){

        },function progress(evt){
            console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '%');
        })
    };

    $scope.printInstallation = function (installation) {
        var printContents = document.getElementById(installation).innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();

        document.body.innerHTML = originalContents;
        window.location.reload(true);

    }

/*    $scope.printInstallation = function (elem) {
        var divToPrint = document.getElementById(elem);
        var popupWin = window.open('', '_blank', 'width=800,height=800');
        popupWin.document.open();
        popupWin.document.write('<html><head><style> table, th, td{ border: 1px solid black;}</style></head><center><body onload="window.print()">' + divToPrint.innerHTML + '</html>');
        popupWin.document.close();
    }*/
    $scope.export=function(fileType){
       // console.log('install ',JSON.stringify($scope.installation))
         info={};
         info= {
            date:($scope.installation && $scope.installation.begin_dt ? $scope.installation.begin_dt:""),
            company:($scope.installation && $scope.installation._installation_group && $scope.installation._installation_group.client_name? $scope.installation._installation_group.client_name:""),
            "adressCompany":($scope.installation  && $scope.installation._installation_group && $scope.installation._installation_group.contact && $scope.installation._installation_group.contact._ctry? $scope.installation._installation_group.contact._ctry:""),
            "mail": ($scope.installation && $scope.installation._installation_group && $scope.installation._installation_group.contact && $scope.installation._installation_group.contact.mail ? $scope.installation._installation_group.contact.mail:""),
            "telFax":($scope.installation && $scope.installation._installation_group && $scope.installation._installation_group.contact && $scope.installation._installation_group.contact.phone ? $scope.installation._installation_group.contact.phone:"")+'/'+($scope.installation && $scope.installation._installation_group && $scope.installation._installation_group.contact && $scope.installation._installation_group.contact.fax ? $scope.installation._installation_group.contact.fax :""),
            "contact": ($scope.installation &&$scope.installation._installation_group && $scope.installation._installation_group.contact && $scope.installation._installation_group.contact.name  ? $scope.installation._installation_group.contact.name:""),
            "telContact": ($scope.installation && $scope.installation._installation_group && $scope.installation._installation_group.contact && $scope.installation._installation_group.contact.phone ? $scope.installation._installation_group.contact.phone:""),
            "mailContact": ($scope.installation && $scope.installation._installation_group && $scope.installation._installation_group.contact && $scope.installation._installation_group.contact.email ? $scope.installation._installation_group.contact.email:""),
            "adressInstal": "",
            data:true
        };
        data= [];
            data.push({
                "typeV": "",
                "marqueV": "",
                "modelV": "",
                "imm": ($scope.installation && $scope.installation.asset_name ? $scope.installation.asset_name:""),
                "odo": "",
                "marqueB": "",
                "modelB": ($scope.installation && $scope.installation.device_type ? $scope.installation.device_type:""),
                "sn": "",
                "sim": "",
                "snSensor": ""
             });

        var ds= {name:"pvSingleinstall",data:{info:info,data:data}}
        if(fileType=="XLSX"){
            reportingSrv.generateExcel("templatePVInstallSingle.mrt",ds,function(){

            },function(){
                Notification.error("L'export a échoué ");
            })
        }else if(fileType=="DOCX"){
            reportingSrv.generateWord("templatePVInstallSingle.mrt",ds,function(){

            },function(){
                Notification.error("L'export a échoué ");
            })
        }else if(fileType=="preview"){
            console.log("preview")
            reportingSrv.preview("templatePVInstallSingle.mrt",ds,function(){

            },function(){
                Notification.error("La prévisualisation  a échoué ");
            })
        }
        else{
            reportingSrv.generatePdf("templatePVInstallSingle.mrt",ds,function(){

            },function(){
                Notification.error("L'export a échoué ");
            })
        }

    }

});