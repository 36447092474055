/**
 * Created by Kais CHAIBI on 05/12/2017.
 */
angular.module('app').controller('installationRequestCtrl', function ($rootScope, $scope,I3InstallationSrv, I3InstallationGroupSrv,I3PlateformeSrv,plateformHelperSrv,iaxeedCompanySrv,I3ProductSrv,ngDialog,Notification,$state,messageBox,$translate, constantSrv) {

    //$scope.search={};
    //$scope.itemsByPage = 50;
    $scope.tableStyle = 'list';
    $scope.inlineStatus = constantSrv.getInstallationGroupStatus();
    $scope.statusInstallRequest = constantSrv.getInstallationGroupStatus();
    $scope.statusInstallRequest.push('NotClosed')
    $scope.statusInstallRequest.push('Filled')
    var compare = false;


    /**
     * Change table style (list / tree)
     */

    $scope.changeTableStyle = function (style) {
        $scope.tableStyle = style;
    };

    $scope.toggleRequest = function (request) {
        request.expanded = !request.expanded;
    };


    /**
     * change to create mode
     */
    $scope.create = function () {

        //$scope.installationRequest = {comments:[{owner:$rootScope.SESSION.user._id}]};
        $state.go("app.home.installRequestAdd");
        $scope.mode = "CREATE";
    };
    $scope.showDetails=function(installationRequest){


        $state.go("app.home.installRequestDetails",{id:installationRequest._id});
    }
    /**
     * Edit installation
     */
    $scope.edit = function (installationRequest) {

        //$scope.installationRequest = {comments:[{owner:$rootScope.SESSION.user._id}]};
        $state.go("app.home.installRequestEdit",{id:installationRequest._id});
        $scope.mode = "EDIT";
    };
    $scope.remove = function (installationRequest) {

        messageBox.confirm("DELETE_INSTALLATION_REQUEST_TITLE","DELETE_INSTALLATION_REQUEST_MESSAGE").then(function(res){

            if(res){
                I3InstallationGroupSrv.delete(installationRequest._id,function(res){
                    if(res && res.success){
                        Notification.success($translate.instant("INSTALLATION_REQUEST_DELETED"));
                        if($scope.stCtrl ){
                            $scope.stCtrl.pipe();
                        }
                    }else{
                        Notification.warn($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
                    }

                },function(err){
                    Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
                })

            }

        },function(reject){
            //Notification.error($translate.instant("INSTALLATION_REQUEST_DELETE_ERROR"));
        })
    };

    /**
     * Get all  plateform
     */
    I3PlateformeSrv.query({}, 10000, 1, function (response) {
        $scope.platforms = response.result;
    }, function (err) {
        console.log(err);
    });
    /**
     * select and authenticate plateforme
     * @param platform
     */
    $scope.selectPlateform=function(platform){


        plateformHelperSrv.setPlateFormAndLogin(platform,function(res){

            console.log(res);
        },function(err){
            console.log(err);
        })
    };
    $scope.selectClient=function(comapny){
        if(comapny.contact){

            if(!$scope.installationRequest.contact) $scope.installationRequest.contact={};
            $scope.installationRequest.contact.fax=comapny.contact.fax;
            $scope.installationRequest.contact.email=comapny.contact.mail;
            $scope.installationRequest.contact.phone=comapny.contact.phone;

        }

    };
    $scope.searchClient=function($select){

        iaxeedCompanySrv.query({query:{name:{'$regex' :$select.search, '$options' : 'i'}}},10,0,function(res){

            $scope.clients=res.result;
        },function(err){

        })
    };
    $scope.addInstallation=function(){

        ngDialog.open({
            template: 'app/commons/dialogs/installationsGroup/installation.add.dlg.tpl.html',
            controller: 'addInstallationDlgCtrl',
            scope: $scope
        })
    };

    $scope.loadInstallationRequest  = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options =  { populate:['_plateforme', {
            path: '_installationIds',
            populate: {
                path: '_assigned_to',
                model: 'user'
            }
        }],
            sortBy: {creation_dt: -1} };

        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            //start = pagination.start / number + 1 || 1;
            $scope.numberPages.installationRequestItemByPages = number
            if($scope.numberPages.installationRequestNumberPages){
                start = $scope.numberPages.installationRequestNumberPages
            }else{
                start = pagination.start / number + 1 || 1;
            }
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }



        var query = {};
        if(!$scope.search.client_name){
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.client_name) {
                    $scope.search.client_name = tableState.search.predicateObject.client_name
                    query.client_name = {'$regex': $scope.search.client_name, '$options': 'i'}
                }
            }else{
                query.client_name = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.client_name = $scope.search.client_name
            }
            query.client_name = {'$regex' :$scope.search.client_name, '$options' : 'i'}
        }

        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.client_name = tableState.search.predicateObject.client_name
        }

        if ($scope.search && $scope.search.statusInstallRequest) {
            if($scope.search.statusInstallRequest === "NotClosed"){
                query.status= {$nin:constantSrv.getPredefinedStatus()};
            }else {
                query.status = $scope.search.statusInstallRequest;
            }
        }
        if ($scope.search && $scope.search.plateform) {

            query._plateforme= $scope.search.plateform._id;
        }


        I3InstallationGroupSrv.query({query: query, options: options } , number, start, function (response) {
            if(response.success){


                $scope.requestsCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                tableState.pagination. start=start*number-1>=0? start*number-1: 0;
                $scope.tableState = tableState;

                $scope.installationRequests = response.result.map(function(e){
                    e.installCount=0;
                    e.installDoneCount=0;
                    if(e.installations && e.installations.length>0){
                        e.installations.forEach(function(val){
                            e.installCount+=(Number.isFinite(val.asset_count)? val.asset_count: 0);
                        })
                    }
                    if(e._installationIds && e._installationIds.length>0){
                        e._installationIds.forEach(function(val){
                            if(val.status=="Done")  e.installDoneCount++;
                        })
                    }
                    e.progress=e.installCount?e.installDoneCount*100/e.installCount:0;
                    return e;
                });
                angular.forEach($scope.installationRequest._installationIds,function (date) {
                    if(date.status === 'Done'){
                        compare = true
                    }else{
                        compare  = false
                    }
                });
            }
        });
    };


    $scope.cancel=function(){
        $scope.mode = "LIST";
    };

    $scope.save=function(installRequest){
        var install = angular.copy(installRequest);
        install.client_id=installRequest.client_id._id;
        install._plateforme=installRequest.plateform._id;
        install.client_name=installRequest.client_id.name;

        if(install.comments[0].msg && install.comments[0].msg.length>0){

        }else {
            delete   install.comments;
        }
        I3InstallationGroupSrv.add(install,function(res){

            if(res){
                Notification.success($translate.instant("INSTALLATION_GROUP_ADDED"));
            }
        },function(err){

        })
    }

    $scope.removeStatus = function (predefined) {
        var index = $scope.predefinedClaimStatus.indexOf(predefined);
        if (index > -1) {
            $scope.predefinedClaimStatus.splice(index, 1)
            $scope.loadInstallationRequest();
        }
    }

    $scope. updateInstallationRequestProp=function(prop,$data, id){
        var updateData={};
        if(prop=="status"){
            updateData[prop]=$data;
        }
        I3InstallationGroupSrv.query({query:{_id:id},options:{
            populate:[{path:"_installationIds",populate:[{path:"_product", model:"product"}]}]}},1,0,function(res) {
            if(res.success){
                $scope.installationRequest = res.result[0]
                for(var i= 0; i<$scope.installationRequest._installationIds.length; i++){
                    if($scope.installationRequest._installationIds[i].status !== 'Done'){
                        compare = false;
                        break
                    }else{
                        compare = true;
                    }
                }
                if(compare && $data == 'Done' || $scope.installationRequest._installationIds.length ==0){
                    I3InstallationGroupSrv.updateById(id,updateData,function(res){
                        Notification.success($translate.instant("INSTALLATION_GROUP_UPDATED"));
                    },function(err){
                    })
                }else if($data != 'Done') {
                    I3InstallationGroupSrv.updateById(id,updateData,function(res){
                        Notification.success($translate.instant("INSTALLATION_GROUP_UPDATED"));
                    },function(err){
                    })
                }else{
                    Notification.error('Il y a des installations qui ne sont pas encore terminées ')
                    $scope.loadInstallationRequest()
                }
            }
        })
    };


    if(!$scope.search.statusInstallRequest && $scope.search.statusInstallRequest !== null){
        $scope.search.statusInstallRequest="NotClosed"
    }

    $scope.onPageChange = function (val) {
        $scope.numberPages.installationRequestNumberPages = val
        $scope.numberPages.installationRequestItemByPages = $scope.itemsByPage
    }

    if(!$scope.numberPages.installationRequestItemByPages || $scope.numberPages.installationRequestItemByPages === undefined){
        $scope.itemsByPage = 50
    }else{
        $scope.itemsByPage = $scope.numberPages.installationRequestItemByPages
    }


});