angular.module('app').controller("addInterventionDlgCtrl", function ($scope, $state, $stateParams, I3UserSrv,I3ClaimSrv, I3InterventionSrv, Notification) {

    $scope.intervention = {};
    I3UserSrv.query({}, 10000, 1, function (res) {
        if(res.success){
            $scope.users = res.result;
        }
    }, function (err) {
        console.log(err);
    });

    $scope.selectUser = function (user) {
        $scope.intervention._assigned_to = user
    }

    $scope.save = function () {
        var interv = angular.copy($scope.intervention);
        interv._claim = $scope.claim._id;
        if($scope.intervention._assigned_to){
            if($scope.intervention._assigned_to.length > 0 && $scope.intervention._assigned_to[0]._id){
                interv._assigned_to = [];
                angular.forEach($scope.intervention._assigned_to, function (user) {
                    interv._assigned_to.push(user._id);
                });
            }else{
                delete interv._assigned_to;
            }
        }

        I3InterventionSrv.add(interv, function (res) {
            if(res && res.success){
                $scope.closeThisDialog(res.result);
                Notification.success({message : $translate.instant("INTERVENTION_ADDED")});
            }else{
                Notification.error({message : $translate.instant("INTERVENTION_FAILED")});
            }
        }, function (err) {
            console.log(err);
        });

        //$state.go('app.admin.claims');
    }
});