angular.module('app').controller('adminUserCtrl', function ($scope,$rootScope, $state, $translate, I3UserSrv, I3RoleSrv,ngDialog, Notification) {

    $scope.user = {};
    //$scope.itemsByPage = 50;
    //$scope.search = {};

    /**
     * Getting users
     */
    $scope.loadUsers  = function (tableState,ctrl) {
        if (!$scope.stCtrl && ctrl) {
            $scope.stCtrl = ctrl;
        }

        if (!tableState && $scope.stCtrl) {
            $scope.stCtrl.pipe();
            return;
        }
        var start = 0;
        var number = $scope.itemsByPage;

        var options =  {
            populate: [
                {
                    path: '_ctry',
                    select: 'name'


                },
                {
                    path:'_role',
                    select:'permissions'
                }
            ]
        };

        var query = {};
        var roles = $rootScope.SESSION.user._role._childs;
        if(roles.indexOf($rootScope.SESSION.user._role._id)===-1){
            roles.push( $rootScope.SESSION.user._role._id);
        }
        query['_role'] = {"$in": roles};
        if (tableState.pagination) {
            var pagination = tableState.pagination;
            number = pagination.number || $scope.itemsByPage;
            //start = pagination.start / number + 1 || 1;
            $scope.numberPages.userItemByPages = number
            if($scope.numberPages.userNumberPages){
                start = $scope.numberPages.userNumberPages
            }else{
                start = pagination.start / number + 1 || 1;
            }
        }
        if (tableState.sort && tableState.sort.predicate) {
            options.sortBy = {};

            var predicate = tableState.sort.predicate;
            options.sortBy[predicate] = ((tableState.sort.reverse) ? -1 : 1);
        }

/*        if (tableState.search && tableState.search.predicateObject) {
            if (tableState.search.predicateObject.last_name) {
                query["last_name"] = {"$regex": tableState.search.predicateObject.last_name, "$options": "i"};
            }
            if (tableState.search.predicateObject.first_name) {
                query["first_name"] = {'$regex': tableState.search.predicateObject.first_name, $options: '-i'}
            }
            if (tableState.search.predicateObject.login) {
                query["login"] = {'$regex': tableState.search.predicateObject.login, $options: '-i'}
            }
        }*/

        if(!$scope.search.last_name) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.last_name) {
                    $scope.search.object = tableState.search.predicateObject.last_name
                    query["last_name"] = {"$regex": tableState.search.predicateObject.object, "$options": "i"};
                }
            }else{
                query.last_name = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.last_name = $scope.search.last_name
            }
            query.last_name = {'$regex' :$scope.search.last_name, '$options' : 'i'}
        }
        if(!$scope.search.first_name) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.first_name) {
                    $scope.search.first_name = tableState.search.predicateObject.first_name
                    query.first_name = {'$regex': tableState.search.predicateObject.first_name, '$options': 'i'}
                }
            }else{
                query.first_name = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.first_name = $scope.search.first_name
            }
            query.first_name = {'$regex' :$scope.search.first_name, '$options' : 'i'}
        }
        if(!$scope.search.login) {
            if (tableState.search && tableState.search.predicateObject) {
                if (tableState.search.predicateObject.login) {
                    $scope.search.login = tableState.search.predicateObject.login
                    query.login = {'$regex': tableState.search.predicateObject.login, '$options': 'i'}
                }
            }else{
                query.login = {'$regex' :'', '$options' : 'i'}
            }
        }else{
            if (tableState.search && tableState.search.predicateObject) {
                tableState.search.predicateObject.login = $scope.search.login
            }
            query.login = {'$regex' :$scope.search.login, '$options' : 'i'}
        }

        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.last_name = tableState.search.predicateObject.last_name
        }
        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.first_name = tableState.search.predicateObject.first_name
        }
        if (tableState.search && tableState.search.predicateObject) {
            $scope.search.login = tableState.search.predicateObject.login
        }


        if($scope.search){
            if ($scope.search._role) {
                query["_role"] = {'$regex': $scope.search._role._id, $options: '-i'}
            }
        }

        I3UserSrv.query({query: query, options: options } , number, start, function (response) {
            if(response.success){
                $scope.users = response.result;
                $scope.userCount = response.total_count;
                tableState.pagination.numberOfPages = Math.ceil(response.total_count / number);
                tableState.pagination.totalItemCount = response.total_count;
                tableState.pagination. start=start*number-1>=0? start*number-1: 0;
                $scope.tableState = tableState;
            }
        });
    };

    /**
     * Getting roles
     */
    I3RoleSrv.query({}, 10000, 1, function (response) {
        if(response.success){
            $scope.roles = response.result;
        }
    });


    /**
     * Removing user
     * @param user
     */
    $scope.remove = function (user) {
        $scope.user = user;
        
        ngDialog.open({
            templateUrl : "app/commons/dialogs/confirm/user.delete.dlg.tpl.html",
            controller : "confirmDeleteUserDlgCtrl",
            scope: $scope
        })

    };

    $scope.onPageChange = function (val) {
        $scope.numberPages.userNumberPages = val
        $scope.numberPages.userItemByPages = $scope.itemsByPage
    }

    if(!$scope.numberPages.userItemByPages || $scope.numberPages.userItemByPages === undefined){
        $scope.itemsByPage = 50
    }else{
        $scope.itemsByPage = $scope.numberPages.userItemByPages
    }


});