/**
 * Created by Kais CHAIBI on 13/12/2017.
 */

angular.module("app.services").factory("ColorSelectorSrv", ["$filter",function ($filter) {

    const defaultColor=["#E53935","#880E4F","#1A237E","#212121","#303F9F","#37474F","#795548","#388E3C","#0277BD","#4E342E","#FF4081","#558B2F","#009688","#0288D1","#AA00FF","#EC407A","#F50057","#009688","#607D8B","#9C27B0","#651FFF","#006064","#3F51B5","#9575CD","#512DA8","#DD2C00","#8D6E63","#616161","#546E7A","#795548","#FF5252","#1976D2","#2979FF","#283593","#9C27B0","#0D47A1","#448AFF","#33691E","#00838F","#7986CB","#A1887F","#01579B","#827717","#E64A19","#607D8B","#F44336","#3949AB","#6D4C41","#311B92","#D84315","#7E57C2","#BA68C8","#673AB7","#EF5350","#C2185B","#C62828","#7C4DFF","#00695C","#536DFE","#757575","#263238","#6A1B9A","#FF3D00","#1565C0","#F4511E","#AD1457","#D32F2F","#BF360C","#304FFE","#2962FF","#3E2723","#E65100","#00796B","#6200EA","#0091EA","#0097A7","#D81B60","#5D4037","#455A64","#7B1FA2","#E91E63","#3F51B5","#FF1744","#424242","#673AB7","#D50000","#F44336","#4A148C","#AB47BC","#8E24AA","#D500F9","#E91E63","#004D40","#1E88E5","#00897B","#B71C1C","#5C6BC0","#43A047","#5E35B1","#1B5E20","#E040FB","#4527A0","#C51162","#3D5AFE","#2E7D32","#78909C","#000000"];
    
    function ColorSelector(){
        this.availableColors=angular.copy(defaultColor);

    }

    ColorSelector.prototype.getNextColor=function(){
        var color =defaultColor[0];
        if(this.availableColors && this.availableColors.length>0){
             color=this.availableColors[0];
            this.availableColors.splice(0,1);
        }else{
             color =this.getRandomColor();
        }
        return color;
    };

    ColorSelector.prototype.getRandomColor=function() {
        var h = Math.floor(Math.random() * 360), //Hue
            s = Math.floor(Math.random() * 80) + '%', // Saturation
            l = Math.floor(Math.random() * 70) + '%'; // Light
        return "hsl("+h+","+s+","+l+")";
    };

    ColorSelector.prototype.updateUsedColor=function(color) {
         if(this.availableColors && this.availableColors.length>0 && this.availableColors.indexOf(color)>=0){
             this.availableColors.splice(this.availableColors.indexOf(color),1);
         }
    };

    return ColorSelector;

}]);